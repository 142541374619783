import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Animated } from "react-animated-css"
import helper from '../utils/helper'
import cfg from '../utils/config'
import auth from '../utils/auth'
import axios from 'axios'

class ModalAlerta extends Component {
    state = {
        modal: false,
        interacaoContent: "",
        alerta: { descricao: "", timestamp: null, interacoes: [], status: "", automacao: { condicao: null }, },
        showmessage: false,
        tabIndex: 0
    }

    toggle = () => this.setState({ modal: !this.state.modal })

    getData = () => {
        this.toggle()
        let userInfo = auth.getUserInfo()
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/alerta/tratativa/' + this.props.id + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((res) => this.setState({ alerta: res.data }))
            .catch(console.error)
    }

    novaInteracao = () => {
        const userInfo = auth.getUserInfo()
        let interacaoContent = this.state.interacaoContent

        if (interacaoContent.trim() === "") {
            this.setState({ showmessage: true })
            setTimeout(() => this.setState({ showmessage: false }), 4000)
            return
        }

        let dados = new FormData()
        dados.append('interacoes', interacaoContent)

        axios({
            method: 'patch',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/alerta/tratativa/' + this.props.id + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() },
            data: dados
        })
            .then((res) => {
                this.setState({ alerta: res.data, interacaoContent: "", tabIndex: 2 })
            })
            .catch((err) => {
                console.error(err)
                if (err.response.status == 403) {
                    helper.dispatchEvent("showAviso", { message: "Erro! sem permissão." })
                }
            })
    }

    updateStatus = (status) => {
        let userInfo = auth.getUserInfo();
        const body = new FormData();
        body.append('status', status);
        let currentStatus = this.state.alerta.status;
        let currentFormatStatus = currentStatus === 'new' ? "novo" : currentStatus === 'pending' ? "pendente" : currentStatus === 'finished' ? "finalizado" : "";
        let newStatus = status === 'new' ? "novo" : status === 'pending' ? "pendente" : status === 'finished' ? "finalizado" : "";

        const interactionContent = `Alerta definido de "${currentFormatStatus}" para "${newStatus}"`;
        body.append('interacoes', interactionContent);
        body.append('user', userInfo.username);
        body.append('date', new Date().toISOString());

        axios({
            method: 'patch',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/alerta/tratativa/' + this.props.id + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() },
            data: body
        })
            .then((res) => {
                this.props.getData({ clear: true });
                this.setState({ alerta: res.data });
            })
            .catch((err) => {
                console.error(err)
                if (err.response.status == 403) {
                    helper.dispatchEvent("showAviso", { message: "Erro! sem permissão." })
                }
            })
    }

    formatDate = (date) => {
        if (!date) return "---"

        try {
            return new Date(date * 1000).toLocaleDateString("pt-BR", { hour: "numeric", minute: "numeric" })
        } catch (err) {
            console.error(err)
            return "---"
        }
    }

    renderEvent = (automacao) => {
        let content = []

        try {
            if (!automacao?.condicao) return content

            let condicoes = JSON.parse(automacao?.condicao)

            for (let j = 0; j < condicoes.length; j++) {
                let conditionGroup = condicoes[j]

                for (let i = 0; i < conditionGroup.length; i++) {
                    const c = conditionGroup[i]?.condicao

                    if (i > 0 && i != conditionGroup.length) {
                        content.push(<strong key={Math.random()}>OU</strong>)
                    }

                    content.push(
                        <div className="alert-condition" key={`${j}-${i}`}>
                            <span>{c?.campo?.label}</span>
                            <span>{c?.condicao?.label}</span>
                            <span>{c?.valor}</span>
                        </div>
                    )
                }

                if (j != (condicoes.length - 1)) {
                    content.push(<strong key={Math.random()}>E</strong>)
                }
            }
        } catch (err) {
            console.error(err)
        }

        return content
    }

    render = () => {
        const { alerta } = this.state
        const status = {
            "new": "Novo",
            "pending": "Pendente",
            "finished": "Finalizado"
        }

        const badges = {
            "new": "primary",
            "pending": "warning",
            "finished": "success"
        }

        return (
            <div>
                <span style={{ cursor: "pointer", color: "#007bff", fontSize: "13px" }} onClick={this.getData}>{this.props.label}</span>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Alerta: {this.state.alerta?.descricao}</ModalHeader>
                    <ModalBody style={{ minHeight: "400px" }}>
                        <Animated
                            style={{ top: '83%' }}
                            className="mensagem-erro"
                            animationIn="fadeInDown"
                            animationOut="fadeOutDown"
                            animateOnMount={false}
                            isVisible={this.state.showmessage}>
                            <div>
                                Por favor descreva o atendimento antes de alterar o seu estado. <br />
                                <i className="fa fa-exclamation mensagem-icone fa-white"></i>
                            </div>
                        </Animated>
                        <Tabs className="alert-details" selectedIndex={this.state.tabIndex} onSelect={(i) => this.setState({ tabIndex: i })}>
                            <TabList>
                                <Tab>Detalhes</Tab>
                                <Tab>Condições</Tab>
                                <Tab>Interações</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-12">
                                        Status Atual: <span className={"badge badge-" + badges[alerta?.status]}>{status[alerta?.status]}</span>
                                    </div>
                                    <div className="col-md-12">
                                        Data: <span>{this.formatDate(alerta?.timestamp)}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className={alerta?.status != "finished" ? "row" : "hdv-noshow-item"}>
                                    <div className="col-md-12">
                                        <label className="hdv-top15">Descrição do Atendimento</label>
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            value={this.state.interacaoContent}
                                            onChange={(e) => this.setState({ interacaoContent: e.target.value })}
                                            className="mb-2 hdv-textarea"
                                            style={{ width: '100%', height: '150px', borderRadius: '5px', minHeight: '100px', maxHeight: '300px' }}
                                        />
                                        <div className="float-right">
                                            <button onClick={this.novaInteracao} className={"hdv-btn-forms hdv-btn-blue m-0"}>Adicionar Interação</button>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-12 alert-condition-group">
                                        <label>Condições de acionamento</label>
                                        {this.renderEvent(alerta.automacao)}
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                {alerta.interacoes?.length == 0 ?
                                    <p className="text-center mt-4">Ainda não há interações cadastradas</p>
                                    :
                                    null
                                }

                                {alerta?.interacoes?.map((item, key) => {
                                    return (
                                        <div key={key} className="row" style={{ marginTop: '5px' }}>
                                            <div className="col-md-6">Usuário: {item?.user}</div>
                                            <div className="col-md-6">Data: {this.formatDate(item.date)}</div>
                                            {/* <div className="col-md-12">Status: <span className={"badge badge-" + item.badge}>{item.status}</span></div> */}
                                            <div className="col-md-12">Comentário:</div>
                                            <div className="col-md-12">
                                                <div style={{ backgroundColor: '#f2f2f2', padding: '4px', borderRadius: '2px' }}>
                                                    {item?.content}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </TabPanel>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        <div className="w-100 d-flex justify-content-between">
                            <div className="ml-3">
                                {alerta?.status != "finished" ?
                                    <>
                                        <button onClick={() => this.updateStatus("pending")} style={{ marginRight: '5px' }} className={"hdv-btn-forms hdv-btn-yellow text-dark"} disabled={alerta?.status == "pending"}>
                                            Marcar como Pendente
                                        </button>
                                        <button onClick={() => this.updateStatus("finished")} style={{ marginRight: '5px' }} className={"hdv-btn-forms hdv-btn-green"} disabled={alerta?.status == "finished"}>
                                            Finalizar
                                        </button>
                                    </>
                                    :
                                    null
                                }
                            </div>
                            <button className="hdv-btn-forms hdv-btn-default" onClick={this.toggle}>Fechar</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ModalAlerta