import React, { Component } from "react"
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import cfg from '../utils/config'
import auth from '../utils/auth'
import axios from 'axios'

class ProgressAcessos extends Component {
    state = {
        modal: false,
        progress: [],
    }

    componentDidMount() {
        this.getAcessos()
    }

    getAcessos = () => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/acesso/?meus_acessos=true"

        if (this.props.modulo) {
            url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/acesso/modulo/?meus_acessos_modulo=true"
        }

        axios.get(url, config_me).then((res) => {
            let acessos = []
            let all = res.data.results ?? []

            res.data.total.forEach(total => {
                let utilizados = 0

                if (res.data?.utilizados?.length > 0) {
                    res.data.utilizados.forEach((utilizado) => {
                        if (total.acesso_tipo === utilizado.acesso_tipo) {
                            utilizados = utilizado.tcount
                        }
                    })
                } 

                acessos.push({ ...total, utilizados: utilizados })
            })
            
            if (this.props.onLoad) {
                this.props.onLoad(acessos, all)
            }

            this.setState({ progress: acessos })
        })
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    render() {
        return (
            <>
                <Modal toggle={this.toggle} isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>
                        Acessos
                    </ModalHeader>
                    <ModalBody>
                        <table className="afira-table">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Status</th>
                                    <th>Utilizados</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.progress.map((acesso, key) => {
                                        return (
                                            <tr key={key}>
                                                <td title={acesso.acesso_tipo__descricao}>{acesso.acesso_tipo__descricao}</td>
                                                <td>
                                                    <progress style={{ width: "80px" }} className={acesso.tcount === acesso.utilizados ? "progressMax" : ""} value={acesso.utilizados} max={acesso.tcount}>
                                                    </progress>
                                                </td>
                                                <td title={acesso.utilizados}>{acesso.utilizados}</td>
                                                <td title={acesso.tcount}>{acesso.tcount}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </ModalBody>
                </Modal>
                <button
                    className={this.props.className ? `hdv-btn-forms hdv-btn-blue ${this.props.className}` : "hdv-btn-forms hdv-btn-blue"}
                    style={this.props.style}
                    onClick={() => {
                        this.getAcessos()
                        this.setState({ modal: true })
                    }}>
                    Ver Acessos
                </button>
            </>
        )
    }

}

export default ProgressAcessos