import { useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export default function ConfirmDelete(props) {
    const [open, setOpen] = useState(false)

    const action = () => {
        if (props.enabled) {
            props.action()
            setOpen(false)
        }
    }

    return (
        <>
            <Modal isOpen={open} toggle={() => setOpen(!open)}>
                <ModalHeader>Confirmar exclusão</ModalHeader>
                <ModalBody>
                    <p style={{ fontSize: '14px' }}>Você tem certeza que deseja deletar? <br />Essa ação não poderá ser desfeita.</p>
                </ModalBody>
                <ModalFooter>
                    <div className="float-right">
                        <button onClick={action} className="hdv-btn-forms hdv-btn-red mr-2">Sim</button>
                        <button className="hdv-btn-forms hdv-btn-default mr-0" onClick={() => setOpen(false)}>Cancelar</button>
                    </div>
                </ModalFooter>
            </Modal>
            <button
                disabled={!props.enabled}
                onClick={() => setOpen(true)}
                style={{ border: 'none !important' }}
                className={"hdv-btn-forms hdv-btn-red" + (props.enabled ? "" : "hdv-btn-disable")}>
                Excluir
            </button>
        </>
    )
}

