import React, { Component } from "react";

class Tela404 extends Component {
    render () {
        return (
            <div className="row" style={{justifyContent: "center", alignItems: "center", width: "100%"}}> 
                <div style={{textAlign: "center"}}>
                    <i style={{fontSize: "200px"}} className="las la-unlink"></i>
                    <h1 style={{fontWeight: "bold"}}>Recurso indisponível</h1>
                </div>
            </div>
        )
    }
}  

export default Tela404