import React, { Component } from "react";
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from "axios";
import ReactLoading from 'react-loading'
import { Badge } from 'reactstrap'
import helper from '../../utils/helper'

export default class ValidarInformacoes extends Component {
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        dados_sideccr: [],
        sincronizar_dados: [],
        relatorio_gerado: true,
        text_button: "Sincronizar Dados",
        cooldown: true
    }

    componentDidMount () {
        const timestampLocalStorage = localStorage.getItem("futureTimestampSideccr")
        
        if(timestampLocalStorage) {
            this.decrementMinute()
            this.checkSincronizacao()
        } else {
            this.getData()
        }
    }

    getData = () => {
        const { id, data } = this.props

        this.setState({ relatorio_gerado: false })

        let data_default = data.split('-')
        let inicio = data_default[0].split('/').join('-') + ' 00:00:00'
        let fim = data_default[1].split('/').join('-') + ' 23:59:59'
        
        axios.get(`${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/sideccr/${id}/validacao/?data_inicial=${inicio}&data_final=${fim}`, this.config_me)
        .then(res => {  
            this.setState({ 
                dados_sideccr: res.data.validada ?? [], 
                relatorio_gerado: true, 
                sincronizar_dados: res.data.sincronizar ?? [], 
                cooldown: res.data.sincronizar ? false : true
            })
        })
        .catch((error) => {
            let mensagem = ""

            if(error.response?.data?.mensagem) {
                mensagem = `ERRO SIDECC-R: ${error.response.data.mensagem}.`
            } else {
                mensagem = "Sidecc-r instável, tente novamente mais tarde."
            }

            helper.dispatchEvent("showAviso", {
                message: mensagem,
            })

            this.setState({relatorio_gerado: true})
        })   
    }

    sincronizar_dados = () =>  {
        this.setState({cooldown: true})

        const { id } = this.props

        let bodyFormData = new FormData()
        bodyFormData.append("sincronizar", JSON.stringify(this.state.sincronizar_dados))

        axios({
            method: "POST",
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/sideccr/' + id + '/validacao/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((response) => {
            helper.dispatchEvent("showAviso", {
                message: response.data.mensagem
            })

            this.setFutureTimestamp()
        })
        .catch((error) => {
            helper.dispatchEvent("showAviso", {
                message: "Sincronização falhou, sidecc-r instável."
            })

            this.setState({cooldown: false})
        })
    }

    setFutureTimestamp = () => {
        this.setState({text_button: "Aguarde 10 minutos...", cooldown: true})
        let timestamp = Date.now() + (10 * 60 * 1000)
        localStorage.setItem("futureTimestampSideccr", timestamp)

        this.checkSincronizacao()
    }

    checkSincronizacao = () => {
        const interval = setInterval(() => {
            let check = this.decrementMinute()

            if(check) {
                clearInterval(interval)
            }
        }, 60000) 
    }

    decrementMinute = () => {
        const timestampNow = Date.now()
        const timestampLocalStorage = localStorage.getItem("futureTimestampSideccr")
        
        if(timestampNow >= timestampLocalStorage) {
            this.getData()
            this.setState({text_button: "Sincronizar Dados"})
            localStorage.removeItem("futureTimestampSideccr")

            return true
        } else {
            let timeDifference = timestampLocalStorage - timestampNow
            let minute = Math.max(Math.floor(timeDifference / 60000), 0)
            this.setState({text_button: `Aguarde ${minute} minuto(s)...`})
            return false
        }
    }

    render() {
        const { relatorio_gerado, dados_sideccr, cooldown, text_button } = this.state

        return(
            <>
                <button style={{width: "100%", height: "50px"}} className="hdv-btn-forms hdv-btn-blue" disabled={cooldown} onClick={this.sincronizar_dados}>{ text_button }</button>

                {
                    relatorio_gerado ?
                        <div style={{position:"absolute", height: "90%", overflow: "auto", marginTop: "10px", width: "100%"}}>   
                            <table className="table afira-table">
                                <thead style={{position: "sticky", top: "0", zIndex: "1000", background: "#d5d5d5"}}>
                                    <tr>
                                        <th>Data hora fechada</th>
                                        <th>Usuario</th>
                                        <th>Chave</th>
                                        <th>Medidor</th>
                                        <th>Vazão sistema</th>
                                        <th>Vazão sidecc-r</th>
                                        <th>Mensagem</th>
                                        <th>Sincronizados</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dados_sideccr.map((data, key) => {
                                            return(
                                                <tr key={key}>
                                                    <td title={data.data_hora_fechada}>{data.data_hora_fechada}</td>
                                                    <td title={data.usuario}>{data.usuario}</td>
                                                    <td title={data.chave}>{data.chave}</td>
                                                    <td title={data.medidor}>{data.medidor}</td>
                                                    <td title={data.vazao_sistema}>{data.vazao_sistema}</td>
                                                    <td title={data.vazao_sideccr}>{data.vazao_sideccr}</td>
                                                    <td title={data.mensagem}>{data.mensagem}</td>
                                                    <td>
                                                        {
                                                            data.igual ? 
                                                                <Badge color="success">Sim</Badge> 
                                                            : 
                                                                <Badge color="danger">Não</Badge>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    : 
                        <div style={{ justifyContent: "center", alignItems: "center", width: "100%", margin: "0px", position: "absolute", height: "100%" }} className="row">
                            <div>
                                <ReactLoading className="hdv-report-loading" type="spin" color={"#589bd4"} width={35} />
                            </div>
                        </div>
                }
            </>
        )
    }
}