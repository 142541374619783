import React, { Component } from "react"
import Table from '../../../components/Table'
import ConfirmDelete from '../../../components/ConfirmDelete'
import ModalFormObservacao from "./form"
import auth from '../../../utils/auth'
import cfg from '../../../utils/config'
import moment from 'moment-timezone'
import axios from 'axios'

class Grid extends Component {
    state = {
        data: [],
        table: null,
        loading: true,
        modal: false,
        edit: ""
    }

    getData = (state) => {
        this.setState({ loading: true })
        const userInfo = auth.getUserInfo()
        if (state) {
            let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/observacao/?limit=' + state?.lineCount + state?.filter

            if (state?.url) {
                url = state.url
            }

            url += `&monitorado=${this.props?.monitorado}`

            axios({
                method: 'GET',
                url: url,
                headers: { 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                let sorted = res.data.results
                sorted = sorted.sort((a, b) => new Date(b.data_criado) - new Date(a.data_criado))

                this.setState({
                    loading: false,
                    data: sorted,
                    count: res.data.count,
                    next: res.data.next,
                    previous: res.data.previous,
                })
            })
        }
    }

    editar = () => {
        let data = this.state.data

        let edit = data.filter((edit) => {
            return edit.id === this.state.table?.state.selected[0]
        })

        this.setState({ modal: true, edit: edit })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        this.setState({ loading: true })

        this.state.table?.state.selected.map((obs, index) => {
            requestArray.push(axios.delete(cfg.base_api_url + `${cfg.api_v2}/${userInfo.empresa}/observacao/${obs}/`, config_me))
        })

        axios.all(requestArray).then(axios.spread(() => {
            this.state.table?.fetch()
            this.state.table?.unselect()
        }))
    }

    toggleModal = () => {
        this.setState({ modal: false, edit: "" })
        this.getData()
        this.state.table?.fetch()
        this.state.table?.unselect()
    }

    render() {
        const { table } = this.state

        const columns = [{
            Header: 'Criado em',
            Accessor: 'data_criado',
            Cell: (original) => {
                let data_in_timezone_user = moment.tz(original.data_criado, 'UTC')
                let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')
                return (<span>{data_formatada}</span>)
            }
        }, {
            Header: 'Usuário',
            Accessor: 'usuario.username',
            sortable: false,
        }, {
            Header: 'Observação',
            Accessor: 'observacao',
            sortable: false,
        }, {
            Header: 'Motivo',
            Accessor: 'motivo.descricao',
            sortable: false,
        }]

        return (
            <>
                <div className="hdv-btn-group mt-0">
                    <button onClick={() => { this.setState({ modal: true }) }} className="hdv-btn-forms hdv-btn-green">Novo</button>
                    <button onClick={() => { if (table?.state.enableEditar) this.editar() }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                    <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar} />
                </div>

                <Table
                    checkbox
                    notEditFirstColum={true}
                    data={this.state.data}
                    loading={this.state.loading}
                    count={this.state.count}
                    next={this.state.next}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />

                <ModalFormObservacao
                    open={this.state.modal}
                    closed={this.toggleModal}
                    monitorado={this.props.monitorado}
                    edit={this.state.edit}
                    id={this.state.table?.state.selected[0]}
                />
            </>
        )
    }
}

export default Grid