import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { once } from "underscore"

const DEFAULT_STATE = {
    show: false,
    callback: null,
    callbackClose: null,
    message: ""
}

export const AvisoContext = React.createContext(DEFAULT_STATE)

export class AvisoContextProvider extends Component {
    state = DEFAULT_STATE

    componentDidMount() {
        this.startListener()
    }

    startListener = once(() => [
        document.addEventListener("showAviso", (e) => {
            this.setState({ ...this.state, ...e.detail, show: true })
        })
    ])

    toggle = () => {
        if (this.state.callbackClose) {
            this.state.callbackClose()
        }

        this.setState({ ...this.state, show: !this.state.show })
    }

    reset = () => this.setState(DEFAULT_STATE)

    fireCallback = () => {
        this.state.callback()
        this.setState({ ...this.state, show: false })
    }

    closeModal = (e) => {
        if (e.target.classList?.contains("aviso-backdrop")) {
            this.toggle()
        }
    }

    render() {
        return (
            <AvisoContext.Provider value={this.state}>
                {this.props.children}
                <div className="aviso-backdrop" hidden={!this.state.show} onClick={this.closeModal}>
                    <div className="aviso-feedback">
                        <h5><i className="fa fa-exclamation fa-20px"></i> Aviso</h5>
                        {this.state.message}
                        <br />
                        <div className="aviso-buttons">
                            <Button color="default" onClick={this.toggle}>Fechar</Button>
                            {this.state.callback
                                ?
                                <Button color="primary" onClick={this.fireCallback} className="ml-2">Continuar</Button>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </AvisoContext.Provider>
        )
    }
}