
export default {
    decimalToTime: value => {
        let decimalTimeString = value
        let decimalTime = parseFloat(decimalTimeString)
        decimalTime = decimalTime * 60 * 60;
        let hours = Math.floor((decimalTime / (60 * 60)))
        decimalTime = decimalTime - (hours * 60 * 60)
        let minutes = Math.floor((decimalTime / 60))
        decimalTime = decimalTime - (minutes * 60)
        let seconds = Math.round(decimalTime)
        if (hours < 10) {
            hours = "0" + hours
        }
        if (minutes < 10) {
            minutes = "0" + minutes
        }
        if (seconds < 10) {
            seconds = "0" + seconds
        }
        return ("" + hours + ":" + minutes + ":" + seconds)
    },

    getIconList: () => {
        return [
            'bolt',
            'cloud',
            'cloud-rain',
            'cloud-showers-heavy',
            'smog',
            'snowflake',
            'sun',
            'temperature-low',
            'umbrella',
            'water',
            'wind',
            'map-marked',
            'ban',
            'battery-empty',
            'battery-full',
            'battery-half',
            'battery-quarter',
            'battery-three-quarters',
            'bell',
            'bell-slash',
            'door-closed',
            'door-open',
            'exclamation-triangle',
            'eye',
            'eye-slash',
            'lightbulb',
            'lock',
            'lock-open',
            'map-marker',
            'microphone',
            'signal',
            'tint',
            'tint-slash',
            'video',
            'video-slash',
            'volume-up',
            'volume-off',
            'wifi',
            'cog',
            'fan',
            'camera',
            'bug',
            'key',
            'satellite',
            'burn',
            'filter',
            'radiation',
            'broadcast-tower',
            'industry',
            'plug',
            'trash',
            'shower',
            'traffic-light',
            'heartbeat',
            'tv',
            'fish',
            'power-off',
            'oil-can',
            'charging-station',
            'car-battery',
            'gas-pump',
            'tachometer-alt',
            'cloud-sun',
            'beer',
            'flask',
            'glass-whiskey',
            'archway',
            'city',
            'home',
            'house-damage',
            'landmark',
            'school',
            'torii-gate',
            'building',
            'clinic-medical',
            'hospital',
            'igloo',
            'monument',
            'store',
            'university',
            'campground',
            'dungeon',
            'hospital-alt',
            'industry',
            'mosque',
            'store-alt',
            'vihara',
            'church',
            'gopuram',
            'hotel',
            'kaaba',
            'place-of-worship',
            'synagogue',
            'warehouse',
            'chart-pie',
            'chart-area',
            'bullhorn',
            'chart-bar',
            'chart-line',
            'dollar-sign',
            'money-bill',
            'money-bill-alt',
            'money-bill-wave',
            'donate',
            'hand-holding-usd',
            'seedling',
            'chalkboard',
            'satellite-dish',
            'ruler',
            'ruler-combined',
            'ruler-horizontal',
            'ruler-vertical',
            'clock',
            'stopwatch',
            'hourglass',
            'hourglass-end',
            'hourglass-half',
            'hourglass-start',
            'atom',
            'leaf',
            'seeding',
            'solar-panel',
            'plug',
            'coins',
            'cash-register',
            'percentage',
            'hot-tub',
            'shower',
            'swimmer',
            'swimming-pool',
            'sort-amount-down-alt',
            'sort-amount-up',
            'sort-amount-up-alt',
            'sort-amount-down',
            'toggle-off',
            'toggle-on',
            'skull-crossbones',
            'biohazard',
            'prescription-bottle',
            'prescription-bottle-alt',
            'syringe',
            'pills',
            'temperature-low',
            'vial',
            'vials',
            'tablets',
            'signal'
        ]
    },

    randomKey: (length = 8) => {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_'
        const charactersLength = characters.length
        let counter = 0

        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
            counter += 1
        }

        return result
    },

    dispatchEvent(event, { message, callback = null, callbackClose = null }) {
        try {
            document.dispatchEvent(new CustomEvent(event, {
                detail: {
                    message,
                    callback,
                    callbackClose
                },
            }))
        } catch (err) {
            console.error(err)
        }
    },

    convertCpfCnpj: (cpf_cnpj) => {
        const numeros = String(cpf_cnpj).replace(/\D/g, '');

        if (numeros.length === 11) {
            return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (numeros.length === 14) {
            return numeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else {
            return cpf_cnpj;
        }
    },

    convertDate: (date) => new Date(Date.parse(date)).toLocaleDateString('pt-BR',
        { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    )
}