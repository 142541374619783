const rotas = {
     base: {
          "/automacao": "/automacao",
          "/lista-monitorados": "/lista-monitorado",
          "/supervisorio": "/supervisorio",
          "/cliente": "/cliente",
          "/perfil/cliente": "/cliente/perfil",
          "/usuario": "/usuario",
          "/perfil/usuario": "/usuario/perfil",
          "/acessos": "/acesso",
          "/exportacao-dados": "/exportacao-dados",
          "/equipamento": "/equipamento",
          "/monitorado": "/monitorado",
          "/virtual": "/equipamento",
          "/empresa": "/empresa",
          "/relatorio/logs": "/log-acessos",
          "/relatorio/analitico": "/relatorio",
          "/relatorio-customizado": "/relatorio",
          "/relatorio-consumo": "/relatorio/consumo",
          "/minha-conta": "/configuracao",
          "/configuracao-sistema": "/configuracao",
          "/afiralink": "/afiralink",
          "/relatorio-alertas": "/relatorio/alerta"
     },
     ambiental: {
          "/mira": "/mira",
          "/sideccr": "/sideccr",
          "/relatorio-inema": "/relatorio-ambiental-inema",
          "/relatorio-serhma": "/relatorio-ambiental-serhma"
     }
}


export default rotas