import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ConfirmDelete from '../../components/ConfirmDelete'
import { Redirect } from 'react-router-dom'
import Select from '../../components/Select'
import ReactLoading from 'react-loading'
import helper from '../../utils/helper'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class Form extends Component {

    state = {
        usuario_perfil: { "timezone": "", "email": "", "imagem_perfil": "", "senha": "" },
        formerrors: [],
        redirect: false,
        path: "",
        optionSelected: { "timezone": {} },
        timezone: [],
        imagem_perfil: "",
        configuracao_existe: false,
        confirma_senha: "",
        cooldown: false,
        tab: 0,
        img_qrcode: "",
        codigo: "",
        autenticacao: false,
        delete_autenticacao: false,
        modal: { status: false, message: "" },
        msg_codigo_erro: "",
        loading: false
    }

    componentDidMount = () => {
        this.getData()
    }

    getData = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/configuracao-timezone/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/me/', config_me))

        axios.all(requestArray)
            .then(axios.spread((timezones, configuracoes) => {
                let optionsTimezones = []
                let usuario_perfil_resp = { "timezone": userInfo['timezone'], "email": userInfo['email'], "imagem_perfil": "", "senha": "" }
                let imagem_perfil = ""
                let selectedOption = { "timezone": {} }

                if (timezones.status === 200) {
                    timezones.data.results.forEach((a) => {
                        optionsTimezones.push({ "value": a.codigo, "label": "(" + a.horas + ") " + a.codigo })
                    })
                }

                if (userInfo['timezone']) {
                    selectedOption.timezone = { "value": userInfo['timezone'], "label": userInfo['timezone'] }
                }

                imagem_perfil = userInfo['imagem_perfil']

                this.setState({
                    "timezone": optionsTimezones, usuario_perfil: usuario_perfil_resp, imagem_perfil: imagem_perfil, optionSelected: selectedOption, autenticacao: configuracoes.data.ativo_2fa, delete_autenticacao: configuracoes.data.ativo_2fa
                })
            }))
    }

    handleChangeSistema = (e) => {
        let obj = this.state.usuario_perfil
        obj[e.target.id] = e.target.value
        this.setState({ "usuario_perfil": obj })
    }

    handleChangePerfil = (e) => {
        let obj = this.state.usuario_perfil
        obj[e.target.id] = e.target.files[0]
        if (e.target.files[0])
            this.setState({ "usuario_perfil": obj, imagem_perfil: URL.createObjectURL(e.target.files[0]) })
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.usuario_perfil
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "usuario_perfil": obj })
    }

    handleChangeAutenticacao = async (checked) => {
        this.setState({ loading: true, autenticacao: checked })


        if (checked) {
            await this.gerarQrcode()
        } else {
            await this.excluirValidacao()
        }

        this.setState({ loading: false })
    }

    validation_confirmPassword = () => {
        const { usuario_perfil, confirma_senha } = this.state
        let erro = false

        if (usuario_perfil.senha != "") {
            if (usuario_perfil.senha != confirma_senha) {
                erro = true
            }
        }

        return erro
    }

    gerarQrcode = () => {
        let requestURL = cfg.base_api_url + cfg.api_v2 + "/2fa/"
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        return axios.get(requestURL, config_me)
            .then((response) => {
                this.setState({ img_qrcode: response.data.qrcode })
            })
    }

    testQrcode = () => {
        let bodyFormData = new FormData()
        bodyFormData.append('codigo', this.state.codigo)

        axios({
            method: "POST",
            url: cfg.base_api_url + cfg.api_v2 + "/2fa/",
            data: bodyFormData,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                helper.dispatchEvent("showAviso", {
                    message: response.data.message,
                    callback: () => { this.setState({ img_qrcode: "", delete_autenticacao: true, codigo: "" }) }
                })
            })
            .catch((error) => {
                this.setState({ msg_codigo_erro: error.response.data.message })
            })
    }

    excluirValidacao = () => {
        return axios({
            method: 'PATCH',
            url: cfg.base_api_url + cfg.api_v2 + '/me/',
            data: JSON.stringify({ "ativo_2fa": false }),
            headers: { 'Authorization': 'Bearer ' + auth.getToken(), 'Content-Type': 'application/json' }
        })
            .then(() => {
                this.setState({ img_qrcode: "", delete_autenticacao: false })
            })
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.testQrcode(e)
        }
    }

    saveAction = async () => {
        if (this.validation_confirmPassword()) {
            this.setState({ tab: 1, modal: { status: true, message: "Confirmação de senha inválida!" } })
            return
        }

        this.setState({ cooldown: true })
        let requestURL = ''
        let verb = ''

        verb = 'patch'

        requestURL = cfg.base_api_url + cfg.api_v2 + '/me/'

        let bodyFormData = new FormData()

        for (let key in this.state.usuario_perfil) {
            bodyFormData.append(key, this.state.usuario_perfil[key])
        }

        if(this.state.usuario_perfil.senha === "") {
            bodyFormData.delete("senha")
        }   

        await axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            let userInfo = auth.getUserInfo()
            userInfo.imagem_perfil = res.data.imagem_perfil
            auth.setUserInfo(userInfo, true)

            helper.dispatchEvent("showAviso", {
                message: "Configurações salvas.",
                callback: () => { window.location.reload() }
            })
        })
        .catch(console.error)

        this.setState({ cooldown: false })
    }

    render() {
        const timezone = this.state.optionSelected.timezone
        const modal = this.state.modal
        const { autenticacao, img_qrcode, delete_autenticacao, loading } = this.state

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <Modal isOpen={modal.status} toggle={() => this.setState({ modal: { status: false, message: "" } })}>
                    <ModalHeader>
                        Aviso
                    </ModalHeader>
                    <ModalBody>
                        <span>{modal.message}</span>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => this.setState({ modal: { status: false, message: "" } })} className="hdv-btn-forms hdv-btn-red">Fechar</button>
                    </ModalFooter>
                </Modal>
                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Minha Conta</h4>
                            <div className="gray-background">
                                <i className="fa fa-user fa-2x"></i>
                            </div>
                        </span>
                    </div>
                </div>

                <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.setState({ tab: i })}>
                    <TabList>
                        <Tab>Meus Dados</Tab>
                        <Tab>Senha</Tab>
                        <Tab>Autenticação</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default row">
                            <div className="hdv-file-picker col-md-4">
                                <label>Imagem de Perfil</label>
                                <img style={{width: "200px"}} className={(this.state.imagem_perfil) ? "hdv-logomarca-preview" : "hdv-noshow-item"} srcSet={this.state.imagem_perfil} alt="Perfil Preview" />
                                <br />
                                <input type="file" name="imagem_perfil" id="imagem_perfil" onChange={this.handleChangePerfil} />
                                <label htmlFor="imagem_perfil">Selecionar arquivo</label>
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="email">E-mail</label>
                                <input type="text" name="email" id="email" value={this.state.usuario_perfil.email} onChange={this.handleChangeSistema} />

                                <label htmlFor="timezone">Timezone</label>
                                <Select
                                    value={timezone}
                                    onChange={(e) => this.handleChangeSelect(e, 'timezone')}
                                    options={this.state.timezone}
                                />
                            </div>
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="senha">Nova Senha</label>
                                    <input className="hdv-form-system-password-field" type="password" name="senha" id="senha" value={this.state.usuario_perfil.senha} onChange={this.handleChangeSistema} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="confirma_senha">Confirmar Nova Senha</label>
                                    <input className="hdv-form-system-password-field" type="password" name="confirma_senha" id="confirma_senha" value={this.state.confirma_senha} onChange={(e) => this.setState({ confirma_senha: e.target.value })} />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <div className="hdv-form-default">
                            <div style={{ display: delete_autenticacao ? "none" : "block" }} className='row'>
                                <div className='col-md-6'>
                                    <label>Autenticação de dois fatores</label>
                                    <input disabled={this.state.autenticacao} onChange={() => this.handleChangeAutenticacao(true)} checked={autenticacao} type='checkbox' />
                                </div>
                            </div>

                            <div style={{ margin: "20px 0px", display: delete_autenticacao ? "block" : "none" }} className='row'>
                                <ConfirmDelete enabled={delete_autenticacao} action={() => this.handleChangeAutenticacao(false)} />
                            </div>
                            <div style={{ position: "absolute", left: "0px", margin: "0px 0px 0px 15px", display: loading ? "block" : "none" }} className='row'>
                                <ReactLoading className="hdv-report-loading" type="cylon" color={"#589bd4"} width={50} />
                            </div>

                            <details open={true} className='custom-summary' style={{ display: img_qrcode != "" ? "block" : "none", width: "600px", margin: "20px 0px", padding: "0px 0px 10px 0px" }}>
                                <summary>2FA</summary>
                                <div style={{ margin: "20px 0px 0px 0px" }} className='col-md-6'>
                                    <img style={{ width: "200px", borderRadius: "10px" }} src={`data:image/jpeg;base64,${this.state.img_qrcode}`} />
                                </div>
                                <div className='col-md-12'>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                        <div className='col-md-6' style={{ padding: "0px" }}>
                                            <label>Código</label>
                                            <input onKeyPress={this.handleKeyPress} onChange={(e) => this.setState({ codigo: e.target.value, msg_codigo_erro: "" })} placeholder='Insira o codigo para validar a autenticação' type='text' />
                                        </div>
                                        <div className='col-md-5' style={{ padding: "0px" }}>
                                            <button style={{ width: "100%" }} onClick={this.testQrcode} className='hdv-btn-forms hdv-btn-blue'>Continuar</button>
                                        </div>
                                    </div>
                                    <span id='validacao-codigo'>{this.state.msg_codigo_erro}</span>
                                </div>
                            </details>
                        </div>
                    </TabPanel>
                </Tabs>
                <div style={{ display: this.state.tab === 2 ? "none" : "block" }} className="hdv-btn-group">
                    <button disabled={this.state.cooldown} onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                </div>
            </div>
        )
    }
}

export default Form