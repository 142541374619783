import React, { Component } from 'react'
import Select from '../../../components/Select'
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'
import { RelatorioContext } from '../../../contexts/Relatorio'
import auth from '../../../utils/auth'
import cfg from '../../../utils/config'
import axios from 'axios'

class HeaderCustomizado extends Component {
    static contextType = RelatorioContext

    state = {
        optionSelected: { agrupar: "" },
        datePickerShow: false,
        checkClose: false,
        tipos: [{ value: "entrada_analogica", label: "Entradas Analógicas" }, { value: "entrada_digital", label: "Entradas Digitais" }],
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection', },
        dataFormatada: "",
        detalhes: { data: "" },
        clientes: [],
        monitorados: [],
    }

    userInfo = auth.getUserInfo()

    checarCalendarioAberto = (e) => {
        if (this.state.checkClose === true) {
            let retArray = e.path?.filter((path) => {
                if (path.className === "hdv-datepicker") {
                    return true
                }
                return false
            })

            if (retArray?.length === 0) {
                this.setState({ "datePickerShow": false, checkClose: false })
            }
        } else this.setState({ checkClose: true })

        return true
    }

    componentDidMount = () => {
        window.addEventListener('click', this?.checarCalendarioAberto, false)
        this.getData()
    }

    getData = async () => {
        if(this.userInfo.tipo === "cliente") {
            let optionsCliente = [{value: this.userInfo.cliente, label: this.userInfo.nome}]
    
            this.setState({ detalhes: this.props.DataChange, clientes: optionsCliente })
            this.context.set({ clientes: optionsCliente })
        } else {
            let userInfo = auth.getUserInfo()
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
            let optionsCliente = []

            await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/?limit=100', config_me).then(cliente => {
                if (cliente.data?.results?.length > 0) {
                    cliente.data?.results?.forEach((c) => {
                        optionsCliente.push({ "value": c.id, "label": c.nome_fantasia })
                    })
                }
            })

            this.setState({ detalhes: this.props.DataChange, clientes: optionsCliente })
            this.context.set({ clientes: optionsCliente })
        }
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhes
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        const dataFormatada = ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString()

        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: dataFormatada,
            detalhes: detalhes
        })

        this.props?.change("data", detalhes.data)
    }

    showDatePicker = () => {
        this.setState({ "datePickerShow": !this.state.datePickerShow })
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption })
    }

    handleChangeAgrupar = (selectedOption) => {
        let obj = this.state.detalhes
        obj['agruparOption'] = selectedOption
        obj['agrupar'] = selectedOption
        let optionObj = this.state.optionSelected
        optionObj['agrupar'] = selectedOption
        this.props?.change("agrupar", selectedOption)
        this.setState({ "optionSelected": optionObj, "detalhes": obj })
    }

    render() {
        const { optionSelected } = this.state
        const { DataChange } = this.props

        const agruparOption = Object.keys(optionSelected.agrupar).length ? optionSelected.agrupar : DataChange.agrupar

        return (
            <div className="col-md-12">
                <div className="mt-3 row">
                    <div className="col-md-5">
                        <label>Periodo</label>
                        <InputGroup className="hdv-force-hight">
                            <Input className="hdv-force-hight" placeholder="Selecione o periodo" onChange={() => false} disabled value={this.state.dataFormatada} />
                            <InputGroupAddon addonType="append">
                                <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                    <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                    {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                        <span className={!(this.props.validacao.data) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>Campo Obrigatório</span>
                        <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                            <DateRangePicker
                                locale={rdrLocales.pt}
                                ranges={[this.state.dateRange]}
                                onChange={this.handleSelect}
                            />
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2 mt-2 pl-0">
                    </div>
                    <div className="col-md-4 px-0 pr-3">
                        <label>Agrupar por</label>
                        <Select
                            value={agruparOption}
                            placeholder=""
                            onChange={(e) => this.handleChangeAgrupar(e)}
                            options={this.context.resource.agruparOptions}
                        />
                        <span className={(this.props.validacao.agrupar === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>Campo Obrigatório</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderCustomizado