import React, { Component } from 'react'

class Poco extends Component {

    state = {
        params: {
            descricao: "TESTE POÇO",
            params: {
                instalacao_bomba: 0,
                instalacao_sensor: 0,
                nivel_estatico: 0
            },
            value: 0,
            id: "false",
            forceupdate: 0,
            event: false,
        },
    }

    componentDidUpdate = () => {
        let params = this.state.params
        if (this.state.params?.forceupdate === 0) {
            params['forceupdate'] = 1
            this.setState({ params: params })
        }
    }

    componentDidMount = () => {
        if (this.props.dataWidget)
            this.setState({ params: this.props.dataWidget })
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.dataWidget.value !== nextProps.dataWidget.value)
            this.setState({ params: nextProps.dataWidget })
    }

    generateGradeItem = () => {
        let elm = document.querySelector('.grade-' + this.props.dataWidget.id)
        let html = []

        let scale = parseFloat(this.state.params?.params?.instalacao_bomba) / 10

        if (elm !== null) {
            let count = 1
            let rangeScale = 0
            let range = 0
            while (count <= 13) {
                if (range > 0)
                    html.push(<div key={count} style={{ top: (42 + rangeScale) }} className="hdv-bullet-well-grade-item"> - {range.toFixed(2)}m</div>)
                rangeScale += parseInt((elm.offsetHeight / 12).toFixed())
                range += scale
                count += 1
            }
        }

        return html
    }

    generateBomb = () => {
        const { id } = this.props.dataWidget
        let { params } = this.state.params
        let terrain = document.querySelector(`div[data-poco-id="${id}"]`)
        let elm = document.querySelector('.grade-' + id)
        let nivel_statico = 0
        let rangeScaleBomb = 0
        let nivel_dinamico = 0
        let vazao = 0

        if (elm !== null) {
            let count = 1

            while (count <= 10) {
                rangeScaleBomb += parseInt((elm.offsetHeight / 12).toFixed())
                count += 1
            }

            let scale = parseFloat(params.instalacao_bomba) / 10
            let total = parseFloat(params.instalacao_bomba) + (scale + scale)
            
            let pre_estatico = parseFloat(params.instalacao_bomba) - parseFloat(params.nivel_estatico)
            let pre_dinamico = parseFloat(params.instalacao_bomba) - (Math.abs(this.state.params?.value))

            nivel_statico = (parseInt((elm.offsetHeight).toFixed()) / total) * (pre_estatico + (scale + scale))
            nivel_dinamico = (parseInt((elm.offsetHeight).toFixed()) / total) * (pre_dinamico + (scale + scale))

            nivel_statico += 4
            nivel_dinamico += 4
        }

        if (this.state.params?.event && params.campo_vazao) {
            let splitField = params.campo_vazao.split('_')
            if (splitField.length > 1) {
                if (splitField[0] === "entrada4a20") {
                    vazao = this.state.params.event['entradas_4a20'][parseInt(splitField[1]) - 1]
                } else if (splitField[0] === "entradalogica") {
                    vazao = this.state.params.event['ct_pulso'][parseInt(splitField[1]) - 1]
                }
            } else {
                vazao = this.state?.params?.event?.[splitField[0]]
            }
        }

        return <div className="hdv-bullet-well-draw">
            <div style={{ height: nivel_statico }} className="hdv-bullet-well-water">
                <div className="hdv-bullet-well-static-point">
                    <span style={{"marginLeft": "47px"}} className="hdv-water-pump-desc hdv-water-pump-desc-levels">Estático ({parseFloat(params.nivel_estatico).toFixed(2)}m)</span>
                </div>
                <div style={{ height: nivel_dinamico }} className="hdv-bullet-well-level">
                    <div className="hdv-bullet-well-dynamic-point">
                        <span className="hdv-water-pump-desc">Nível ({this.state?.params?.value?.toFixed(2)}m)</span>
                    </div>
                </div>
            </div>
            <div style={(rangeScaleBomb) ? { top: rangeScaleBomb + 45 } : {}} className="hdv-water-pump-point">
                <span className="hdv-water-pump-desc">Bomba
                    {(params.campo_vazao)
                        ?
                        <span>
                            <i className={(vazao > 0 && vazao < 999) ? "fa fa-sync hdv-water-pump-fan hdv-water-pump-fan-on" : "fa fa-sync hdv-water-pump-fan hdv-water-pump-fan-off"}></i>
                        </span>
                        :
                        <span>
                            <i className="fa fa-cog hdv-water-pump-fan hdv-water-pump-fan-none"></i>
                        </span>
                    }
                </span>
                {(params.campo_vazao && vazao > 0 && vazao < 999)
                    ?
                    <div>
                        <div className="hdv-water-pump-desc hdv-vazao-detalhe" title="Vazão">{vazao?.toFixed(2)} m3/h</div>
                        <div className="hdv-water-pump-desc hdv-vazao-detalhe" title="Vazão Específica">{(vazao?.toFixed(2) / (this.state.params.value?.toFixed(2) - parseFloat(this.state.params.params.nivel_estatico).toFixed(2))).toFixed(2)} m3/h/m</div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    }
    // vazao  / (nivel - estatico) = m3/h/m
    // adicionar check da data se atulizado - considerar cada widget
    // nivel estatico do solo até o ponto
    // nivel do solo

    render() {
        const { id } = this.props.dataWidget

        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento({ showUnity: false })}
                <div className="hdv-bullet-well">
                    <div className="hdv-bullet-well-box">
                        <div className="hdv-bullet-well-terrain" data-poco-id={id}>
                            {this.generateBomb()}
                        </div>
                        <div className={"hdv-bullet-well-grade grade-" + id}>
                            {this.generateGradeItem()}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Poco