import React, { Component } from 'react'
import PrivateRoute from '../../containers/PrivateRoute'
import { Switch } from 'react-router-dom'
import Grid from './grid'
import Form from './form'

class Cliente extends Component {
    render() {
        return (
            <div className="hdv-render-div">
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">

                    <Switch>
                        <PrivateRoute exact path='/cliente' component={Grid} />
                        <PrivateRoute path='/cliente/novo' component={Form} />
                        <PrivateRoute path='/cliente/:id/editar' component={Form} />
                    </Switch>

                </div>
            </div>
        )
    }
}

export default Cliente
