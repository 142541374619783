import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import Form from './form'
import PrivateRoute from '../../containers/PrivateRoute'

class MinhaConta extends Component {
	render() {
		return (
			<div className="hdv-render-div">
				<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <Switch>
                        <PrivateRoute exact path='/minha-conta' component={Form}/>
                    </Switch>
				</div>
			</div>
		)
	}
}

export default MinhaConta
