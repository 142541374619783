import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { Animated } from "react-animated-css"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import logo from "../../assets/ghidro-logo-footer.png"
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import qs from "query-string"
import axios from 'axios'
import './style.css'

class Login extends Component {

    state = {
        new_password: "",
        confirm_password: "",
        token: "",
        msgbox: '',
        redirectToReferrer: false,
        showmessage: false,
        logomarca: logo,
        loadcomplete: false,
        config: { "logomarca": "logomarcas/kronlogo.png", "descricao_horario_atendimento": "Seg. a Sex. das 8:00 às 18:00 ", "email_contato": "contato@ghidro.com.br ", "endereco": "Rua Carlos Ferrari, 18, Sala 610 – Centro – Garça/SP", "facebook": "https://www.facebook.com/ghidromonitoramento/", "link_criar_conta": "https://www.ghidro.com.br", "linkedin": "https://www.linkedin.com/company/g-hidro-monitoramento/", "telefone": "(14) 98161-0616", "youtube": "https://www.youtube.com/channel/UCj1BOaOQTuPMrTgPewSwgvw" },
        cores: { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" },
        modalToggle: false
    }

    componentWillMount = () => {
        let requestArray = []
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let domain = window.location.host

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + `/configuracao-login/?domain=${domain}`, config_me))

        let token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token

        axios.all(requestArray).then(axios.spread((config) => {
            let cores = ''
            let logomarca = ''
            let config_nova = ''

            cores = config.data.cores
            logomarca = config.data.config.logomarca
            config_nova = config.data.config
            document.title = config.data.config.titulo_sistema ?? "Afira"

            this.setState({ cores: cores, opacity: 1, logomarca: logomarca, loadcomplete: true, 'config': config_nova, 'token': token })
        }))
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/create-customer-access/'
        const config = { headers: { 'Content-Type': 'application/json' } }

        if (this.state.new_password !== this.state.confirm_password) {
            this.setState({ showmessage: true, msgbox: 'As senhas digitadas são diferentes.' })
            setTimeout(() => {
                this.setState({ showmessage: false, msgbox: '' })
            }, 5000)
            return
        }

        let bodyFormData = new FormData()
        bodyFormData.append("token", this.state.token)
        bodyFormData.append("new_password", this.state.new_password)
        bodyFormData.append("confirm_password", this.state.confirm_password)

        axios.post(requestURL, bodyFormData, config).then((response) => {
            this.setState({ modalToggle: true })
        })
            .catch((error) => {
                this.setState({ showmessage: true, msgbox: 'Seu TOKEN é inválido, por favor entre em contato com o administrador!' })
                setTimeout(() => {
                    this.setState({ showmessage: false, msgbox: '' })
                }, 5000)
                return
            })
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/login/" } }
        const { redirectToReferrer } = this.state

        if (redirectToReferrer) {
            return <Redirect to={from} />
        }

        if (auth.getToken() !== null) {
            return <Redirect to={from} />
        }
        
        return (
            <>
                <Modal isOpen={this.state.modalToggle}>
                    <ModalHeader>
                        Aviso
                    </ModalHeader>
                    <ModalBody>
                        Este é seu primeiro acesso a nossa plataforma de monitoramento, agora você será redirecionado para tela de Login, onde utilizará seu e-mail e a senha cadastrada.
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.setState({ modalToggle: false, redirectToReferrer: true })} color="success">Ok</Button>
                    </ModalFooter>
                </Modal>
                <div className={(this.state.loadcomplete) ? "body-page" : "hdv-noshow-item"} style={{ 'background': `linear-gradient(to right, ${this.state.cores.login_informacao_background} 50%, transparent 50%` }}>
                    <Animated className="mensagem-erro" animationIn="fadeInDown" animationOut="fadeOutDown" animateOnMount={false} isVisible={this.state.showmessage}>
                        <div>
                            {this.state.msgbox} <br />
                            <i className="fa fa-exclamation mensagem-icone fa-white"></i>
                        </div>
                    </Animated>
                    <div className="container-login" style={{ 'backgroundColor': this.state.cores.login_informacao_background }}>
                        <div className="login-logo-sociais">
                        <div className="logomarca">
                            <img style={{filter: this.state.cores.filtro_logo ? 'brightness(0) invert(1)' : '', maxWidth: "200px"}} src={this.state.logomarca}/>
                        </div>
                        </div>
                        <div className="form-login" style={{ 'backgroundColor': this.state.cores.login_formulario_background }}>

                            <div className="imagem-titulo" style={{ width: "160px", height: "100px", backgroundImage: `url(${this.state.logomarca})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", margin: "0 auto" }}></div>

                            <div className="titulo">Primeiro Acesso</div>

                            <form style={{ marginTop: '70px' }}>
                                <input placeholder="Digite sua senha ..." type="password" id="new_password" onChange={e => this.setState({ new_password: e.target.value })} onKeyPress={this.handleKeyPress} className="usuario" />
                                <input placeholder="Confirme a senha ..." type="password" id="confirm_password" onChange={e => this.setState({ confirm_password: e.target.value })} onKeyPress={this.handleKeyPress} className="usuario" />
                                <div>
                                    <div style={{ 'background': this.state.cores.login_informacao_background, color: this.state.cores.login_formulario_fonte_cor }} className="btn-login" onClick={this.handleSubmit}>
                                        Cadastrar Senha
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Login