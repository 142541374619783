import React, { Component } from "react"
import auth from "../../../utils/auth"
import cfg from '../../../utils/config'
import mascaraParametrizacao from "../../../components/MascaraParametrizacao"
import CardComando from "../../../components/CardComando"
import { isBoolean } from 'underscore'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'
import axios from "axios"

class CamposComandos extends Component {
    state = {
        comandos: [],
        monitorado: { equipamentos: [], fotos: [], parametros: [], automacoes: [], automacoesAtivas: 0 },
        selectedParam: null,
        totalMoveSlides: 0,
        actualMoveSlides: 0,
        totalMoveSlidesComando: 0,
        actualMoveSlidesComando: 0,
        monitorado_id: this.props.monitorado ? this.props.monitorado.value : false,
        requestFinalizada: { parametros: false, comandos: false }
    }

    componentDidMount() {
        const monitorado_id = this.state

        if(this.props.dataShared) {
            this.getDataShared()
        } else {
            if (monitorado_id) {
                this.getData()
                this.getComandos()
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.requestFinalizada.parametros && this.state.requestFinalizada.comandos) {
            this.quantidadeSlides("parametro")
            this.quantidadeSlides("comando")
        }
    }

    getDataShared = () => {
        const url = `${cfg.base_api_url + cfg.api_v2 }/${this.props.monitorado.value}/monitorado-status-supervisorio-link/${this.props.dataShared.hash}/`
        axios.get(url)
        .then((res) => {
            this.setParametros(res.data.parametros, true)
            this.setComandos(res.data.comandos, true)

            this.setState({requestFinalizada: { parametros: true, comandos: true }})
        })
        .catch((error) => {
            console.log(error)
        })
    }

    getData = () => {
        const userInfo = auth.getUserInfo()
        const configMe = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() } }
        const requestURL = `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/monitorado-status/${this.state.monitorado_id}/`

        axios.get(requestURL, configMe).then((res) => {
            this.setParametros(res.data.parametros)
        })
        .catch(console.error)
    }

    getComandos = () => {
        const userInfo = auth.getUserInfo()
        axios({
            method: "GET",
            url: `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/monitorado-comando/?limit=100&monitorado=${this.state.monitorado_id}`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then(res => {
            this.setComandos(res.data?.results)
        })
        .catch(console.error)
    }

    setParametros = (monitorado, finalizada = false) => {
        let selectedParam = this.state.selectedParam
        let requestFinalizada = Object.assign({}, this.state.requestFinalizada)

        if (monitorado.length > 0) {
            if (selectedParam == null) {
                selectedParam = monitorado[0]
            }
        }
        
        if(!finalizada) {
            requestFinalizada['parametros'] = true
            this.setState({ requestFinalizada })
        }

        this.setState({ monitorado, selectedParam, requestFinalizada })
    }

    setComandos = (comandos, finalizada = false) => {
        let monitoradoComandos = []
        let requestFinalizada = Object.assign({}, this.state.requestFinalizada)
        comandos.forEach((comando) => monitoradoComandos.push(comando.comando))

        if(!finalizada) {
            requestFinalizada['comandos'] = true
            this.setState({ requestFinalizada })
        }

        this.setState({ comandos: monitoradoComandos })
    }

    quantidadeSlides = (tipo) => {
        setTimeout(() => {
            let widthVision = document.getElementById(`slide-${tipo}`)?.clientWidth
            let widthList = document.getElementById(`list-${tipo}`)?.clientWidth / 2
            let moveSlide = Math.ceil((widthVision / widthList)) - 2

            if (Math.sign(moveSlide) === -1 || Math.sign(moveSlide) === -0) {
                moveSlide = 0
            }

            this.setState(tipo === "parametro" ? { totalMoveSlides: moveSlide } : { totalMoveSlidesComando: moveSlide })
        }, 2000)
    }

    slideMove = (direcao, tipo) => {
        let newValue = 0
        let actualMoveSlides = tipo === "parametro" ? this.state.actualMoveSlides : this.state.actualMoveSlidesComando
        let totalMoveSlides = tipo === "parametro" ? this.state.totalMoveSlides : this.state.totalMoveSlidesComando

        if (direcao) {
            newValue = actualMoveSlides + 1
        } else {
            newValue = actualMoveSlides - 1
        }

        if (newValue > totalMoveSlides)
            newValue = totalMoveSlides

        document.getElementById(`slide-${tipo}`).style.transform = "translateX(" + -Math.abs(newValue * (document.getElementById(`list-${tipo}`).clientWidth) / 2) + "px)"
        this.setState(tipo === "parametro" ? { actualMoveSlides: newValue } : { actualMoveSlidesComando: newValue })
    }

    render() {
        const { monitorado, selectedParam, requestFinalizada } = this.state

        return (
            <div style={{ margin: "0px" }} className="row">

                {requestFinalizada.comandos && requestFinalizada.parametros ?
                    <>
                        <div id='list-parametro' className="fields-list" style={{ display: monitorado.length > 0 ? "flex" : "none" }}>
                            <div id='slide-parametro' className="fields-slide">
                                {monitorado.map((parametro) => {
                                    let mascara = parametro.campo.mascara
                                    let icone = parametro.campo?.icone?.icone_on
                                    let value = mascara === "padrao" ? String(parametro?.valor_atual) : mascaraParametrizacao[mascara](parametro?.valor_atual)
                                    let elementValue = value.length > 10 ? `${value.slice(0, 10)} ...` : value

                                    if (elementValue == "null") {
                                        elementValue = "---"
                                    }

                                    if (isBoolean(parametro?.valor_atual)) {
                                        let color = ""

                                        if (parametro?.valor_atual) {
                                            icone = parametro.campo?.icone?.icone_on
                                            color = "#44bb52"
                                        } else {
                                            icone = parametro.campo?.icone?.icone_off
                                            color = "silver"
                                        }

                                        elementValue = <div style={{ padding: "15px", width: "20px", borderRadius: "30px", background: color }}></div>
                                    }

                                    return (
                                        <div
                                            key={parametro.id}
                                            className={selectedParam?.id === parametro.id ? "status-box-field active" : "status-box-field"}
                                            style={{ /*cursor: chartLoading ? 'default' : 'pointer'*/ }}
                                            onClick={(e) => {
                                                if (/*!chartLoading &&*/ e.target.tagName != "I") {
                                                    this.setState({ selectedParam: parametro/*, chartLoading: true*/ })
                                                    //this.getChart({ param: parametro })
                                                }
                                            }}>
                                            <div className="status-box-bg"></div>
                                            <div>{parametro.campo?.descricao}</div>
                                            <strong title={value}>{elementValue} {parametro.campo?.unidade}</strong>
                                            <i id='icon-status' style={{ position: "relative", left: "calc(100% - 85px)", fontSize: "85px", bottom: "100%" }} className={`fa ${icone}`}></i>
                                            <div style={{display: this.props.dataShared ? "none" : "block"}}>   
                                                <Link to={`/campo-parametrizado/${parametro.campo?.equipamento?.id}/editar/${parametro?.campo?.id}`}>
                                                    <button><i className="fa fa-pen fa-2x"></i></button>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div
                                style={{ display: (this.state.actualMoveSlides == this.state.totalMoveSlides) ? "none" : "flex" }}
                                onClick={() => this.slideMove(true, "parametro")}
                                className="fields-box-move fields-box-move-right">
                                <i className="fa fa-angle-right fa-2x"></i>
                            </div>
                            <div
                                style={{ display: (this.state.actualMoveSlides == 0) ? "none" : "flex" }}
                                onClick={() => this.slideMove(false, "parametro")}
                                className="fields-box-move fields-box-move-left">
                                <i className="fa fa-angle-left fa-2x"></i>
                            </div>
                        </div>

                        <div id='list-comando' className="fields-list" style={{ display: this.state.comandos?.length > 0 ? "flex" : "none", marginTop: "13px" }}>
                            <div id='slide-comando' className="fields-slide">
                                {this.state.comandos?.map((comando, index) => (
                                    <CardComando dataSharedSupervisory={this.props.dataShared} width="300px" detalhes={comando.equipamento} getComandos={this.getComandos} comando={comando} key={index} compact />
                                ))}
                            </div>
                            <div
                                style={{ display: (this.state.actualMoveSlidesComando == this.state.totalMoveSlidesComando) ? "none" : "flex", height: "200px" }}
                                onClick={() => this.slideMove(true, "comando")}
                                className="fields-box-move fields-box-move-right">
                                <i className="fa fa-angle-right fa-2x"></i>
                            </div>
                            <div
                                style={{ display: (this.state.actualMoveSlidesComando == 0) ? "none" : "flex", height: "200px" }}
                                onClick={() => this.slideMove(false, "comando")}
                                className="fields-box-move fields-box-move-left">
                                <i className="fa fa-angle-left fa-2x"></i>
                            </div>
                        </div>
                    </>
                    :
                    <div style={{ justifyContent: "center", alignItems: "center", width: "100%", marginTop: "20px" }} className="row">
                        <div>
                            <ReactLoading className="hdv-report-loading" type="spin" color={"#589bd4"} width={35} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default CamposComandos