import React, { Component } from 'react'
import CardComando from '../../../components/CardComando'

class Comando extends Component {

    state = {
        gaugeRef: { h: 150 },
        params: {
            descricao: "",
            value: 100,
            id: "",
            params: {}
        }
    }

    componentDidMount = () => {
        if (this.props.dataWidget) {
            let params = this.state.params
            params = this.props.dataWidget

            params['value'] = (100 / parseFloat(this.props.dataWidget.params['valor_maximo'])) * parseFloat(params['value'])

            this.setState({ params: params })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.dataWidget.value !== nextProps.dataWidget.value) {
            let params = this.state.params
            params = nextProps.dataWidget
            params['value'] = (100 / parseFloat(nextProps.dataWidget.params['valor_maximo'])) * parseFloat(params['value'])

            this.setState({ params: params })
        }
    }

    render() {
        const comando = this.props.comandos?.find((c) => c.id == this.props.dataWidget.params.comandos)

        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento({ showUnity: false })}
                {comando
                    ?
                    <div className={"box-gauge id-reserv-" + this.state.params.id}>
                        <div className="box-gauge-margintop">
                            <CardComando compact comando={comando} />
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default Comando