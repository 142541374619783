import React from 'react'
import ModalIcone from '../../components/ModalIcone'
import { JSONTree } from 'react-json-tree'
import { isBoolean } from 'underscore'
import helper from '../../utils/helper'
import ReactLoading from 'react-loading'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ReferenciaParametrizacao from './referencia'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

export default class Form extends React.Component {
    state = {
        detalhes: { data: "" },
        optionSelected: { "cliente": null, "monitorado": null, "item": null },
        validacao: { cliente: true, monitorado: true, item: true },
        clientes: [],
        monitorados: [],
        itens: [],
        data: null,
        validacao_campo: {
            descricao: true, identificador: true, formula: true, decimal: true,
            operador_especial: true, tipo_dado_saida: true, tipo_grafico: true, prioridade: true
        },
        campoParametrizado: {
            descricao: '', identificador: '', formula: '', unidade: '', decimal: '2', operador_especial: 'padrao',
            tipo_dado_saida: 'decimal', tipo_grafico: 'linha', prioridade: '1', icone_on: null, icone_off: null,
            mascara: 'padrao',
        },
        teste: { resultado: '0', invalido: false, erro: '', status: false },
        iconModal: false,
        icone_tipo: "",
        cooldown: true,
        loading: false,
        tab: 0,
        modalReferencia: false,
        detalhesRefencia: {},
        referencias: [],
        isVirtual: false
    }

    componentDidMount = async () => {
        if (this.props.match.params?.imei) {
            this.buscarDados(this.props.match.params?.imei)
        }

        if (this.props.match.params.id) {
            let userInfo = auth.getUserInfo()

            await axios({
                method: 'GET',
                url: `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/campo-customizado/${this.props.match.params.id}/`,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            }).then(res => {
                const { campoParametrizado } = this.state

                if (res.data.icone !== null) {
                    try {
                        let icone
                        if (typeof res.data?.icone == "string") {
                            icone = JSON.parse(res.data.icone)
                        } else {
                            icone = res.data?.icone
                        }

                        if (res.data.tipo_dado_saida === 'booleano') {
                            campoParametrizado['icone_on'] = icone?.icone_on
                            campoParametrizado['icone_off'] = icone?.icone_off
                        } else {
                            campoParametrizado['icone_on'] = icone?.icone_on
                        }
                    } catch (err) {
                        console.error(err)
                    }
                }

                campoParametrizado['formula'] = res.data.formula
                campoParametrizado['descricao'] = res.data.descricao
                campoParametrizado['identificador'] = res.data.identificacao
                campoParametrizado['unidade'] = res.data.unidade
                campoParametrizado['decimal'] = res.data.decimal
                campoParametrizado['mascara'] = res.data?.mascara ?? 'padrao'
                campoParametrizado['operador_especial'] = res.data.operador_especial
                campoParametrizado['prioridade'] = res.data.prioridade
                campoParametrizado['tipo_dado_saida'] = res.data.tipo_dado_saida
                campoParametrizado['tipo_grafico'] = res.data.tipo_grafico

                if (res.data.configuracao !== null) {
                    let configuracoes = JSON.parse(res.data.configuracao)

                    this.setState({ referencias: configuracoes.referencias })
                }

                this.setState({ campoParametrizado })

            }).catch(console.error)
        }
    }

    buscarDados = async (equipamento) => {
        this.setState({ carregandoDados: true })
        let userInfo = auth.getUserInfo()

        await axios({
            method: 'GET',
            url: `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/equipamento/${equipamento}/historico/`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            if(res.data.modelo === 8) {
                this.setState({ isVirtual: true })
            }

            try {
                if (res.data?.last_report === false) return

                let campos = JSON.parse(res.data.last_report)
                this.setState({ data: campos })
            } catch (err) { }
        }).catch(console.error)

        this.setState({ carregandoDados: false })
    }

    handleChange = (e) => {
        let { campoParametrizado, teste } = this.state
        campoParametrizado[e.target.name] = e.target.value

        if (e.target.name === 'descricao' && !this.props.match.params.id) {
            let identificador = String(e.target.value).toLowerCase().replace(/\s/g, '_')
            identificador = identificador.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            identificador = identificador.replace(/[^\w\s]/gi, '')
            if (/^[0-9].+$/.test(identificador))
                identificador = identificador.substring(1)

            campoParametrizado['identificador'] = identificador
        }

        teste['status'] = false

        if (campoParametrizado["tipo_dado_saida"] != "booleano") {
            campoParametrizado["icone_off"] = null
        }

        if (e.target.name === 'tipo_dado_saida') {
            teste['erro'] = ''
            teste['resultado'] = '0'
            teste['invalido'] = false

            this.checa_tipo(e.target.value)
        }

        if (e.target.name === 'tipo_dado_saida' || e.target.name === 'formula') {
            this.setState({ cooldown: true })
        }

        this.setState({ campoParametrizado, teste })
    }

    validation = (keyRemove) => {
        const { campoParametrizado, validacao_campo } = this.state

        let valid = []
        let keys = Object.keys(validacao_campo)

        if(keyRemove) {
            keys = keys.filter((key) => key !== keyRemove)
        }
    
        keys.map(key => {
            if (!campoParametrizado[key]) {
                validacao_campo[key] = false
            } else {
                validacao_campo[key] = true
                valid.push(key)
            }
        })

        this.setState({ validacao_campo })

        return valid
    }

    saveAction() {
        const { campoParametrizado, referencias, isVirtual } = this.state

        let verb, url
        let userInfo = auth.getUserInfo()

        if(isVirtual) {
            let keyRemoveValidation = "formula"
            if(this.validation(keyRemoveValidation).length < 7) return
        } else {
            if (!this.testarFormula()) return
        }
        

        if (this.props.match.params.id) {
            verb = this.props.match.params.id ? "PATCH" : "POST"
            url = `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/campo-customizado/${this.props.match.params.id}/`
        } else {
            verb = "POST"
            url = `${cfg.base_api_url + cfg.api_v2}/${userInfo.empresa}/campo-customizado/`
        }

        let icone = {
            icone_on: campoParametrizado.icone_on,
            icone_off: campoParametrizado.icone_off
        }

        let bodyFormData = new FormData()
        const configuracao = {
            referencias: referencias
        }

        bodyFormData.append('equipamento', this.props.match.params.imei)
        bodyFormData.append('descricao', campoParametrizado.descricao)
        bodyFormData.append('identificacao', campoParametrizado.identificador)
        bodyFormData.append('unidade', campoParametrizado.unidade)
        bodyFormData.append('mascara', campoParametrizado.mascara)
        bodyFormData.append('decimal', campoParametrizado.decimal)
        bodyFormData.append('operador_especial', campoParametrizado.operador_especial)
        bodyFormData.append('prioridade', campoParametrizado.prioridade)
        bodyFormData.append('icone', JSON.stringify(icone))
        bodyFormData.append('tipo_dado_saida', campoParametrizado.tipo_dado_saida)
        bodyFormData.append('tipo_grafico', campoParametrizado.tipo_grafico)
        bodyFormData.append('configuracao', JSON.stringify(configuracao))

        if(isVirtual) {
            if(campoParametrizado.tipo_dado_saida === "string"){
                bodyFormData.append('formula', ` "[${campoParametrizado.identificador}]" `)
            } else {
                bodyFormData.append('formula', `[${campoParametrizado.identificador}]`)
            }
        } else {
            bodyFormData.append('formula', campoParametrizado.formula)
        }

        axios({
            method: verb,
            url: url,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            helper.dispatchEvent("showAviso", {
                message: `Parâmetro ${verb === "POST" ? 'Criado' : 'Editado'} com sucesso`,
                callback: this.props.history.goBack
            })
        }).catch(error => {
            if (error.response.status === 400) {
                let errors = error.response.data

                if (typeof errors === 'object') {
                    if ('acesso' in errors) {
                        helper.dispatchEvent("showAviso", { message: errors['acesso'] })
                    }

                    if ('equipamento' in errors) {
                        helper.dispatchEvent("showAviso", { message: errors['equipamento'] })
                    }

                    if ('identificacao' in errors) {
                        helper.dispatchEvent("showAviso", { message: errors['identificacao'] })
                    }
                }
            }
        })
    }

    converterCampo = (variavel) => {
        let campo = ""

        if (String(variavel) == "") return

        variavel.reverse().forEach((str, index) => {
            index != 0 ? campo += `.${str}` : campo += `${str}`
        })

        this.adicionarNaFormula(`[${campo}]`)
    }

    adicionarNaFormula = (variavel) => {
        const { campoParametrizado } = this.state
        campoParametrizado['formula'] += ` ${variavel}`
        this.setState({ campoParametrizado })
    }

    testarFormula = async () => {
        const { campoParametrizado, teste } = this.state
        this.setState({ cooldown: true })

        try {
            if (this.validation().length == 8) {
                this.setState({ loading: true })

                teste['resultado'] = '0'
                teste['invalido'] = false
                teste['status'] = false
                teste['erro'] = ''
                this.setState({ teste })

                let formula = this.regex(campoParametrizado['formula'])
                let erro = false
                let resultado = "0"

                await this.requestTesteFormula(formula).then((response) => {
                    let sucesso = response.data.message

                    if (sucesso) {
                        resultado = response.data.result
                        erro = this.validation_saida(resultado)
                    } else {
                        erro = true
                    }
                }).catch(() => {
                    erro = true
                })

                if (erro) {
                    teste['invalido'] = true
                    teste['erro'] = 'Operação Inválida!'
                } else {
                    teste['resultado'] = resultado
                    teste['invalido'] = false
                    teste['status'] = true

                    this.setState({ cooldown: false })
                }

                this.setState({ teste, loading: false })
            }

            return teste['status']
        } catch (err) {
            teste['invalido'] = true
            teste['erro'] = 'Operação Inválida!'
            this.setState({ cooldown: true, teste })
            return false
        }
    }

    requestTesteFormula = (formula) => {
        const data = {
            formula: formula
        }

        return axios({
            method: 'POST',
            url: "https://az3ku1ksma.execute-api.us-east-1.amazonaws.com/formulas_check",
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(data)
        })
    }

    regex = (formula) => {
        try {
            let string_formula = formula

            Array.from(formula.matchAll(/\[(.*?)\]/g)).forEach(v => {
                let campo = { ... this.state.data }

                v[1].split('.').forEach((item) => campo = campo[item] ? campo[item] : 0)

                string_formula = string_formula.replace(v[0], campo)
            })

            return string_formula
        } catch (err) {
            console.error(err)
        }
    }

    validation_saida = (result) => {
        let error = false

        switch (this.state.campoParametrizado.tipo_dado_saida) {
            case "decimal":
                isNaN(result) ? error = true : false

                break
            case "inteiro":
                isNaN(result) ? error = true : false

                break
            case "booleano":

                if (result === "1" || result === "True") {
                    result = true
                } else if (result === "0" || result === "False") {
                    result = false
                }

                !isBoolean(result) ? error = true : false

                break
            default:
                break
        }

        return error
    }

    mostrar_saida = () => {
        let saida = null

        switch (this.state.campoParametrizado.tipo_dado_saida) {
            case "decimal":
                saida = <span className='operacao-valida'>Resultado: <b>{parseFloat(this.state.teste.resultado).toFixed(parseInt(this.state.campoParametrizado.decimal))} {this.state.campoParametrizado.unidade}</b></span>
                break
            case "inteiro":
                saida = <span className='operacao-valida'>Resultado: <b>{parseInt(this.state.teste.resultado)} {this.state.campoParametrizado.unidade}</b></span>
                break
            case "booleano":
                let resultado_booleano = this.state.teste.resultado

                if (resultado_booleano === "1" || resultado_booleano === "True") {
                    resultado_booleano = true
                } else if (resultado_booleano === "0" || resultado_booleano === "False") {
                    resultado_booleano = false
                }

                saida = <span className='operacao-valida'>Resultado: <b>{String(resultado_booleano)} {this.state.campoParametrizado.unidade}</b></span>
                break
            default:
                saida = <span className='operacao-valida'>Resultado: <b>{this.state.teste.resultado} {this.state.campoParametrizado.unidade}</b></span>
                break
        }

        return saida
    }

    checa_tipo = (value) => {
        let campoParametrizado = this.state.campoParametrizado
        switch (value) {
            case 'inteiro':
                campoParametrizado.decimal = "2"
                break
            case 'booleano':
                campoParametrizado.decimal = "2"
                campoParametrizado.unidade = ""
                break
            case 'string':
                campoParametrizado.decimal = "2"
                campoParametrizado.unidade = ""
                campoParametrizado.tipo_grafico = "linha"
                campoParametrizado.operador_especial = "padrao"
                break
            default:
                campoParametrizado.decimal = "2"
                break
        }

        this.setState({ campoParametrizado })
    }

    toggleIconModal = (e) => {
        this.setState({ iconModal: true, icone_tipo: e.target.id })
    }

    add_icon = (icon) => {
        let campoParametrizado = this.state.campoParametrizado
        campoParametrizado['icone_on'] = icon
        this.setState({ campoParametrizado, iconModal: false })
    }

    add_icon_on = (icon) => {
        let campoParametrizado = this.state.campoParametrizado
        campoParametrizado['icone_on'] = icon
        this.setState({ campoParametrizado, iconModal: false })
    }

    add_icon_off = (icon) => {
        let campoParametrizado = this.state.campoParametrizado
        campoParametrizado['icone_off'] = icon
        this.setState({ campoParametrizado, iconModal: false })
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }

    toggleModalReferencia = (status) => {
        this.setState({ modalReferencia: status })
    }

    render() {
        const { data, validacao_campo, cooldown, loading, tab, modalReferencia, referencias, isVirtual } = this.state

        const theme = {
            scheme: 'monokai',
            author: 'wimer hazenberg (http://www.monokai.nl)',
            base00: '#F2F2F2',
            base01: '#212529',
            base02: '#212529',
            base03: '#212529',
            base04: '#212529',
            base05: '#212529',
            base06: '#212529',
            base07: '#212529',
            base08: '#212529',
            base09: '#212529',
            base0A: '#212529',
            base0B: '#212529',
            base0C: '#212529',
            base0D: '#212529',
            base0E: '#212529',
            base0F: '#212529',
        }

        return (
            <>
                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            {this.props.match.params.id ? <h4>Editar Parametrização</h4> : <h4>Parametrizar Campos</h4>}
                            <div className="gray-background">
                                <i className="fa fa-hdd fa-2x"></i>
                            </div>
                        </span>
                    </div>
                </div>

                <Tabs className="tabs-style" selectedIndex={tab} onSelect={(i) => this.handleTab(i)}>
                    <TabList>
                        <Tab>Parametrizar</Tab>
                        <Tab>Referências</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="row">
                            <div className={isVirtual ? "col-md-12" : "col-md-8"}>
                                <form className="hdv-form-default">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="required" htmlFor="descricao">Descrição do parâmetro</label>
                                            <input
                                                type="text"
                                                name="descricao"
                                                value={this.state.campoParametrizado.descricao}
                                                onChange={(e) => this.handleChange(e)}
                                            />
                                            <span id="validacao-descricao" className={!validacao_campo.descricao ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                        </div>
                                        <div style={{/* display: this.props.match.params.id ? "none" : "block" */ }} className="col-md-6">
                                            <label className="required" htmlFor="identificador">Identificador</label>
                                            <input
                                                type="text"
                                                name="identificador"
                                                value={this.state.campoParametrizado.identificador}
                                                readOnly={true}
                                            />
                                            <span id="validacao-identificador" className={!validacao_campo.identificador ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                            {this.state.campoParametrizado.invalido ? <span id="validacao-identificador">Identificador inválido!</span> : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            !isVirtual? 
                                                <div className="col-md-12">
                                                    <label className="required" htmlFor="formula">Fórmula</label>
                                                    <input
                                                        type="text"
                                                        name="formula"
                                                        value={this.state.campoParametrizado.formula}
                                                        placeholder="Exemplo: [ct_pulso_1] * 2"
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                    <span id="validacao-formula" className={!validacao_campo.formula ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                                </div>
                                            : null
                                        }
                                        <div className="col-md-6">
                                            <label className="required" htmlFor="tipo_dado_saida">Tipo do dado na Saída</label>
                                            <select value={this.state.campoParametrizado.tipo_dado_saida} onChange={(e) => this.handleChange(e)} name="tipo_dado_saida" id="tipo_dado_saida">
                                                <option value="decimal">Decimal</option>
                                                <option value="inteiro">Inteiro</option>
                                                <option value="booleano">Booleano</option>
                                                <option value="string">Texto</option>
                                            </select>
                                            <span id="validacao-formula" className={!validacao_campo.tipo_dado_saida ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                        </div>
                                        <div style={{ display: (this.state.campoParametrizado.tipo_dado_saida === "string") ? "none" : "block" }} className="col-md-6">
                                            <label className="required" htmlFor="tipo_grafico">Visualização em Gráficos</label>
                                            <select value={this.state.campoParametrizado.tipo_grafico} onChange={(e) => this.handleChange(e)} name="tipo_grafico" id="tipo_grafico">
                                                <option value="linha">Gráfico de Linha</option>
                                                <option value="barra">Gráfico de Barras</option>
                                            </select>
                                            <span id="validacao-formula" className={!validacao_campo.tipo_grafico ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                        </div>
                                    </div>
                                    <div style={{ display: (this.state.campoParametrizado.tipo_dado_saida === "booleano" || this.state.campoParametrizado.tipo_dado_saida === "string") ? "none" : "flex" }} className="row">

                                        <div className="col-md-12">
                                            <label className="required" htmlFor="unidade">Unidade</label>
                                            <input
                                                type="text"
                                                name="unidade"
                                                value={this.state.campoParametrizado.unidade}
                                                onChange={(e) => this.handleChange(e)}
                                            />
                                        </div>

                                        <div style={{ display: (this.state.campoParametrizado.tipo_dado_saida === "inteiro") ? "none" : "block" }} className="col-md-3">
                                            <label className="required" htmlFor="unidade">Decimal</label>
                                            <input
                                                type="number"
                                                name="decimal"
                                                value={this.state.campoParametrizado.decimal}
                                                onKeyPress={(e) => e.preventDefault()}
                                                onChange={(e) => this.handleChange(e)}
                                                max="6"
                                                min="2"
                                            />
                                            <span id="validacao-formula" className={!validacao_campo.decimal ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div style={{ display: (this.state.campoParametrizado?.tipo_dado_saida === "string") ? "none" : "block" }} className="col-md-6">
                                            <label className="required" htmlFor="operador_especial">Operador Especial</label>
                                            <select value={this.state.campoParametrizado.operador_especial} onChange={(e) => this.handleChange(e)} name="operador_especial" id="operador_especial">
                                                <option value="padrao">Padrão</option>
                                                <option value="soma">Incremental</option>
                                                <option value="volume_dia">Volume do dia</option>
                                                {/* <option value="conversor_ponto_flutuante">Conversor de ponto flutuante IEEE-754</option> */}
                                            </select>
                                            <span id="validacao-formula" className={!validacao_campo.operador_especial ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="required" htmlFor="mascara">Máscara</label>
                                            <select value={this.state.campoParametrizado.mascara} onChange={(e) => this.handleChange(e)} name="mascara" id="mascara">
                                                <option value="padrao">Padrão</option>
                                                <option value="horas">Horas, minutos e segundos</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div style={{ display: (this.state.campoParametrizado.tipo_dado_saida !== "booleano") ? "block" : "none" }} className="row">
                                        <div className="col-md-12">
                                            <label className="required">Icone</label>
                                            <div id='icone' onClick={this.toggleIconModal} className="escolha-icones">
                                                <i id='icone' className={"fa " + ((this.state.campoParametrizado.icone_on) ? this.state.campoParametrizado.icone_on : "fa-hdd") + " fa-4x"}></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: (this.state.campoParametrizado.tipo_dado_saida === "booleano") ? "flex" : "none" }} className="align-items-center">
                                        <div className="mr-4">
                                            <label className="required">Icone (Verdadeiro)</label>
                                            <div onClick={this.toggleIconModal} id="iconeOn" className="escolha-icones">
                                                <i id="iconeOn" className={"fa " + ((this.state.campoParametrizado.icone_on) ? this.state.campoParametrizado.icone_on : "fa-hdd") + " fa-4x"}></i>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="required">Icone (Falso)</label>
                                            <div onClick={this.toggleIconModal} id="iconeOff" className="escolha-icones">
                                                <i id="iconeOff" className={"fa " + ((this.state.campoParametrizado.icone_off) ? this.state.campoParametrizado.icone_off : "fa-hdd") + " fa-4x"}></i>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <ModalIcone open={this.state.iconModal} toggle={() => this.setState({ iconModal: !this.state.iconModal })}>
                                    {helper.getIconList().map((icon, key) => {
                                        return (
                                            <li key={key}>
                                                <i className={"fa fa-" + icon} onClick={() =>
                                                    this.state.icone_tipo == "icone" ?
                                                        this.add_icon("fa-" + icon)
                                                        : this.state.icone_tipo == "iconeOn" ?
                                                            this.add_icon_on("fa-" + icon)
                                                            : this.add_icon_off("fa-" + icon)
                                                }
                                                ></i>
                                            </li>
                                        )
                                    })
                                    }
                                </ModalIcone>
                                {
                                    !isVirtual ? 
                                        <div style={{ display: 'flex', margin: '25px 0', alignItems: 'center' }}>
                                            <button onClick={this.testarFormula} className="hdv-btn-forms hdv-btn-blue">
                                                Testar fórmula
                                            </button>
                                            {
                                                loading ?
                                                    <div style={{ marginLeft: "50px" }}><ReactLoading className="hdv-report-loading" type="cylon" color={"#589bd4"} width={50} /></div>
                                                    :
                                                    this.state.teste.invalido ? <span className="operacao-invalida">{this.state.teste.erro}</span> : this.mostrar_saida()
                                            }
                                        </div>
                                    : <div style={{marginBottom: "20px"}}></div>
                                }
                            </div>
                            {
                                !isVirtual ?
                                    <div className="col-md-4 campo-customizado">
                                        <h6 style={{
                                            borderBottom: "2px solid #e6e6e6",
                                            padding: "6px 0px",
                                            marginBottom: "15px",
                                            fontWeight: "bold",
                                            color: "#979797"
                                        }}>
                                            Parâmetros com base no último evento
                                        </h6>
                                        <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                            <JSONTree
                                                data={data}
                                                labelRenderer={([key]) => <span>{key}: </span>}
                                                hideRoot={true}
                                                theme={theme}
                                                valueRenderer={(valueAsString, value, ...keyPath) => (
                                                    <button
                                                        onClick={() => this.converterCampo(keyPath)}
                                                        style={{ backgroundColor: "#44bb52", color: "white", borderRadius: "5px" }}>
                                                        {valueAsString}
                                                    </button>
                                                )}
                                            />
                                        </div>
                                    </div>
                                : null
                            }
                        </div>
                        <div style={{ marginTop: "0px" }} className="hdv-btn-group">
                            {(this.props.match.params.id) ? "" : <button onClick={() => this.saveAction(false)} disabled={isVirtual ? false : cooldown} className="hdv-btn-forms hdv-btn-green">Salvar e Cadastrar um Novo</button>}
                            {(this.props.match.params.id) ? <button onClick={() => this.saveAction(false)} disabled={isVirtual ? false : cooldown} className="hdv-btn-forms hdv-btn-green">Salvar</button> : ""}
                            <button onClick={this.props.history.goBack} className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <button onClick={() => this.setState({ modalReferencia: true })} className="hdv-btn-forms hdv-btn-blue">Nova referência</button>
                        <ReferenciaParametrizacao
                            modalReferencia={modalReferencia}
                            toggleModal={this.toggleModalReferencia}
                            referencias={this.state.referencias}
                            setReferecias={(referencias) => this.setState({ referencias })}
                        />
                    </TabPanel>
                </Tabs>
            </>
        )
    }
}