import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ButtonsForm from '../../components/ButtonsForm'
import Select from '../../components/Select'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from '../../utils/helper'
import axios from 'axios'


class Form extends Component {
    state = {
        detalhes: { nome: "", tipo: "", perfil_id: "", email: "", senha: "", status: "ativo" },
        validacao_campo: { nome: true, tipo: true, perfil_id: true, email: true, senha: true, status: true },
        formerrors: [],
        perfis: [],
        redirect: false,
        path: "",
        permissoes: [],
        open_group_block: {},
        selected_permission: [],
        tipos: [{ value: "admin", label: "Administrador" }, { value: "normal", label: "Normal" }],
        selects: { tipo: {}, perfil_id: {} },
        informacoes_usuario: false,
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/perfil/', config_me))

        axios.all(requestArray).then(axios.spread((perfis) => {
            let perfilSelect = []
            if (perfis.data?.results?.length > 0) {
                perfis.data.results.forEach((value) => {
                    if (!value?.nome?.includes("- Administrador")) {
                        perfilSelect.push({ label: value.nome, value: value.id })
                    }
                })
            }

            this.setState({ "perfis": perfilSelect, informacoes_usuario: userInfo })
        }))
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let userInfo = auth.getUserInfo()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/' + this.props.match.params.id + '/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((res) => {
            let objReturn = res.data
            delete objReturn.id
            objReturn['email'] = objReturn.usuario__username
            delete objReturn.usuario__username

            let selects = this.state.selects
            selects['tipo'] = { value: objReturn.tipo, label: objReturn.tipo.charAt(0).toUpperCase() + objReturn.tipo.slice(1) }
            selects['perfil_id'] = { value: objReturn.perfil, label: objReturn.perfil_nome }

            delete objReturn.perfil__nome
            objReturn['senha'] = ""
            objReturn['perfil_id'] = objReturn.perfil

            delete objReturn.perfil
            delete objReturn.cliente
            delete objReturn.data_atualizado
            delete objReturn.data_criado
            delete objReturn.empresa
            delete objReturn.imagem_perfil
            delete objReturn.perfil_nome
            delete objReturn.timezone
            delete objReturn.usuario

            if (userInfo.tipo === "cliente") {
                delete objReturn.tipo
            }

            this.setState({ selects: selects, detalhes: objReturn })
        })
    }

    handleChange = (field, e) => {
        let obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj[field] = (e.target.checked === true) ? true : false
        } else {
            obj[field] = e.target.value
        }

        this.setState({ "detalhes": obj })
    }

    validationFields = (detalhes, ignore = []) => {
        const validacao_campo = Object.assign({}, this.state.validacao_campo)
        let erro = false

        for (let i in detalhes) {
            if (detalhes[i] === "") {
                if (this.props.match.params.id && i === "senha" || ignore.includes(i)) {

                } else {
                    validacao_campo[i] = false
                    erro = true
                }

            } else {
                validacao_campo[i] = true
            }
        }

        this.setState({ validacao_campo: validacao_campo })
        return erro
    }

    saveAction = (e, novoCadastro = false) => {
        e.preventDefault()

        this.setState({ cooldown: true })
        let { detalhes } = this.state
        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/' + this.props.match.params.id + '/'
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/'
            verb = 'post'
        }

        if (userInfo?.tipo === "cliente") {
            delete detalhes.tipo
        }

        if (this.validationFields(detalhes, ["perfil_id"])) {
            this.setState({ cooldown: false })
            return
        }

        let bodyFormData = new FormData()
        for (let key in detalhes) {
            if (key === "perfil_id") {
                bodyFormData.append("perfil", detalhes[key])
            } else if (key === "perfil" && this.props.match.params.id) {
                bodyFormData.append("perfil", detalhes[key].id)
            } else {
                bodyFormData.append(key, detalhes[key])
            }
        }

        if (verb === "patch" && detalhes['senha'] === "") {
            bodyFormData.delete("senha")
        }

        if (detalhes.tipo == "admin") {
            bodyFormData.delete("perfil")
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            this.setState({ validacao_campo: { nome: true, tipo: true, perfil_id: true, email: true, senha: true, status: true } })

            if (novoCadastro) {
                window.location.reload()
            } else {
                helper.dispatchEvent("showAviso", {
                    message: `Usuário ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`,
                    callback: () => this.setState({ redirect: true, path: "/usuario" }),
                    callbackClose: verb === 'post' ? () => {
                        window.location.href = `/usuario/${res.data.id}/editar`
                        setTimeout(() => window.location.reload(), 1000)
                    } : null
                })
            }
        }).catch((error) => {
            if (error.response.status === 413) {
                helper.dispatchEvent("showAviso", {
                    message: "Imagem muito grande. Por favor, escolha uma imagem menor.",
                    callback: () => this.setState({ cooldown: false })
                }
                )
            } else {
                let erros = Object.entries(error.response.data)
                let validacao = { ...this.state.validacao_campo }

                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        validacao[erros[i][0]] = false
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span) span.innerHTML = erros[i][1]
                    }
                }
                this.setState({ validacao_campo: validacao })
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    handleChangeSelect = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = String(selectedOption.value)

        let selects = this.state.selects
        selects[field] = selectedOption

        this.setState({ "selects": selects, "detalhes": obj })
    }

    render() {
        const { tipo, perfil_id } = this.state.selects
        const { cooldown, validacao_campo, detalhes } = this.state

        if (this.state.redirect) {
            history.pushState({}, "", location.href)
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar Usuário</h4> : <h4>Novo Usuário</h4>}
                        <div className="gray-background">
                            <i className="fa fa-users fa-2x"></i>
                        </div>
                    </span>
                </div>

                <form className="hdv-form-default">
                    <label htmlFor="nome">Nome</label>
                    <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={(e) => this.handleChange('nome', e)} />
                    <span id="validacao-nome" className={!validacao_campo.nome ? "" : "hdv-noshow-item"}>
                        Campo não pode ser nulo!
                    </span>
                    {
                        (this.state.informacoes_usuario.tipo === "cliente")
                            ?
                            null
                            :
                            <>
                                <label htmlFor="nome">Tipo</label>
                                <Select
                                    value={tipo}
                                    onChange={(e) => this.handleChangeSelect(e, 'tipo')}
                                    options={this.state.tipos}
                                />
                                <span id="validacao-tipo" className={!validacao_campo.tipo ? "" : "hdv-noshow-item"}>
                                    Campo não pode ser nulo!
                                </span>
                            </>
                    }

                    {detalhes.tipo != "admin"
                        ?
                        <>
                            <label htmlFor="perfil">Perfil</label>
                            <Select
                                value={perfil_id}
                                disabled={perfil_id?.label?.includes("- Administrador")}
                                onChange={(e) => this.handleChangeSelect(e, 'perfil_id')}
                                options={this.state.perfis}
                            />
                            <span id="validacao-perfil_id" className={!validacao_campo.perfil_id ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </>
                        :
                        null
                    }

                    <div className="row">
                        <div className="col-md-6">
                            <label>E-mail</label>
                            <input type="text" autoComplete="new-password" value={this.state.detalhes.email} onChange={(e) => { this.handleChange('email', e) }} />
                            <span id="validacao-email" className={!validacao_campo.email ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </div>
                        <div className="col-md-6">
                            <label>Senha</label>
                            <input type="password" autoComplete="new-password" className="hdv-form-system-password-field" value={this.state.detalhes.senha} onChange={(e) => { this.handleChange('senha', e) }} />
                            <span id="validacao-senha" className={!validacao_campo.senha ? "" : "hdv-noshow-item"}>
                                Campo não pode ser nulo!
                            </span>
                        </div>
                    </div>

                    <label htmlFor="nome">Status</label>
                    <div className="d-flex">
                        <label className="hdv-fixed">
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'ativo'} value="ativo" onChange={(e) => this.handleChange('status', e)} />
                            Ativo
                        </label>
                        <label className="hdv-fixed">
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'bloqueado'} value="bloqueado" onChange={(e) => this.handleChange('status', e)} />
                            Bloqueado
                        </label>
                        <label className="hdv-fixed">
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'cancelado'} value="cancelado" onChange={(e) => this.handleChange('status', e)} />
                            Cancelado
                        </label>
                    </div>
                    <span id="validacao-status" className={!validacao_campo.status ? "" : "hdv-noshow-item"}>
                        Campo não pode ser nulo!
                    </span>
                </form>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/usuario"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={cooldown}
                        buttons={[{
                            method: "post",
                            button: <button onClick={(e) => this.saveAction(e, false)} className="hdv-btn-forms hdv-btn-green" disabled={cooldown}>Salvar</button>,
                        }, {
                            method: "post",
                            button: <button style={{ display: this.props.match.params.id ? "none" : "inline-block" }} onClick={(e) => this.saveAction(e, true)} className="hdv-btn-forms hdv-btn-greenlight " disabled={cooldown}>Salvar e Cadastrar um Novo</button>
                        }, {
                            method: "patch",
                            button: <button onClick={(e) => this.saveAction(e, false)} className="hdv-btn-forms hdv-btn-green" disabled={cooldown}>Salvar</button>,
                        },
                        ]}
                    />
                    <Link to="/usuario">
                        <button className="hdv-btn-forms hdv-btn-orange" disabled={cooldown}>Cancelar</button>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Form