import React, { Component } from 'react'
import helper from '../../../utils/helper'

class ValorAtual extends Component {
    state = {
        params: {
            descricao: "",
            value: 150,
            metric: "m",
            params: false,
            update: false,
            type_el: false,
            unidade: '',
        },
        previousColorText: 'black-txt',
    }

    componentWillMount = () => {
        if (this.props.dataWidget)
            this.setState({ params: this.props.dataWidget })
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.dataWidget.value !== nextProps.dataWidget.value)
            this.setState({ params: nextProps.dataWidget })
    }

    render() {
        let { params, value, unidade } = this.state.params
        let value_show = ""
        let color_text = "black-txt"
        let background_color = "hdv-bloco-informacao"
        let previousBackgroundColor = this.state.previousBackgroundColor

        try {
            if ([null, undefined].includes(value) || isNaN(value)) {
                value_show = ""
            } else {
                if (value?.toFixed(2) <= parseFloat(params.valor_normal)) {
                    color_text = "green-txt"
                    background_color = "hdv-bloco-informacao"
                } else if (value?.toFixed(2) > parseFloat(params.valor_normal) && value?.toFixed(2) < parseFloat(params.valor_alerta)) {
                    color_text = "white-txt"
                    background_color = "background-yellow"
                } else if (value?.toFixed(2) >= parseFloat(params.valor_perigo)) {
                    color_text = "white-txt"
                    background_color = "background-red"
                }

                value_show = parseFloat(value)?.toFixed(2)
            }
        } catch (err) {
            console.error(err)
            value_show = String(value)
        }

        if (this.state.params.type_el === 2) {
            value_show = String(helper.converte_decimal_to_hhmmss(value))
        }
        
        if (this.state.previousColorText !== color_text) {
            this.setState({ previousColorText: color_text })
        }

        return (
            <div className={` ${color_text != 'green-txt' ? color_text : previousBackgroundColor} ${background_color}  hdv-bloco-informacao hdv-chart-bloco-reactgridlayout hdv-top-bar-hidden `}>
                {this.props.dataWidget?.ultimoEvento()}
                <div className="box-simple-value"  >
                    <div className="hdv-metric-text">{this.state.params.metric}</div>
                    <div className="bar-value">
                        <div className="layout-inlineflex"><i className={"fa " + this.state.params.params.icone + " icon-big " + color_text}></i></div>
                        <div className={"layout-inlineflex " + color_text}>
                            <strong className={"value-super " + color_text}>
                                {value_show}
                            </strong>
                            <strong>{unidade}</strong>
                        </div>
                    </div>
                    <div className="bar-info">
                        {(this.state.params.params.valor_normal)
                            ?
                            <div className="layout-inlineflex bar-label bar-label-green"> (&lt;{this.state.params.params.valor_normal}) </div>
                            :
                            null
                        }
                        {(this.state.params.params.valor_alerta)
                            ?
                            <div className="layout-inlineflex bar-label bar-label-yellow"> (&gt;{this.state.params.params.valor_alerta}&lt;) </div>
                            :
                            null
                        }
                        {(this.state.params.params.valor_perigo)
                            ?
                            <div className="layout-inlineflex bar-label bar-label-red"> (&gt;{this.state.params.params.valor_perigo}) </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ValorAtual