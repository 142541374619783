import React, { Component } from "react"
import Table from '../../components/Table'
import { Redirect } from 'react-router-dom'
import { Badge } from "reactstrap"
import cfg from '../../utils/config'
import auth from '../../utils/auth'
import axios from 'axios'

class AutomacoesAfiraLink extends Component {
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    userInfo = auth.getUserInfo()

    state = {
        data: [],
        loading: true,
        selected: null,
        redirectToEdit: false,
    }

    getData = (state) => {
        this.setState({ loading: true })
        const requestURL = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/afiralink-lista/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(requestURL, this.configMe).then((res) => {
            let { count, results, next, previous } = res.data

            this.setState({
                loading: false,
                data: res.data,
                count,
                next,
                previous,
            })
        })
    }

    editar = () => {
        let imei = this.state.data.filter((item) => item.id === this.state?.table?.state?.selected[0])[0].imei

        let toGo = "/afiralink/" + this.state?.table?.state?.selected[0] + `/${imei}` + '/automacoes'
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    render() {
        const { redirectToEdit, table } = this.state

        if (redirectToEdit) {
            return <Redirect to={redirectToEdit.pathname} />
        }

        const columns = [{
            Header: 'Modelo',
            Accessor: 'modelo',
            sortable: true
        }, {
            Header: 'Imei',
            Accessor: 'imei',
            sortable: true
        }, {
            Header: 'Automações Cadastradas',
            Accessor: '',
            sortable: false,
            Cell: (original) => {
                let label = "Não"
                let color = "secondary"

                if (original.automacao_local) {
                    label = "Sim"
                    color = "success"
                }

                return <Badge href="#" color={color}>{label}</Badge>
            }
        }]

        return (
            <div className="hdv-render-div">
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Afiralink</h4>
                        <div className="gray-background">
                            <i className="fa fa-hdd fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-btn-group">
                    <button onClick={() => { if (table?.state?.enableEditar) { this.editar() } }}
                        className={"hdv-btn-forms hdv-btn-green " + (table?.state?.enableEditar ? "" : "hdv-btn-disable")}>
                        Automação
                    </button>
                </div>

                <Table
                    checkbox
                    notEditFirstColum={true}
                    data={this.state.data}
                    loading={this.state.loading}
                    count={this.state.count}
                    next={this.state.next}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />
            </div>
        )
    }
}

export default AutomacoesAfiraLink