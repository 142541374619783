import React from "react"
import helper from "../../../utils/helper"
import cfg from "../../../utils/config"
import auth from "../../../utils/auth"
import axios from "axios"

class Simulacao extends React.Component {
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    userInfo = auth.getUserInfo()
    interval = null

    state = {
        values: {}
    }

    componentDidMount() {
        this.getFields()
        this.interval = setInterval(this.getFields, 30_000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getFields = () => {
        let { condicoes } = this.props.state
        let fields = []

        condicoes?.forEach((cGroup) => {
            cGroup?.forEach((c) => {
                if (c?.condicao?.campo?.identificacao)
                    fields.push({
                        device: c?.condicao?.campo?.id_dispositivo,
                        field: c?.condicao?.campo?.identificacao
                    })
            })
        })

        if (fields.length == 0) return

        const bodyForm = new FormData()
        bodyForm.append("fields", JSON.stringify(fields))

        axios.post(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/reporte-ultimo-evento-campos/', bodyForm, this.configMe)
            .then((res) => {
                this.setState({ values: res.data })
            })
            .catch(console.error)
    }

    getLastValue = (id, cIndex, index, boolean = false) => {
        let { values } = this.state
        let { condicoes } = this.props.state

        try {
            let item = values?.[id]
            let date = ""

            if (item?.timestamp) {
                date = new Date(item?.timestamp * 1000)?.toLocaleDateString("pt-BR", { hour: "numeric", minute: "numeric" })
            }

            if (item) {
                let text = item?.value ?? "0"

                if (boolean) {
                    text = item?.value ? "Ativo" : "Desativado"
                }

                return (
                    <span onClick={() => {
                        if (boolean) {
                            condicoes[cIndex][index].simulacao.value = (item?.value ? "1" : "0")
                        } else {
                            condicoes[cIndex][index].simulacao.value = (item?.value ?? 0)
                        }

                        this.props?.update("condicoes", condicoes)
                    }}>
                        Valor atual: {text} | Atualizado: {date}
                    </span>
                )
            }
        } catch (err) {
            console.error(err)
            return ""
        }
    }

    render() {
        let state = this.props.state
        let { condicoes, acoes, detalhes, periodos, condicoes_automacoes } = this.props.state
        let resumo = { condicoes: [], condicoes_automacoes: [], acoes: [], extras: [], periodos: [] }

        condicoes.forEach((condicoes, cIndex) => {
            let condicaoArray = []
            if (condicoes?.map) {
                condicaoArray = condicoes?.map(c => c.condicao)
            }

            condicaoArray.forEach((condicao, index) => {
                let campo = condicao?.campo?.label ?? "---"
                let operador = condicao?.condicao?.label ?? "---"
                let valor = condicao?.valor ?? "---"

                if (condicao?.campo?.tipo_saida === "booleano") {
                    valor = condicao?.valor === "1" ? "Ativado" : "Desativado"
                }

                resumo.condicoes.push(
                    <p key={`${cIndex}-${index}`}>Quando o campo [<b>{campo}</b>] for <b>{operador}</b> [<b>{valor}</b>]</p>
                )
            })
        })

        condicoes_automacoes?.forEach?.((condicao, index) => {
            let automacao = condicao?.automacao?.label ?? "---"

            if (condicao?.tipo === "0") {
                let estado = condicao?.valor === "1" ? "Ativo" : "Desativado"
                resumo.condicoes_automacoes.push(<p key={index}>Quando o estado da automação [<b>{automacao}</b>] for [<b>{estado}</b>]</p>)
            } else if (condicao?.tipo === "1") {
                let execucoes_limite = condicao?.execucoes_limite ?? "---"
                resumo.condicoes_automacoes.push(<p key={index}>Quando a quantidade de execuções no dia da automação [<b>{automacao}</b>] for maior que [<b>{execucoes_limite}</b>]</p>)
            }
        })

        acoes.forEach((acao, index) => {
            if (acao?.tipo === "alerta") {
                let contatos = acao?.contatos?.length ?? "0"
                resumo.acoes.push(<p key={index}>Enviar notificação para [<b>{contatos}</b>] contatos</p>)
            } else if (acao?.tipo === "comando") {
                let comando = acao?.comando?.label ?? "---"

                resumo.acoes.push(<p key={index}>Enviar comando [<b>{comando}</b>]</p>)
            } else if (acao?.tipo === "http") {
                let metodo = acao?.metodo?.label ?? "---"
                let endereco = acao?.endereco ?? "---"

                resumo.acoes.push(<p key={index}>Enviar dados via HTTP com método [<b>{metodo}</b>] para [<b>{endereco}</b>]</p>)
            }
        })

        if (detalhes.tolerancia !== "00:00") {
            resumo.extras.push(
                <p key={helper.randomKey(8)}>Tolerância de [<b>{detalhes.tolerancia}</b>] minutos antes de acionar a automação</p>
            )
        }

        return (
            <div className="row">
                <div className="col-md-6">
                    <details className="custom-summary" open>
                        <summary>Resumo</summary>
                        <div className="automacao-resumo">
                            {resumo.condicoes?.length > 0
                                ?
                                <fieldset className="hdv-fieldset">
                                    <legend className="hdv-legend">Acionar automação à partir das seguintes condições:</legend>
                                    {resumo.condicoes.map((condicao) => condicao)}
                                </fieldset>
                                :
                                null
                            }

                            {resumo.condicoes_automacoes?.length > 0
                                ?
                                <fieldset className="hdv-fieldset">
                                    <legend className="hdv-legend">Considerar as seguintes automações:</legend>
                                    {resumo.condicoes_automacoes.map((condicao) => condicao)}
                                </fieldset>
                                :
                                null
                            }

                            {resumo.acoes?.length > 0
                                ?
                                <fieldset className="hdv-fieldset">
                                    <legend className="hdv-legend">Ações a serem tomadas após a acionação</legend>
                                    {resumo.acoes.map((acao) => acao)}
                                </fieldset >
                                :
                                null
                            }

                            {periodos?.length > 0 ?
                                <fieldset className="hdv-fieldset">
                                    <legend className="hdv-legend">Intervalos de Execução</legend>
                                    <div className="row">
                                        {periodos?.map((periodo, index) => (
                                            <div className="col-md-6">
                                                <label key={index} className="dia-sem" style={{ fontWeight: "bold" }}>
                                                    {periodo.horainicio} - {periodo.horafim}
                                                </label>
                                            </div>
                                        )
                                        )}
                                    </div>
                                </fieldset>
                                :
                                null
                            }

                            <fieldset className="hdv-fieldset">
                                <legend className="hdv-legend">Extras</legend>
                                {resumo.extras.map((extra) => extra)}

                                <label style={{ opacity: "0.75" }}>Dias da Semana</label>
                                <div className="dias-semana-grid">
                                    {Object.entries(detalhes.dias_da_semana)?.map(([dia, valor], index) => (
                                        <label
                                            className={valor ? "dia-sem active" : "dia-sem"}
                                            style={{ textTransform: "capitalize", cursor: "pointer" }}
                                            key={index}>
                                            {dia}
                                        </label>
                                    ))
                                    }
                                </div>
                            </fieldset>
                        </div>
                    </details>
                </div>
                <div className="col-md-6">
                    <details className="custom-summary" open>
                        <summary>Simulação</summary>
                        <div className="automacao-simulacao row" style={{ minHeight: "180px" }}>
                            {condicoes.length === 0
                                ?
                                <p className="w-100 text-center">Adicione condições para poder simular</p>
                                :
                                condicoes?.map((condicoes, cIndex) => {
                                    let simulacaoArray = []
                                    if (condicoes?.map) {
                                        simulacaoArray = condicoes?.map(c => c.simulacao)
                                    }

                                    return simulacaoArray?.map?.((item, index) => {
                                        const id = state.condicoes[cIndex][index]?.condicao?.campo?.identificacao
                                        let label = item?.campo?.label ?? "---"
                                        let symbol = state.condicoes[cIndex][index]?.condicao?.condicao?.symbol ?? ""

                                        if (item.campo?.tipo_saida === "booleano") {

                                            const valid = (item?.campo?.label) && (symbol)
                                            let valorCondicao = state.condicoes[cIndex][index]?.condicao?.valor

                                            return (
                                                <div className="col-md-12 d-flex justify-content-between" key={index}>
                                                    <div className="simulacao-select">
                                                        <label>
                                                            {`${label} ${symbol} ${valorCondicao !== "0" ? "Ativado" : "Desativado"}`}
                                                        </label>
                                                        <select value={item?.value} onChange={(e) => this.props.handleChange(e.target.value, "value", cIndex, index)}>
                                                            <option value="1">Ativado</option>
                                                            <option value="0">Desativado</option>
                                                        </select>
                                                        {this.getLastValue(id, cIndex, index, true)}
                                                        {!valid ? <span id="validacao-descricao-campo">Campo inválido!</span> : null}
                                                    </div>
                                                    <div className="d-flex flex-column" style={{ textAlign: "center" }}>
                                                        <label>Resultado:</label>
                                                        {item.resultado !== null
                                                            ?
                                                            <i
                                                                style={{ color: item.resultado ? "#39c734" : "#c94d44" }}
                                                                className={item.resultado ? "fa fa-check fa-2x" : "fa fa-times fa-2x"}></i>
                                                            :
                                                            <i className="fa fa-circle fa-2x"></i>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            const valorCondicao = state.condicoes[cIndex][index]?.condicao?.valor
                                            const symbol = state.condicoes[cIndex][index]?.condicao?.condicao?.symbol ?? ""
                                            const valid = (item?.campo?.label) && (symbol) && (valorCondicao)

                                            return (
                                                <div className="col-md-12 d-flex justify-content-between" key={index}>
                                                    <div className="simulacao-input">
                                                        <label>
                                                            {`${label} ${symbol} ${valorCondicao ?? ""}`}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={item?.value}
                                                            onChange={(e) => this.props.handleChange(e.target.value, "value", cIndex, index)}
                                                        />
                                                        {this.getLastValue(id, cIndex, index)}
                                                        {!valid ? <span id="validacao-descricao-campo">Campo inválido!</span> : null}
                                                    </div>
                                                    <div className="d-flex flex-column" style={{ textAlign: "center" }}>
                                                        <label>Resultado:</label>
                                                        {item.resultado !== null
                                                            ?
                                                            <i
                                                                style={{ color: item.resultado ? "#39c734" : "#c94d44" }}
                                                                className={item.resultado ? "fa fa-check fa-2x" : "fa fa-times fa-2x"}></i>
                                                            :
                                                            <i className="fa fa-circle fa-2x"></i>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                })
                            }

                            <div className={condicoes.length > 0 ? "col-md-12 mt-2" : "hdv-noshow-item"}>
                                <button
                                    onClick={this.props.simular}
                                    className="hdv-btn-forms hdv-btn-blue float-right"
                                    style={{ width: "100px", margin: "0" }}>
                                    Simular
                                </button>
                            </div>
                        </div>
                    </details>
                </div>
            </div>
        )
    }
}

export default Simulacao