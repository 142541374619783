import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import Form from './form'
import PrivateRoute from '../PrivateRoute'

class RelatorioAlertas extends Component {
   render() {
      return (
         <div className="hdv-render-div" style={{ marginBottom: '65px' }}>
            <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
               <Switch>
                  <PrivateRoute exact path='/relatorio-alertas' component={Form} />
               </Switch>
            </div>
         </div>
      )
   }
}

export default RelatorioAlertas
