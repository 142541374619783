import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import auth from "../utils/auth"
import rotas from './RotasMenu'

class LinksBase extends Component {
    componentDidMount() {
        let modulos = auth.getUserInfo().menu
        this.checkToShow(modulos)
    }

    checkToShow(modulos) {
        let menu = modulos?.find((user) => user.item === 'base')?.endpoints
        let links = document.querySelectorAll("#item-menu")

        if (auth.getUserInfo()['tipo'] === "admin" || auth.getUserInfo()['tipo'] === "master") {
            return
        }

        links.forEach(a => {
            let href = a.attributes.href?.nodeValue

            if(modulos.length > 0) {
                if (href != "/") {
                    if (menu?.includes(rotas['base'][href]) === false) {
                        a.style.display = "none"
                    }
                }

                if (auth.isClient()) {
                    let itensDeleteCliente = ["/configuracao-sistema", "/equipamento", "/automacao", "/cliente"]

                    itensDeleteCliente?.includes(href) ? a.style.display = "none" : ""
                }
            } else {
                if(href != "/") {
                    a.style.display = "none"
                }
            }
        })

        document.querySelectorAll("#group").forEach((group) => {
            if (group.querySelectorAll("a:not([style*='display: none'])").length === 0) {
                group.querySelector("span").style.display = "none"
            }
        })

        document.querySelectorAll(".hdv-menu-parent").forEach((group) => {
            if (group.querySelectorAll("a:not([style*='display: none'])").length === 0) {
                group.style.display = "none"
            }
        })
    }

    render() {
        const revenda = auth.isPai()
        let mostrarEmpresa = false

        if(revenda) {
            const data = auth.getUserInfo()
            mostrarEmpresa = data.pai === data.empresa
        }

        return (
            <div id="scroll-menu">
                <div className="hdv-menu-grupo">
                    Sistema
                </div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Link className="hdv-submenu" id="item-menu" to="/">
                        <div className={(this.props.pathCurrent === "/") ? "hdv-menu hdv-menu-selected" : "hdv-menu"} >
                            <i className="fa fa-tachometer-alt fa-20px"></i> Dashboard
                        </div>
                    </Link>
                    <Link className={"hdv-submenu"} id="item-menu" to="/lista-monitorados">
                        <div className={this.props.pathIncludes("/lista-monitorados", "/status")}>
                            <i className="fa fa-list fa-20px"></i> Lista de Monitorados
                        </div>
                    </Link>
                    <Link className={"hdv-submenu"} id="item-menu" to="/supervisorio">
                        <div className="hdv-menu">
                            <i className="fa fa-project-diagram fa-20px"></i> Supervisório
                        </div>
                    </Link>
                </div>

                <br />

                <div id='group'>
                    <div className={"hdv-menu-grupo"}>
                        <span>Cadastros</span>
                    </div>
                    <div className={"hdv-menu-parent"}>
                        <div id='cliente' className={this.props.pathIncludes(["/cliente", "/perfil/cliente"])} onClick={(e) => this.props.openSubMenu(e)}>
                            <i className="fa fa-users fa-20px"></i> Clientes
                            <i className="fa fa-caret-down hdv-caret-menu"></i>
                            <div className="hdv-menu-item-grupo" id='group-cliente'>
                                <Link className={"hdv-submenu"} id="item-menu" to="/acesso-modulo">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Acesso ao Módulo
                                    </div>
                                </Link>
                                <Link className={"hdv-submenu"} id="item-menu" to="/cliente">
                                    <div className="hdv-menu-item" >
                                        <i className="fa fa-angle-double-right fa-12px"></i> Lista de Clientes
                                    </div>
                                </Link>
                                <Link className={"hdv-submenu"} id="item-menu" to="/perfil/cliente">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Perfis
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={"hdv-menu-parent"}>
                        <div id='usuario' className={this.props.pathIncludes(["/usuario"])} onClick={(e) => this.props.openSubMenu(e)}>
                            <i className="fa fa-user fa-20px"></i> Usuários
                            <i className="fa fa-caret-down hdv-caret-menu"></i>
                            <div className="hdv-menu-item-grupo" id='group-usuario'>
                                <Link className={"hdv-submenu"} id="item-menu" to="/usuario">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Lista de Usuários
                                    </div>
                                </Link>
                                <Link className={"hdv-submenu"} id="item-menu" to="/perfil/usuario">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Perfis
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={"hdv-menu-parent"}>
                        <div id='equipamento' className={this.props.pathIncludes(["/equipamento", "/monitorado", "/automacao", "/virtual", "/acessos"])} onClick={(e) => this.props.openSubMenu(e)}>
                            <i className="fa fa-hdd fa-20px"></i> Equipamentos
                            <i className="fa fa-caret-down hdv-caret-menu"></i>
                            <div className="hdv-menu-item-grupo" id='group-equipamento'>
                                <Link className={"hdv-submenu"} id="item-menu" to="/acessos">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Acessos
                                    </div>
                                </Link>
                                <Link className={"hdv-submenu"} id="item-menu" to="/equipamento">
                                    <div className="hdv-menu-item" >
                                        <i className="fa fa-angle-double-right fa-12px"></i> Lista de Equipamentos
                                    </div>
                                </Link>
                                <Link className={"hdv-submenu"} id="item-menu" to="/monitorado">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Monitorados
                                    </div>
                                </Link>
                                <Link className={"hdv-submenu"} id="item-menu" to="/virtual">
                                    <div className="hdv-menu-item" >
                                        <i className="fa fa-angle-double-right fa-12px"></i> Reporte Manual
                                    </div>
                                </Link>
                                <Link className={"hdv-submenu"} id="item-menu" to="/automacao">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Automações
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Link style={{display: mostrarEmpresa ? "block" : "none"}} className="hdv-submenu" id="item-menu" to="/empresa">
                        <div className={this.props.pathIncludes("/empresa")} >
                            <i className="fa fa-building fa-20px"></i> Empresas
                        </div>
                    </Link>
                    <div className={"hdv-menu-parent"}>
                        <div id='equipamento' className={this.props.pathIncludes(["/afiralink"])} onClick={(e) => this.props.openSubMenu(e)}>
                            <i className="fa fa-hdd fa-20px"></i> Afiralink
                            <i className="fa fa-caret-down hdv-caret-menu"></i>
                            <div className="hdv-menu-item-grupo" id='group-equipamento'>
                                <Link className={"hdv-submenu"} id="item-menu" to="/afiralink">
                                    <div className="hdv-menu-item">
                                        <i className="fa fa-angle-double-right fa-12px"></i> Equipamentos
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div id='group' style={{ display: 'flex', flexDirection: "column" }}>
                    <div className={"hdv-menu-grupo"}>
                        <span>Relatórios</span>
                    </div>
                    <Link className="hdv-submenu" id="item-menu" to="/relatorio/logs">
                        <div className={this.props.pathIncludes("/logs")}>
                            <i className="fa fa-clipboard fa-20px"></i> Logs
                        </div>
                    </Link>
                    <Link className="hdv-submenu" id="item-menu" to="/relatorio-customizado">
                        <div className={this.props.pathIncludes("/relatorio-customizado")}>
                            <i className="fa fa-chart-bar fa-20px"></i> Relatório Customizado
                        </div>
                    </Link>
                    <Link className="hdv-submenu" id="item-menu" to="/relatorio-consumo">
                        <div className={this.props.pathIncludes("/relatorio-consumo")}>
                            <i className="fa fa-chart-area fa-20px"></i> Relatório de Consumo
                        </div>
                    </Link>
                    <Link className="hdv-submenu" id="item-menu" to="/relatorio-alertas">
                        <div className={this.props.pathIncludes("/relatorio-alertas")}>
                            <i className="fa fa-history fa-20px"></i> Relatório de Alertas
                        </div>
                    </Link>
                    <Link className="hdv-submenu" id="item-menu" to="/exportacao-dados">
                        <div className={this.props.pathIncludes("/exportacao-dados")}>
                            <i className="fa fa-file-export fa-20px"></i> Exportação de Dados
                        </div>
                    </Link>
                </div>

                <br />

                <div id='group' style={{ display: 'flex', flexDirection: "column" }}>
                    <div className={"hdv-menu-grupo"}>
                        <span>Configurações</span>
                    </div>
                    <Link className={"hdv-submenu"} id="item-menu" to="/minha-conta">
                        <div className={this.props.pathIncludes("/minha-conta")}>
                            <i className="fa fa-user fa-20px"></i>  Minha conta
                        </div>
                    </Link>
                    <Link className={"hdv-submenu"} id="item-menu" to="/configuracao-sistema">
                        <div className={this.props.pathIncludes("/configuracao-sistema")}>
                            <i className="fa fa-desktop fa-20px"></i>  Sistema
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}

export default LinksBase