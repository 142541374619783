import React, { Component } from 'react'
import LinksBase from './LinksBase'
import LinksAmbiental from './LinksAmbiental'

class MenuSystem extends Component {

    openSubMenu(e) {
        let submenu = e.target.querySelector('.hdv-menu-item-grupo')
        if (!submenu) {
            return
        }
        submenu.classList.toggle("hdv-menu-item-grupo-open")
    }

    pathIncludes(paths) {
        let items = []
        let results = []
        items = Array.isArray(paths) ? paths : new Array(paths)
        items?.forEach((path) => results.push(this.props.pathCurrent.includes(path)))

        return results.some((i) => i) ? "hdv-menu hdv-menu-selected" : "hdv-menu"
    }

    render() {
        let modulo = this.props.modulo

        if (modulo['base']) {
            return (
                <LinksBase
                    pathCurrent={this.props.pathCurrent}
                    openSubMenu={(e) => this.openSubMenu(e)}
                    pathIncludes={(paths) => this.pathIncludes(paths)}
                />
            )
        } else if (modulo['ambiental']) {
            return (
                <LinksAmbiental
                    pathCurrent={this.props.pathCurrent}
                    openSubMenu={(e) => this.openSubMenu(e)}
                    pathIncludes={(paths) => this.pathIncludes(paths)}
                />
            )
        }
    }
}

export default MenuSystem