import { Component } from "react"
import { Button, Modal, ModalBody, ModalHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Redirect, Link } from "react-router-dom"
import helper from "../utils/helper"
import cfg from "../utils/config"
import auth from "../utils/auth"
import axios from "axios"

class CardComando extends Component {
    userInfo = auth.getUserInfo()
    count = null
    sending = false

    state = {
        randId: helper.randomKey(12),
        error: { status: false, message: "" },
        redirect: { to: null },
        modalDelete: false,
        modalEnviado: false,
        loadingValue: 0
    }

    componentDidMount() {
        if (this.props?.comando?.values) {
            this.setValues(this.props?.comando?.values)
        }
    }

    setValues = (values) => {
        let form = document.querySelector(`form[data-form_id="${this.state.randId}"]`)

        Array.from(form.elements).forEach(input => {
            if (input.name in values) {
                input.value = values[input.name]
            }
        })
    }

    renderContent = () => {
        let parametros = []
        let comandoParametros = this.props.comando?.parametros

        if (typeof comandoParametros === "string") {
            try {
                comandoParametros = JSON.parse(comandoParametros)
            } catch (err) {
                console.error(err)
            }
        }

        comandoParametros?.forEach((parametro, index) => {
            if (parametro.tipo === "select") {
                parametros.push(
                    <label key={index}>{parametro?.nome_parametro}
                        <select name={parametro.id} onChange={(e) => this.handleChange(e)}>
                            <option value=""></option>
                            {parametro.selectValues?.map((param, pIndex) => (
                                <option key={pIndex} value={param.valor}>
                                    {`${param.descricao} (${param.valor})`}
                                </option>
                            )
                            )}
                        </select>
                    </label>
                )
            } else {
                parametros.push(
                    <label key={index}>
                        {parametro?.nome_parametro}
                        <input type="text" name={parametro?.id} key={index} onChange={(e) => this.handleChange(e)} />
                    </label>
                )
            }
        })

        return parametros
    }

    send = () => {
        this.setState({ error: { status: false, message: "" } })
        let form = document.querySelector(`form[data-form_id="${this.state.randId}"]`)
        let data = new FormData()
        let params = []

        Array.from(form.elements).forEach(input => {
            let value = input.value

            if (!isNaN(value) && String(value).length > 0) {
                value = Number(value)
            }

            if (input.tagName.toLowerCase() === "select") {
                params.push({ "key": input.name, "value": value })
            } else {
                params.push({ "key": input.name, "value": value })
            }
        })

        if (params.some(param => param.value?.length === 0)) {
            this.setState({ error: { status: true, message: "Preencha todos os campos para enviar o comando!" } })
            return
        }

        data.append("id_comando", this.props.comando.id)
        data.append("params", JSON.stringify(params))

        if(this.props.dataSharedSupervisory) {
            axios({
                url: cfg.base_api_url + cfg.api_v2 + "/" + this.props.comando.id + '/comando-envio-supervisorio-link/' + this.props.dataSharedSupervisory.hash + "/",
                method: "POST",
                data: data,
            })
            .then((res) => this.setState({ modalEnviado: true }))
            .catch(console.error)

        } else {
            axios({
                url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + `/comando-envio/`,
                method: "POST",
                data: data,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
            .then((res) => this.setState({ modalEnviado: true }))
            .catch(console.error)
        }
    }

    mouseDown = (e) => {
        let { loadingValue } = this.state
        
        this.sending = false

        this.count = setInterval(() => {
            loadingValue += 10

            if (loadingValue >= 130 && !this.sending) {
                this.send()
                this.sending = true
                clearInterval(this.count)
                this.setState({ loadingValue: 0 })
            }

            this.setState({ loadingValue })
        }, 90)
    }

    mouseUp = (e) => {
        let { loadingValue } = this.state

        if (loadingValue >= 130 && !this.sending) {
            this.send()
            this.sending = true
        }

        clearInterval(this.count)
        this.setState({ loadingValue: 0 })
    }

    mouseOut = () => {
        clearInterval(this.count)
        this.setState({ loadingValue: 0 })
    }

    delete = () => {
        this.setState({ modalDelete: false })

        axios({
            url: cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/comando-customizado/' + this.props.comando.id,
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => this.props?.getComandos())
            .catch(console.error)
    }

    handleChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e.target.name, e.target.value)
        }
    }

    render() {
        const { comando } = this.props
        const { error, loadingValue } = this.state

        if (this.state.redirect.to) {
            return <Redirect to={this.state.redirect.to} />
        }

        return (
            <>
                <Modal isOpen={this.state.modalEnviado} toggle={() => this.setState({ modalEnviado: false })}>
                    <ModalHeader>Sucesso</ModalHeader>
                    <ModalBody>
                        <p style={{ fontSize: '14px' }}>Comando enviado com sucesso!</p>
                        <div className="float-right" style={{ marginTop: '20px' }}>
                            <Button color="primary" onClick={() => this.setState({ modalEnviado: false })}>
                                Ok
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: !this.state.modalDelete })}>
                    <ModalHeader>Confirmar exclusão</ModalHeader>
                    <ModalBody>
                        <p style={{ fontSize: '14px' }}>Você tem certeza que deseja deletar? Essa ação não poderá ser desfeita</p>
                        <div className="float-right" style={{ marginTop: '20px' }}>
                            <Button
                                color="default"
                                onClick={() => this.setState({ modalDelete: false })}>
                                Cancelar
                            </Button>
                            {' '}
                            <Button color="danger" onClick={this.delete}>
                                Confirmar
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={error.status} toggle={() => this.setState({ error: { status: false, message: "" } })}>
                    <ModalHeader>Erro</ModalHeader>
                    <ModalBody>
                        <p style={{ fontSize: '14px' }}>{error.message}</p>
                        <div className="float-right" style={{ marginTop: '20px' }}>
                            <Button
                                color="primary"
                                onClick={() => this.setState({ error: { status: false, message: "" } })}>
                                Ok
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                <div
                    className="comandos-btn-editar comandos-flex"
                    style={{ minWidth: this.props?.width ? this.props?.width : "200px" }}>
                    <form className="comandos-parametros" onSubmit={(e) => e.preventDefault()} data-form_id={this.state.randId}>
                        <strong>{comando.descricao}</strong>
                        {this.renderContent()}
                    </form>

                    {this.props.compact
                        ?
                        <button
                            onMouseDown={this.mouseDown}
                            onMouseUp={this.mouseUp}
                            onMouseOut={this.mouseOut}
                            className="comandos-parametros-send">
                            <span className="loading-state" style={{ width: `${loadingValue}%` }}></span>
                            Segure para Enviar
                        </button>
                        :
                        <UncontrolledDropdown direction="down">
                            <DropdownToggle nav>
                                <section className="btns-comandos-novo">
                                    <span>Ações</span>
                                </section>
                            </DropdownToggle>
                            <DropdownMenu className="mini-modal">
                                <DropdownItem onClick={this.send}>
                                    <i className="fa fa-paper-plane"></i> Enviar
                                </DropdownItem>
                                <Link to={`/comando-customizado/${this.props.comando.id}/editar/`}>
                                    <DropdownItem>
                                        <i className="fa fa-pen"></i> Editar
                                    </DropdownItem>
                                </Link>
                                <DropdownItem onClick={() => this.setState({ modalDelete: true })}>
                                    <i className="fa fa-times"></i> Excluir
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    }
                </div>
            </>
        )
    }
}

export default CardComando