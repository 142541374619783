import React, { Component } from 'react'
import helper from '../../../utils/helper'

class VolumeDia extends Component {
    state = {
        params: {
            descricao: "",
            comunicacao: false,
            value: 150,
            metric: "m",
            params: false,
            update: false,
            type_el: false,
        }
    }

    componentWillMount = () => {
        if (this.props.dataWidget)
            this.setState({ params: this.props.dataWidget })
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.dataWidget.value !== nextProps.dataWidget.value)
            this.setState({ params: nextProps.dataWidget })
    }

    render() {
        let value_show = parseFloat(this.state.params.value).toFixed(2)
        if (this.state.params.type_el === 2) {
            value_show = String(helper.converte_decimal_to_hhmmss(this.state.params.value))
        }

        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento()}
                <div className="box-simple-value">
                    <div className="hdv-metric-text">{this.state.params.metric}</div>
                    <div className="bar-value">
                        <div className="layout-inlineflex"><strong className={"value-super"}>{value_show}</strong></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VolumeDia