import React, { Component } from 'react'
import PrivateRoute from '../PrivateRoute'
import { Switch } from 'react-router-dom'
import Form from './form'
import Grid from './grid'
import Relatorio from './relatorio'

class Mira extends Component {
    render() {
        return(
            <div className='hdv-render-div'>
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <Switch>
                        <PrivateRoute exact path='/mira' component={Grid}/>
                        <PrivateRoute path='/mira/novo' component={Form}/>
                        <PrivateRoute path='/mira/:id/editar' component={Form}/>
                        <PrivateRoute path='/mira/:id/relatorio' component={Relatorio} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default Mira

 