import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-tabs/style/react-tabs.css"
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import auth from './utils/auth'

axios.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === 401) {
        let retries = 0
        const retryInterval = setInterval(() => {
            auth.refreshToken(error).then(() => {
                if (retries < 2) {
                    retries += 1
                } else {
                    clearInterval(retryInterval)
                    auth.clearAppStorage(true)
                }
            })
        }, 2000)
    }

    return Promise.reject(error)
})

createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
