import { Component, useState, useEffect } from 'react'
import { Redirect } from "react-router-dom"
import { WidthProvider, Responsive } from "react-grid-layout"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { SupervisorioLinkForm } from './Elementos/Link'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import PlantaBaixa from './PlantaBaixa'
import { Link } from 'react-router-dom'
import Select from '../../components/Select'
import { v4 as uuidv4 } from 'uuid'

import Reservatorio from './Elementos/Reservatorio'
import GraficoBarra from './Elementos/GraficoBarra'
import GraficoLinha from './Elementos/GraficoLinha'
import MaximoAtual from './Elementos/MaximoAtual'
import arrowLeft from '../../assets/svg/arrow_left.svg'
import Imagem from './Elementos/Imagem'
import Poco from './Elementos/Poco'
import ValorAtual from './Elementos/ValorAtual'
import ValorAtualV2 from './Elementos/ValorAtualV2'
import Status from './Elementos/Status'
import VolumeDia from './Elementos/VolumeDia'
import Comando from './Elementos/Comando'
import helper from '../../utils/helper'

import { io } from "socket.io-client"
import auth from '../../utils/auth'
import moment from 'moment'
import axios from 'axios'
import cfg from '../../utils/config'
import './style.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

class Supervisorio extends Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        socket: null,
        coresConfig: {
            "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b",
            "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7",
            "fonte_grupo_menu_lateral": "#c7c7c7"
        },
        modal: { supervisorio: false, delete: false, apresentacao: false, edit: false, widget: false, compartilhar: false, erro: false },
        opacity: 0,
        logomarca: '',
        layouts: JSON.parse(localStorage.getItem('layouts')),
        gaugeRef: { h: 150 },
        apresentacao: { index: 0, function: null, intervalo: { label: '30 Segundos', value: 30_000 } },
        novoSupervisorio: { descricao: false, tipo: "empresa", cliente_id: false },
        supervisorios: [],
        supervisorioSelecionado: false,
        editEnabled: false,
        clientes: [],
        novoWidget: { id_widget: false, descricao_widget: false, equipamento: false, campo: false, calcularVolume: false, parametros: false },
        widgets: [],
        monitorados: [],
        equipamentosVinculados: [],
        equipamentosData: {},
        camposDisponiveis: [],
        comandos: [],
        comandosDisponiveis: [],
        htmlCampos: [],
        redirect: { to: null },
        needUpdate: false,
        blockScreen: false,
        socketReconnectInterval: null,
        showIcons: false,
        widgetEdit: { input: "", widget: null, element: null },
        compartilhar: { descricao: "", expiraEm: "", link: "", erros: [] },
        tab: 0,
        widgetsDisponiveis: [
            {
                id: 1, descricao: "Reservatório (%)", parametros: [
                    { field: "valor_maximo", label: "Valor Máximo", type: "float", optional: false },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            }, {
                id: 2, descricao: "Valor Atual", parametros: [
                    { field: "valor_normal", label: "Valor Normal", type: "float", optional: true },
                    { field: "valor_alerta", label: "Valor Alerta", type: "float", optional: true },
                    { field: "valor_perigo", label: "Valor Perigoso", type: "float", optional: true },
                    { field: "icone", label: "Icone", type: "text", optional: true, fn: () => this.showSelectedIcons() },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            },
            /*{id: 3, descricao: "Imagem", parametros: [
                {field: "image", label: "Selecionar Imagem", type: "file", optional: false}
            ]},*/
            {
                id: 4, descricao: "Valor Máximo x Atual", parametros: [
                    { field: "valor_maximo", label: "Valor Máximo", type: "float", optional: false },
                    { field: "valor_alerta", label: "Valor Alerta", type: "float", optional: false },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                    /*{field: "referencias", label: "Referências", type: "array", array_fields: [{field: "descricao", label: "Descrição", type: "text"}, {field: "valor", label: "Valor", type: "float"}], optional: false}*/
                ]
            }, {
                id: 5, descricao: "Poço", parametros: [
                    { field: "instalacao_bomba", label: "Instalação da Bomba (metros)", type: "float", optional: false },
                    { field: "instalacao_sensor", label: "Instalação do Sensor (metros)", type: "float", optional: false },
                    { field: "nivel_estatico", label: "Nível Estático (metros)", type: "float", optional: false },
                    { field: "campo_vazao", label: "Vazão", type: "custom_field", optional: false },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            }, {
                id: 6, descricao: "Gráfico de Linhas",
                parametros: [
                    { field: "valor_minimo", label: "Valor Minimo", type: "float", optional: true },
                    { field: "valor_maximo", label: "Valor Máximo", type: "float", optional: true },
                    { field: "valores_anteriores", label: "Mostrar valores anteriores", type: "checkbox", optional: true },
                    { field: "periodo", label: "Período de exibição", type: "select_field", optional: true },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            }, {
                id: 7, descricao: "Gráfico de Barras",
                parametros: [
                    { field: "periodo", label: "Período de exibição", type: "select_field", optional: true },
                    { field: "valores_anteriores", label: "Mostrar valores anteriores", type: "checkbox", optional: true },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            }, {
                id: 8, descricao: "Status", parametros: [
                    { field: "on", label: "Descrição Ligado", type: "text", optional: false },
                    { field: "off", label: "Descrição Desligado", type: "text", optional: false },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            }, {
                id: 9, descricao: "Volume Diário", parametros: [
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            }, {
                id: 10, descricao: "Valor Atual v2", parametros: [
                    { field: "valor_normal", label: "Valor Normal", type: "float_between", optional: true },
                    { field: "valor_alerta", label: "Valor Alerta", type: "float_between", optional: true },
                    { field: "valor_perigo", label: "Valor Perigoso", type: "float_between", optional: true },
                    { field: "icone", label: "Icone", type: "text", optional: true, fn: () => this.showSelectedIcons() },
                    { field: "minutos_alerta_semcomunicacao", label: "Alerta de Comunicação Atrasada (em minutos)", type: "float", optional: false }
                ]
            }, {
                id: 11, descricao: "Comando", parametros: [
                    { field: "comandos", label: "Selecione o comando", type: "select_field", optional: false }
                ]
            }
        ],
    }

    sortSupervisories() {
        let supervisorios = []

        if (this.state.supervisorios?.length > 0) {
            this.state.supervisorios?.sort((a, b) => {
                if (a.descricao < b.descricao) return -1
                if (a.descricao > b.descricao) return 1
                return 0
            })?.forEach(supervisorio => {
                supervisorios.push({ label: supervisorio.descricao, value: supervisorio.id, supervisorio })
            })
        }

        return supervisorios
    }

    toggleApresentacao = () => {
        let { apresentacao, supervisorios } = this.state

        if (apresentacao.function === null) {
            apresentacao.function = setInterval(() => {
                apresentacao.index += 1

                if (apresentacao.index === supervisorios.length)
                    apresentacao.index = 0

                this.selectSupervisory(supervisorios[apresentacao.index].id)
            }, apresentacao.intervalo.value)

            this.setState({ apresentacao })
            setTimeout(() => this.handleModal("apresentacao"), 5_000)
        } else {
            window.clearInterval(this.state.apresentacao.function)
            apresentacao.function = null
            this.setState({ apresentacao })
        }
    }

    handleModal = (key, autoClose = false) => {
        let modal = this.state.modal
        modal[key] = !modal[key]
        this.setState({ modal })

        if (autoClose) {
            setTimeout(() => {
                modal[key] = false
                this.setState({ modal })
            }, 4500)
        }
    }

    showSelectedIcons = () => {
        this.setState({ blockScreen: true, showIcons: true })
    }

    componentWillMount = () => {
        if (this.props.shared) {
            let { supervisorio } = this.props.link

            let coresConfig = { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
            let logomarca = ''
            let supervisorios = [supervisorio]
            let clientes = []

            this.setState({ opacity: 1, coresConfig, logomarca, clientes, supervisorios })
            setTimeout(() => this.selectSupervisory(supervisorio.id), 200)
        } else {
            let requestArray = []
            let userInfo = auth.getUserInfo()
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

            if (!userInfo?.empresa) return

            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/configuracao-cores/', config_me))
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/?limit=1000', config_me))
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/', config_me))

            axios.all(requestArray).then(axios.spread((coresConfig, config, supervisorios, clientes) => {
                let cores = { "degrade_top_primeira_cor": "#F44E3B", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
                let logomarca = ''
                let supervisoriosLista = []
                let clientesLista = []

                if (coresConfig.status === 200) {
                    cores = coresConfig.data?.results?.[0]

                    let cssVars = document.querySelector(':root')
                    cssVars.style.setProperty('--degrade_top_primeira_cor', cores?.degrade_top_primeira_cor)
                    cssVars.style.setProperty('--fonte_menu_lateral', cores?.fonte_menu_lateral)
                    cssVars.style.setProperty('--background-menu', cores?.back_menu_lateral)
                    cssVars.style.setProperty('--fonte_grupo_menu_lateral', cores?.fonte_grupo_menu_lateral)
                    cssVars.style.setProperty('--fonte_titulo_abas', cores?.degrade_top_primeira_cor)
                }

                if (config.status === 200) {
                    logomarca = config.data?.results?.[0]?.logomarca
                }

                if (supervisorios.status === 200) {
                    supervisoriosLista = supervisorios.data?.results
                }

                if (clientes.status === 200) {
                    clientesLista = clientes.data.results
                }

                this.setState({ coresConfig: cores, opacity: 1, logomarca, supervisorios: supervisoriosLista, clientes: clientesLista })
            }))
        }
    }

    startSocket = (data) => {
        let options = {
            reconnectionDelayMax: 10000,
            auth: {
                apiVersion: cfg.api_v2,
                token: this.props.hash ? this.props.hash : auth.getToken(),
                type: this.props.hash ? "hash" : "token"
            }
        }

        const socket = this.state.socket ?? io("https://skt.afira.io/", options)

        socket.on("connect", () => {
            console.info("[connected]")
            socket.emit("joinRoom", data)
        })

        socket.on("update", (_data) => {
            let time = (new Date()).toLocaleTimeString().slice(0, 5)
            console.info(`[${time}][updating]`, _data)

            let { equipamentosData, supervisorioSelecionado } = this.state
            let mqttToImei = {}

            try {
                JSON.parse(supervisorioSelecionado.widgets)?.map((widget) => {
                    mqttToImei[widget.mqtt] = widget.equipamento
                })

                const id = mqttToImei[_data?.reporte?.id_dispositivo]
                this.setState({ updating: true })

                if (equipamentosData?.[id]) {
                    for (let key of Object.keys(_data?.reporte)) {
                        equipamentosData[id][key] = _data?.reporte[key]
                    }

                    this.setState({ equipamentosData })
                }

                setTimeout(() => this.setState({ updating: false }), 5_000)
            } catch (err) {
                console.error(err)
            }
        })

        socket.on("disconnect", () => this.socketReconnection(data))

        document.addEventListener("beforeunload", (e) => socket.disconnect())
        document.addEventListener("__force_disconnect", (e) => {
            console.info("[disconnected]")
            socket.disconnect()
        })

        this.setState({ socket: socket })
    }

    socketReconnection = (data) => {
        const socketReconnectInterval = setInterval(() => {
            if (this.state.socket && this.state.socket?.connected === false) {
                this.startSocket(data)
            } else {
                clearInterval(this.state.socketReconnectInterval)
                clearInterval(socketReconnectInterval)
            }
        }, 20_000)

        this.setState({ socketReconnectInterval })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.apresentacao.function)
        clearInterval(this.state.socketReconnectInterval)
        this.state.socket?.disconnect()
    }

    newSupervisory = (e) => {
        let newObj = this.state.novoSupervisorio
        if (e.target.value === "") {
            newObj[e.target.id] = false
        } else {
            newObj[e.target.id] = e.target.value
        }
        this.setState({ novoSupervisorio: newObj })
    }

    saveSupervisory = () => {
        let { novoSupervisorio } = this.state
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()
        const configMe = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() } }

        if (!Boolean(novoSupervisorio.descricao)) return

        if (userInfo?.tipo == "cliente") {
            bodyFormData.append("cliente_id", userInfo.cliente)
            bodyFormData.append("descricao", novoSupervisorio?.descricao)
            bodyFormData.append("tipo", "cliente")
        } else {
            if (novoSupervisorio?.tipo == "cliente") {
                if (novoSupervisorio?.cliente_id) {
                    bodyFormData.append("cliente_id", novoSupervisorio?.cliente_id)
                }
                else return
            }

            bodyFormData.append("descricao", novoSupervisorio?.descricao)
            bodyFormData.append("tipo", novoSupervisorio?.tipo)
        }

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/?limit=1000'

            helper.dispatchEvent("showAviso", {
                message: "Supervisório criado com sucesso!",
                callback: null
            })

            axios.get(url, configMe).then((res) => {
                this.setState({ supervisorios: res.data?.results, supervisorioSelecionado: false, widgets: [] })
            })
        }).catch(console.error)
    }

    getMonitorados = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/monitorado?limit=100/'

        axios.get(requestURL, config_me).then((res) => {
            let monitorados = res.data?.results?.length > 0 ? res.data?.results : []
            try {
                monitorados = monitorados?.sort((a, b) => {
                    let textA = a?.nome.toUpperCase()
                    let textB = b?.nome.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
            } catch (err) {
                console.error(err)
            }

            this.setState({ monitorados: monitorados })
        })
    }

    selectSupervisory = async (e) => {
        const supId = isNaN(e) ? Number(e.target.value) : Number(e)

        if (supId === this.state.supervisorioSelecionado.id) return

        this.setState({ supervisorioSelecionado: false, tab: 0 })

        if (this.state.socket) {
            document.dispatchEvent(new CustomEvent('__force_disconnect'))
            this.setState({ socket: null })
            await new Promise((r, _) => setTimeout(() => r(), 100))
        }

        let supervisory = false
        let userInfo = auth.getUserInfo()
        let configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        clearInterval(this.state.socketReconnectInterval)

        if (supId) {
            supervisory = this.state.supervisorios.find((sup) => sup.id === supId)
        }

        if (!supervisory) {
            this.setState({ supervisorioSelecionado: false, widgets: [] })
            return
        }

        this.setState({ blockScreen: true })

        let widgets = (supervisory.widgets) ? JSON.parse(supervisory.widgets) : []
        let user = { id: userInfo?.id, type: userInfo?.tipo, name: userInfo?.nome }
        let vinculados = []
        let mqttKeys = []

        widgets?.forEach((widget) => {
            if (widget?.equipamento)
                vinculados.push(widget.equipamento)
            if (widget?.mqtt)
                mqttKeys.push(widget.mqtt)
        })

        vinculados = Array.from(new Set(vinculados))
        mqttKeys = Array.from(new Set(mqttKeys))

        if (this.props.shared) {
            this.setState({
                blockScreen: false,
                needUpdate: false,
                supervisorioSelecionado: supervisory,
                equipamentosData: supervisory.reportes,
                widgets: JSON.parse(supervisory.widgets),
                layouts: (supervisory.layout) ? JSON.parse(supervisory.layout) : []
            })

            this.startSocket({
                supervisory,
                devices: mqttKeys,
                user,
            })
        } else {
            this.getComandos()
            this.startSocket({ supervisory, devices: mqttKeys, user })

            let bodyFormData = new FormData()
            bodyFormData.append("equipamentos", JSON.stringify(vinculados))

            axios.post(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/reporte/supervisorio/', bodyFormData, configMe)
                .then((res) => {
                    let data = res.data
                    let { apresentacao } = this.state
                    const layouts = (supervisory.layout) ? JSON.parse(supervisory.layout) : []
                    apresentacao.index = this.state.supervisorios.indexOf(supervisory)

                    for (let key of Object.keys(data)) {
                        data[key].unidades = {}

                        if ("_parametros" in data[key]) {
                            data[key]._parametros?.forEach((param) => {
                                if ("unidade" in param) {
                                    data[key].unidades[param.identificacao] = param.unidade
                                }
                            })
                        }
                    }

                    this.setState({
                        blockScreen: false, equipamentosData: data, equipamentosVinculados: vinculados, needUpdate: false,
                        supervisorioSelecionado: supervisory, apresentacao, widgets, layouts
                    })
                })
                .catch((err) => {
                    console.error(err)
                    const layouts = (supervisory.layout) ? JSON.parse(supervisory.layout) : []
                    this.setState({ blockScreen: false, widgets: [], supervisorioSelecionado: false, layouts })
                    this.handleModal("erro", true)
                })
        }
    }

    getComandos = () => {
        let requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/comando-customizado/'

        axios.get(requestURL, this.configMe).then((res) => {
            let comandos = res.data?.results ?? []
            this.setState({ comandos })
        })
    }

    filterComandos = (equipamento = null, campo = null) => {
        let comandos = this.state.comandos

        if (equipamento) {
            comandos = comandos.filter((cmd) => cmd?.equipamento__imei == equipamento)
        } else {
            comandos = []
        }

        this.setState({ comandosDisponiveis: comandos })
        return comandos
    }

    updateSupervisory = () => {
        this.setState({ needUpdate: true })
    }

    actionUpdateSupervisory = () => {
        let supervisorioSelecionado = this.state.supervisorioSelecionado
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        for (let key in supervisorioSelecionado) {
            if (supervisorioSelecionado[key] !== 'cliente__nome_fantasia' && supervisorioSelecionado[key] !== 'id')
                bodyFormData.append(key, supervisorioSelecionado[key])
        }

        if (Boolean(supervisorioSelecionado.cliente)) {
            bodyFormData.set('cliente', String(supervisorioSelecionado.cliente))
        } else {
            bodyFormData.delete('cliente')
        }

        axios({
            method: 'PATCH',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/' + supervisorioSelecionado.id + '/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(() => this.setState({ needUpdate: false }))
            .catch(console.error)
    }

    deleteSupervisory = () => {
        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()
        const configMe = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() } }

        axios({
            method: 'DELETE',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/' + this.state.supervisorioSelecionado.id + '/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => {
            axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/supervisorio/', configMe).then((res) => {

                this.setState({ supervisorios: res.data?.results, supervisorioSelecionado: false, widgets: [] })
            })
        })
            .catch((error) => console.error(error))
    }

    newWidget = (e) => {
        let newObj = this.state.novoWidget
        let { camposDisponiveis } = this.state

        if (e.target.value === "") {
            newObj[e.target.id] = false
        } else {
            let value = e.target.value
            if (e.target.id === "campo") {
                let campo = camposDisponiveis.find(campo => campo.value == e.target.value)

                if (campo) {
                    newObj.equipamento = campo?.equipamento?.imei
                    newObj.equipamento_mqtt = campo?.equipamento?.usuario_mqtt
                    value = campo.id
                }
            }

            newObj[e.target.id] = value
        }

        this.setState({ novoWidget: newObj })

        if (e.target.id == "campo") {
            if (newObj?.equipamento) {
                this.showWidgetParams({ target: { value: "11" } })
            }
        }

        setTimeout(() => this.setState({ blockScreen: false }), 2000)
    }

    deleteWidget = (id) => {
        let widgets = this.state.widgets
        let supervisorioSelecionado = this.state.supervisorioSelecionado
        let widgets_novo = widgets.filter((widget) => widget.id !== id)
        supervisorioSelecionado['widgets'] = JSON.stringify(widgets_novo)
        this.setState({ widgets: widgets_novo, supervisorioSelecionado: supervisorioSelecionado })
        this.handleModal("edit")
    }

    saveWidget = () => {
        let widgets = this.state.widgets
        let supervisorioSelecionado = this.state.supervisorioSelecionado
        const widgetSelecionado = this.state.widgetEdit
        let widgetIndex = widgets.findIndex((w) => w.id === widgetSelecionado.widget.id)
        widgets[widgetIndex].descricao = this.state.widgetEdit.input.trim()
        widgetSelecionado.element.innerHTML = this.state.widgetEdit.input.trim()
        supervisorioSelecionado['widgets'] = JSON.stringify(widgets)

        this.actionUpdateSupervisory()
        this.handleModal("edit")
    }

    getMonitoradoFields = async (e) => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        if (!e.target.value) return

        document.querySelector(".hdv-barsuper-select#campo").value = ""

        let requestArray = [
            axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/monitorado-parametro/?monitorado=${e.target.value}&limit=100`, config_me),
            axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/monitorado-comando/?monitorado=${e.target.value}&limit=100`, config_me)
        ]

        let comandosDisponiveis = []
        let camposDisponiveis = []

        await axios.all(requestArray).then(axios.spread((params, comandos) => {
            params?.data?.results?.forEach((field) => {
                let campo = field?.campo

                camposDisponiveis.push({
                    id: campo?.identificacao,
                    label: `${campo.descricao} (${campo.identificacao})`,
                    value: campo.id,
                    dataType: "text",
                    equipamento: campo?.equipamento,
                })
            })

            comandos?.data?.results?.forEach((item) => {
                comandosDisponiveis.push(item?.comando)
            })

        }))

        this.setState({ camposDisponiveis, comandosDisponiveis })
        setTimeout(() => {
            this.showWidgetParams({ target: { value: "11" } })
        }, 150)
    }

    showWidgetParams = (e) => {
        let newObj = this.state.novoWidget
        let { comandosDisponiveis } = this.state

        try {
            if (e?.target?.value === "") {
                newObj[e.target.id] = false
            } else {
                newObj[e.target.id] = e.target.value
            }
        } catch (err) {
            console.error(err)
        }

        let selectWidget = this.state.widgetsDisponiveis.filter((widget) => widget.id === parseInt(e.target.value))[0]
        let fields = selectWidget?.parametros?.map((field, key) => {
            let options = []

            if (field.field == "periodo") {
                options.push(<option key={Math.random()} value="1">1 Hora</option>)
                options.push(<option key={Math.random()} value="3">3 Horas</option>)
                options.push(<option key={Math.random()} value="6">6 Horas</option>)
                options.push(<option key={Math.random()} value="12">12 Horas</option>)
                options.push(<option key={Math.random()} value="24">24 Horas</option>)
            } else if (field.field == "comandos") {
                comandosDisponiveis.forEach((comando, key) => {
                    options.push(<option key={"c-" + key} value={comando.id}>{comando.descricao}</option>)
                })
            } else if (field.field == "campo_vazao") {
                let { camposDisponiveis } = this.state
                camposDisponiveis.forEach((campo, key) => {
                    options.push(<option key={"cd-" + key} value={campo.id}>{campo.label}</option>)
                })
            } else {
                let { camposDisponiveis } = this.state
                camposDisponiveis.forEach((campo, key) => {
                    options.push(<option key={"cd-" + key} value={campo.value}>{campo.label}</option>)
                })
            }

            switch (field.type) {
                case 'file':
                    return (
                        <div key={key}>
                            <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                            <input onChange={this.saveSubParamField} type="file" className="hdv-barsuper-input" id={field.field} name={field.field} />
                        </div>
                    )
                case 'select_field':
                    return (
                        <div key={key}>
                            <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                            <select onChange={this.saveSubParamField} id={field.field} name={field.field} className="hdv-barsuper-select">
                                <option value="">-- Selecione --</option>
                                {options}
                            </select>
                        </div>
                    )
                case 'custom_field':
                    return (
                        <div key={key}>
                            <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                            <input type="text" value={newObj?.campo ?? ""} readOnly id={field.field} name={field.field} className="hdv-barsuper-input" />
                        </div>
                    )
                case 'checkbox':
                    return (
                        <div key={key}>
                            <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>
                                {field.label}
                                <input type="checkbox" onChange={this.saveSubParamField} id={field.field} name={field.field} className="hdv-barsuper-select" />
                            </label>
                        </div>
                    )
                case 'float_between':
                    return (
                        <div key={key}>
                            <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                            <input onChange={(e) => this.saveSubParamBetween(field.field, "min", e.target.value)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                            &nbsp; Até &nbsp;
                            <input onChange={(e) => this.saveSubParamBetween(field.field, "max", e.target.value)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                        </div>
                    )
                default:
                    return (
                        <div key={key}>
                            <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                            {
                                ("fn" in field)
                                    ?
                                    <input onClick={field.fn} onChange={this.saveSubParamField} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                                    :
                                    <input onChange={this.saveSubParamField} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} />
                            }
                        </div>
                    )
            }
        })

        newObj['parametros'] = {}
        selectWidget?.parametros.forEach((field, key) => {
            newObj['parametros'][field.field] = false
        })

        this.setState({ novoWidget: newObj, htmlCampos: fields })
    }

    toggleEdit = () => {
        this.setState({ editEnabled: !this.state.editEnabled })
        document.querySelector('details.hdv-super-popup').open = false
    }

    showEditParams = () => {
        let { widgetEdit, modal, comandosDisponiveis, camposDisponiveis } = this.state
        if (!modal.edit) return

        let parametros = widgetEdit.widget.parametros
        let selectWidget = this.state.widgetsDisponiveis.find((widget) => widget.id === parseInt(widgetEdit.widget.type))

        let fields = selectWidget?.parametros?.map((field, key) => {
            if (field.field in parametros) {
                let value = parametros[field.field] === false ? "" : parametros[field.field]
                let options = []

                if (field.field == "periodo") {
                    options.push(<option key={Math.random()} value="1">1 Hora</option>)
                    options.push(<option key={Math.random()} value="3">3 Horas</option>)
                    options.push(<option key={Math.random()} value="6">6 Horas</option>)
                } else if (field.field == "comandos") {
                    options = []

                    comandosDisponiveis.filter((comando) => {
                        return comando.equipamento__imei == widgetEdit.equipamento
                    }).forEach((comando, key) => {
                        options.push(<option key={"c-" + key} value={comando.id}>{comando.descricao}</option>)
                    })
                } if (field.field == "campo_vazao") {
                    camposDisponiveis.forEach((campo, key) => {
                        options.push(<option key={"cd-" + key} value={campo.id}>{campo.label}</option>)
                    })
                } else {
                    this.state.camposDisponiveis.forEach((campo, key) => {
                        options.push(<option key={"cd-" + key} value={campo.value}>{campo.label}</option>)
                    })
                }

                switch (field.type) {
                    case 'file':
                        return (
                            <div key={key}>
                                <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                                <input onChange={(e) => this.saveSubParamField(e, true)} type="file" className="hdv-barsuper-input" id={field.field} name={field.field} value={value} />
                            </div>
                        )
                    case 'checkbox':
                        return (
                            <div key={key}>
                                <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>
                                    {field.label}
                                    <input onChange={(e) => this.saveSubParamField(e, true)} type="checkbox" id={field.field} name={field.field} className="hdv-barsuper-select" checked={parametros[field.field]} />
                                </label>
                            </div>
                        )
                    case 'select_field':
                        return (
                            <div key={key}>
                                <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                                <select
                                    value={field.field == "comandos" ? this.state.widgetEdit.widget.parametros.comandos : value}
                                    onChange={(e) => this.saveSubParamField(e, true)} id={field.field} name={field.field}
                                    className="hdv-barsuper-select">
                                    <option value="">-- Selecione --</option>
                                    {options}
                                </select>
                            </div>
                        )
                    case 'custom_field':
                        if (this.state.monitorados.length == 0) {
                            this.getMonitorados()
                        }

                        return (
                            <div className="row" key={key}>
                                <div className="col-md-6">
                                    <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="monitorado">Selecione o Monitorado</label>
                                    <select onChange={this.getMonitoradoFields} id="monitorado" name="monitorado" className="hdv-barsuper-select">
                                        <option value="">-- Monitorados --</option>
                                        {
                                            this.state.monitorados.map((monitorado, key) => {
                                                return <option key={key} value={monitorado.id}>{monitorado.nome}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                                    <select
                                        value={field.field == "comandos" ? this.state.widgetEdit.widget.parametros.comandos : value}
                                        onChange={(e) => this.saveSubParamField(e, true)} id={field.field} name={field.field}
                                        className="hdv-barsuper-select">
                                        <option value="">-- Selecione --</option>
                                        {options}
                                    </select>
                                </div>
                            </div>
                        )
                    case 'float_between':
                        return (
                            <div key={key}>
                                <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                                <input onChange={(e) => this.saveSubParamBetween(field.field, "min", e.target.value, true)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} value={value?.min} />
                                &nbsp; Até &nbsp;
                                <input onChange={(e) => this.saveSubParamBetween(field.field, "max", e.target.value, true)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} value={value?.max} />
                            </div>
                        )
                    default:
                        return (
                            <div key={key}>
                                <label className={(key === 0) ? "hdv-barsuper-label" : "hdv-barsuper-label hdv-barsuper-label-margin"} htmlFor={field.field}>{field.label}</label>
                                {("fn" in field)
                                    ?
                                    <div className="sup-icone" onClick={field.fn}>
                                        <input onChange={(e) => this.saveSubParamField(e, true)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} value={value} />
                                        <span className={`fa ${value} fa-2x`}></span>
                                    </div>
                                    :
                                    <input onChange={(e) => this.saveSubParamField(e, true)} type="text" className="hdv-barsuper-input" id={field.field} name={field.field} value={value} />
                                }
                            </div>
                        )
                }
            }
        }) ?? []

        return fields
    }

    saveSubParamField = (e, edit = false) => {
        let widgetEdit = this.state.widgetEdit
        let newObj = edit ? this.state.widgetEdit.widget : this.state.novoWidget

        if (e.target.type == "checkbox") {
            newObj.parametros[e.target.id] = e.target.checked
        } else {
            if (e.target.value === "") {
                newObj.parametros[e.target.id] = false
            } else {
                newObj.parametros[e.target.id] = e.target.value
            }
        }

        if (edit) {
            widgetEdit.widget = newObj
            document.querySelector(`#${e.target.id}`).value = e.target.value
            this.setState({ widgetEdit: widgetEdit })
        } else {
            this.setState({ novoWidget: newObj })
        }
    }

    saveSubParamBetween = (field, ref, value, edit = false) => {
        let widgetEdit = this.state.widgetEdit
        let newObj = edit ? this.state.widgetEdit.widget : this.state.novoWidget

        if (typeof (newObj.parametros[field]) !== 'object')
            newObj.parametros[field] = {}

        if (value === "") {
            newObj.parametros[field][ref] = false
        } else {
            newObj.parametros[field][ref] = value
        }

        if (edit) {
            widgetEdit.widget = newObj
            this.setState({ widgetEdit: widgetEdit })
        } else {
            this.setState({ novoWidget: newObj })
        }
    }

    saveSubParamIcon = (e) => {
        let editing = this.state.modal.edit
        let widgetEdit = this.state.widgetEdit
        let newObj = editing ? this.state.widgetEdit.widget : this.state.novoWidget

        document.getElementById('icone').value = e.target.id

        if (e.target.id === "") {
            newObj.parametros['icone'] = false
        } else {
            newObj.parametros['icone'] = e.target.id
        }

        if (editing) {
            widgetEdit.widget = newObj
            this.setState({ blockScreen: false, showIcons: false, widgetEdit })
        } else {
            this.setState({ blockScreen: false, showIcons: false, novoWidget: newObj })
        }
    }

    saveNewWidget = () => {
        let userInfo = auth.getUserInfo()
        let widget = this.state.novoWidget
        let widgets = this.state.widgets
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/reporte/supervisorio/'
        let equipamentosVinculados = []

        if (!widget.id_widget || !widget.descricao_widget) {
            helper.dispatchEvent("showAviso", { message: "Preencha todos os campos!" })

            return
        }

        this.setState({ blockScreen: true })

        widgets.push({
            id: uuidv4(),
            type: widget.id_widget,
            descricao: encodeURI(widget.descricao_widget),
            equipamento: widget.equipamento,
            mqtt: widget.equipamento_mqtt,
            campo: widget.campo,
            calcularVolume: widget.calcularVolume,
            parametros: widget.parametros
        })

        let supervisorio = this.state.supervisorioSelecionado
        supervisorio['widgets'] = JSON.stringify(widgets)

        if (widgets.length > 0) {
            for (let index = 0; index < widgets.length; index++) {
                if (!equipamentosVinculados.includes(widgets[index].equipamento) && widgets[index].equipamento !== false)
                    equipamentosVinculados.push(widgets[index].equipamento)
            }
        }

        if (equipamentosVinculados.length > 0) {
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
            let data_send = new FormData()

            data_send.append("equipamentos", JSON.stringify(equipamentosVinculados))
            axios.post(requestURL, data_send, config_me).then((res) => {
                const novoWidget = { id_widget: false, descricao_widget: false, equipamento: false, campo: false, calcularVolume: false, parametros: false }
                this.handleModal("widget");
                this.setState({
                    blockScreen: false, equipamentosData: res.data, equipamentosVinculados, widgets, novoWidget,
                    needUpdate: true, supervisorioSelecionado: supervisorio,
                    layouts: (supervisorio.layout) ? JSON.parse(supervisorio.layout) : []
                })
                this.updateSupervisory(supervisorio)
            })
        }
    }

    handleIntevalo = (e) => {
        let { apresentacao } = this.state
        apresentacao.intervalo = e
        this.setState({ apresentacao })
    }

    closeMenu = () => {
        document.querySelector('details.hdv-super-popup').open = false
    }

    convertDataTimeZone = (UTCDate) => {
        const timezone = auth.getUserInfo()?.timezone ?? this.props.timezone
        let timezoneDate = moment.tz(UTCDate, 'UTC')
        let formatted = timezoneDate.tz(timezone)
        return formatted
    }

    calcDifferenceInMinutes(timestamp) {
        const ultimoReporte = new Date(timestamp * 1000)
        const diffTime = (new Date().getTime() - ultimoReporte.getTime())
        let days = (diffTime / (1000 * 60 * 60 * 24)).toFixed(2)
        return days * 1440
    }

    convertTimezone = (dataUTC, format) => {
        if (dataUTC) {
            const systemTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
            try {
                let timezone = auth.getUserInfo()?.timezone ?? this.props.timezone
                if (!timezone) {
                    timezone = systemTZ
                }

                return moment.tz(dataUTC, 'UTC').tz(timezone).format(format)
            } catch (err) {
                return moment.tz(new Date().getTime(), 'UTC').tz(systemTZ).format(format)
            }
        }
    }

    isEditable = () => {
        if (this.props.shared) return false

        const userCanEdit = (auth?.getUserInfo()?.['tipo'] === "cliente") ? false : true

        return this.state.editEnabled && userCanEdit
    }

    calcExpiration = () => {
        let { expira } = this.props.link

        let difference = new Date(expira).getTime() - new Date().getTime()
        let days = Math.ceil(difference / (1000 * 3600 * 24) - 1)

        return `Link expira em: ${days} ${days === 1 ? "dia" : "dias"}`
    }

    handleTab = (index) => {
        this.setState({ tab: index })
    }

    render() {
        let { supervisorioSelecionado, novoWidget, tab } = this.state
        const isCliente = auth.getUserInfo()?.tipo === "cliente" ? true : false
        if (this.state.redirect.to)
            return <Redirect to={this.state.redirect.to} strict={false} />

        return (
            <div style={{ opacity: this.state.opacity, backgroundColor: "#d2d2d2" }}>
                <Modal isOpen={this.state.modal.apresentacao} toggle={() => this.handleModal("apresentacao")} className={this.props.className}>
                    <ModalHeader toggle={() => this.handleModal("apresentacao")}>Modo Apresentação</ModalHeader>
                    <ModalBody>
                        <div style={{ padding: '10px 0' }}>
                            <label className="required">Selecione o intervalo</label>
                            <Select
                                value={this.state.apresentacao.intervalo}
                                onChange={(e) => this.handleIntevalo(e)}
                                options={[
                                    { label: '30 Segundos', value: 30_000 },
                                    { label: '1 Minuto', value: 60_000 },
                                    { label: '2 Minutos', value: 120_000 },
                                    { label: '3 Minutos', value: 180_000 },
                                    { label: '5 Minutos', value: 300_000 },
                                    { label: '10 Minutos', value: 600_000 },
                                    { label: '15 Minutos', value: 900_000 },
                                ]}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.apresentacao.function === null
                            ? <Button color="success" onClick={this.toggleApresentacao}>Iniciar</Button>
                            : <Button color="danger" onClick={this.toggleApresentacao}>Parar</Button>
                        }
                        {' '}
                        <Button color="default" onClick={() => this.handleModal("apresentacao")}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <Modal size="md" isOpen={this.state.modal.supervisorio} toggle={() => this.handleModal("supervisorio")} className={this.props.className}>
                    <ModalHeader toggle={() => this.handleModal("supervisorio")}>Criar novo supervisório</ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="hdv-barsuper-label" htmlFor="descricao">Descrição</label>
                                    <input onChange={this.newSupervisory} className="hdv-barsuper-input" type="text" id="descricao" name="descricao" />
                                </div>
                                <div style={{ display: isCliente ? "none" : "block" }} className="col-md-6">
                                    <label className="hdv-barsuper-label" htmlFor="tipo">Tipo</label>
                                    <div className="d-flex">
                                        <label className="hdv-sup-label">
                                            <input onChange={this.newSupervisory} type="radio" id="tipo" name="tipo" value="cliente" checked={this.state.novoSupervisorio['tipo'] === "cliente"} />
                                            Cliente
                                        </label>

                                        <label className="hdv-sup-label">
                                            <input onChange={this.newSupervisory} type="radio" id="tipo" name="tipo" value="empresa" checked={this.state.novoSupervisorio['tipo'] === "empresa"} />
                                            Empresa
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <label
                                style={{ display: (this.state.novoSupervisorio['tipo'] === "cliente") ? "block" : "none" }}
                                className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="cliente_id">
                                Selecione o Cliente
                            </label>
                            <select
                                style={{ minWidth: '100%', display: (this.state.novoSupervisorio['tipo'] === "cliente") ? "block" : "none" }}
                                onChange={this.newSupervisory} id="cliente_id" name="cliente_id" className="hdv-barsuper-select">
                                <option value="">-- Selecione o Cliente --</option>
                                {this.state.clientes.map((cliente, key) => {
                                    return <option key={key} value={cliente.id}>{cliente.nome_fantasia}</option>
                                })}
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => { this.saveSupervisory(); this.handleModal("supervisorio") }}>Criar</Button>{' '}
                        <Button color="default" onClick={() => this.handleModal("supervisorio")}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <Modal size="lg" isOpen={this.state.modal.widget} toggle={() => this.handleModal("widget")} className={this.props.className}>
                    <ModalHeader toggle={() => this.handleModal("widget")}>Novo Componente</ModalHeader>
                    <ModalBody>
                        <div>
                            <label className="hdv-barsuper-label" htmlFor="descricao_widget">Descrição</label>
                            <input onChange={this.newWidget} className="hdv-barsuper-input" type="text" id="descricao_widget" name="descricao_widget" />

                            <div className="row">
                                <div className="col-md-6">
                                    <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="monitorado">
                                        Selecione o Monitorado
                                    </label>
                                    <select
                                        onChange={this.getMonitoradoFields}
                                        id="monitorado" name="monitorado" className="hdv-barsuper-select">
                                        <option value="">-- Monitorados --</option>
                                        {
                                            this.state.monitorados.map((monitorado, key) => {
                                                return <option key={key} value={monitorado.id}>{monitorado.nome}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="campo">Selecione o Parâmetro</label>
                                    <select onChange={this.newWidget} id="campo" name="campo" className="hdv-barsuper-select">
                                        <option value="">-- Campos Disponíveis --</option>
                                        {
                                            this.state.camposDisponiveis.map((campo, key) => {
                                                return <option key={key} value={campo.value}>{campo.label}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <label className="hdv-barsuper-label hdv-barsuper-label-margin" htmlFor="id_widget">
                                Selecione o tipo do componente
                            </label>
                            <select onChange={this.showWidgetParams} id="id_widget" name="id_widget" className="hdv-barsuper-select">
                                <option value="">-- Componentes --</option>
                                {
                                    this.state.widgetsDisponiveis.map((widget, key) => {
                                        return <option key={key} value={widget.id}>{widget.descricao}</option>
                                    })
                                }
                            </select>
                            {(this.state.htmlCampos?.length > 0)
                                ?
                                <fieldset
                                    style={{ display: (this.state.novoWidget['id_widget'] !== false) ? "block" : "none", marginTop: "15px" }}
                                    className="hdv-widget-config-fieldset">
                                    <legend className="hdv-widget-config-fieldset-legend">Configurações do Componente:</legend>
                                    {this.state.htmlCampos}
                                </fieldset>
                                :
                                null
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => { this.saveNewWidget() }}>Adicionar</Button>{' '}
                        <Button color="default" onClick={() => this.handleModal("widget")}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    isOpen={this.state.modal.edit}
                    toggle={() => this.handleModal("edit")}
                    className={`${this.props.className} modal-lg`}>
                    <ModalHeader toggle={() => this.handleModal("edit")}>Editar Componente</ModalHeader>
                    <ModalBody>
                        <div className="hdv-widget-popup">
                            <label className="mb-3">Descrição
                                <br />
                                <input
                                    type="text"
                                    className="hdv-barsuper-input"
                                    value={this.state.widgetEdit.input}
                                    onChange={(e) => this.setState({ widgetEdit: { ...this.state.widgetEdit, input: e.target.value } })}
                                />
                            </label>
                            {this.showEditParams()}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.deleteWidget(this.state.widgetEdit.widget.id)}>
                            Excluir Componente
                        </Button>{' '}
                        <Button color="primary" onClick={this.saveWidget}>Salvar</Button>{' '}
                        <Button color="default" onClick={() => this.handleModal("edit")}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modal.delete} toggle={() => this.handleModal("delete")} className={this.props.className}>
                    <ModalHeader toggle={() => this.handleModal("delete")}>Deletar Supervisório</ModalHeader>
                    <ModalBody>
                        <div style={{ padding: '20px 0', fontSize: '16px', opacity: '0.9' }}>
                            Realmente deseja deletar o Supervisório?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => { this.deleteSupervisory(); this.handleModal("delete") }}>Deletar</Button>{' '}
                        <Button color="default" onClick={() => this.handleModal("delete")}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modal.erro} toggle={() => this.handleModal("erro")}>
                    <ModalHeader toggle={() => this.handleModal("erro")}>Erro</ModalHeader>
                    <ModalBody>
                        <div style={{ padding: '20px 0', fontSize: '16px', opacity: '0.9' }}>
                            Erro ao carregar supervisório.
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={() => this.handleModal("erro")}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                <div className="row hdv-barsuper">
                    {this.props.shared
                        ?
                        <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                            <h6 className="hdv-sup-title pt-1 m-0">{this.state.supervisorioSelecionado.descricao}</h6>
                            <span style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                                {this.state.updating ? <i className="fa fa-sync fa-2x spinning mr-2"></i> : null}
                                {this.calcExpiration()}
                            </span>
                        </div>
                        :
                        <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                            <TabList style={{ borderBottom: "none", margin: "3px 0 10px", padding: "0" }}>
                                <Link
                                    to='/'
                                    style={{
                                        color: "var(--fonte_titulo_abas)",
                                        padding: "20px 20px 20px 0",
                                        transition: "opacity 0.2s",
                                        fontSize: "26px",
                                        opacity: "1"
                                    }}
                                    onMouseEnter={(e) => e.target.style.opacity = "0.7"}
                                    onMouseLeave={(e) => e.target.style.opacity = "1"}>
                                    <img src={arrowLeft} style={{ width: "24px" }} />
                                </Link>
                                <Tab
                                    style={{
                                        transition: "opacity 0.2s",
                                        opacity: "1"
                                    }}
                                    onMouseEnter={(e) => e.target.style.opacity = "0.7"}
                                    onMouseLeave={(e) => e.target.style.opacity = "1"}>
                                    Widgets
                                </Tab>
                                <Tab
                                    style={{
                                        transition: "opacity 0.2s",
                                        opacity: "1"
                                    }}
                                    onMouseEnter={(e) => e.target.style.opacity = "0.7"}
                                    onMouseLeave={(e) => e.target.style.opacity = "1"}>
                                    Planta Baixa
                                </Tab>
                            </TabList>
                        </Tabs>
                    }

                    {!this.props.shared
                        ?
                        <>
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                {auth?.getUserInfo()?.['tipo'] !== "cliente" && tab !== 1 ?
                                    <button className="hdv-barsuper-button d-flex align-items-center hdv-barsuper-new" onClick={() => this.handleModal("supervisorio")}>
                                        <i className="fa fa-plus fa-2x"></i>
                                        Novo
                                    </button>
                                    : ""
                                }

                                <div className="select-supervisory">
                                    <Select
                                        value={supervisorioSelecionado ? { label: supervisorioSelecionado?.descricao, value: supervisorioSelecionado?.id } : null}
                                        onChange={(e) => this.selectSupervisory(e.supervisorio.id)}
                                        options={this.sortSupervisories()}
                                        noOptionsMessage={() => "Nenhum supervisório encontrado"}
                                        height={"38px !important"}
                                        placeholder={""}
                                    />
                                </div>

                                {auth?.getUserInfo()?.['tipo'] !== "cliente" && tab !== 1 ?
                                    <button
                                        className="hdv-barsuper-button hdv-barsuper-update d-flex align-items-center"
                                        disabled={!(this.state.needUpdate)}
                                        onClick={this.actionUpdateSupervisory}>
                                        {this.state.updating ?
                                            <>
                                                <i className="fa fa-sync fa-2x spinning"></i>
                                                Atualizando
                                            </>
                                            :
                                            <>
                                                <i className="fa fa-cloud-upload-alt fa-2x"></i>
                                                Salvar
                                            </>
                                        }
                                    </button>
                                    : ""
                                }

                            </div>
                            <div>
                                {auth?.getUserInfo()?.['tipo'] !== "cliente" && tab !== 1 ?
                                    <details
                                        className="hdv-super-popup"
                                        onClick={(e) => { if (e.target.classList.contains("hdv-super-popup")) e.target.open = false }}>
                                        <summary className="hdv-barsuper-button d-flex align-items-center" style={{ backgroundColor: "#D2D2D2" }} >
                                            <i className="fa fa-bars fa-2x" style={{ userSelect: 'none', color: "#222" }} />
                                            <span style={{ color: "black", marginLeft: "6px" }}>Opções</span>
                                        </summary>
                                        <section
                                            className="px-3 popup"
                                            style={{
                                                boxShadow: "rgb(236, 236, 236) 5px 5px 0px 3px",
                                                border: "3px solid rgb(236,236,236)",
                                                borderRadius: "15px"
                                            }}>
                                            <div className="row pt-2">
                                                <div className="col-md-6" style={{ paddingRight: '4px' }}>
                                                    <button
                                                        disabled={(this.state.supervisorioSelecionado) ? false : true}
                                                        className="hdv-popup-button hdv-barsuper-new m-0"
                                                        onClick={() => {
                                                            this.closeMenu()
                                                            this.handleModal("widget")
                                                            this.getMonitorados()
                                                        }}>
                                                        <i className="fa fa-plus fa-2x"></i>
                                                        Novo Componente
                                                    </button>
                                                </div>
                                                <div className="col-md-6" style={{ paddingLeft: '4px' }}>
                                                    <button
                                                        style={{ display: (auth?.getUserInfo()?.['tipo'] === "cliente") ? "none" : "inline" }}
                                                        className="hdv-popup-button hdv-barsuper-slide"
                                                        onClick={() => {
                                                            this.closeMenu()
                                                            this.handleModal("apresentacao")
                                                        }}>
                                                        <i className="fa fa-play fa-2x"></i>
                                                        Apresentação
                                                    </button>
                                                </div>
                                            </div>

                                            <hr style={{ width: '100%', border: '1px solid #ddd', display: (auth?.getUserInfo()?.['tipo'] === "cliente") ? "none" : "inline" }} />
                                            <label style={{ opacity: "0.8" }}>Outros</label>
                                            <SupervisorioLinkForm supervisorioSelecionado={this.state.supervisorioSelecionado} />

                                            <button
                                                style={{ display: (auth?.getUserInfo()?.['tipo'] === "cliente") ? "none" : "inline" }}
                                                disabled={!Boolean(this.state.supervisorioSelecionado)}
                                                onClick={this.toggleEdit}
                                                className="hdv-barsuper-default hdv-popup-button">
                                                <i className="fa fa-pen fa-2x"></i>
                                                {this.state.editEnabled ? "Desativar edição" : "Editar"}
                                            </button>
                                            <button style={{ display: (auth?.getUserInfo()?.['tipo'] === "cliente") ? "none" : "inline" }}
                                                disabled={(this.state.supervisorioSelecionado) ? false : true}
                                                onClick={() => this.handleModal("delete")}
                                                className="hdv-barsuper-delete hdv-popup-button mt-1">
                                                <i className="fa fa-trash fa-2x"></i>
                                                Deletar Supervisório
                                            </button>
                                        </section>
                                    </details>
                                    : <div style={{ minWidth: "250px" }} className="hdv-btn-group"></div>
                                }
                            </div>
                        </>
                        :
                        null
                    }
                </div>
                <div className="hdv-bodysuper">
                    <Tabs className="tabs-style" selectedIndex={this.state.tab} onSelect={(i) => this.handleTab(i)}>
                        <TabPanel>
                            <ResponsiveReactGridLayout
                                className="layout"
                                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                                draggableCancel={"input, i, select"}
                                rowHeight={30}
                                isDraggable={this.isEditable()}
                                isResizable={this.isEditable()}
                                layouts={(this.state.layouts) ? this.state.layouts : {}}
                                onLayoutChange={(layout, layouts) => {
                                    try {
                                        document.querySelectorAll('*[id^="chart-"]').forEach((chart) => {
                                            const parent = chart.parentElement
                                            const child = chart.querySelector("div")
                                            child.style.height = `${parent?.clientHeight - 80}px`
                                        })
                                    } catch (err) {
                                        console.error(err)
                                    }

                                    let supervisorio = this.state.supervisorioSelecionado

                                    if (this.props.shared) return

                                    if (supervisorio) {
                                        if (supervisorio['layout'] !== JSON.stringify(layouts)) {
                                            supervisorio['layout'] = JSON.stringify(layouts)
                                            this.setState({ supervisorioSelecionado: supervisorio, layouts: layouts })
                                            this.updateSupervisory(supervisorio)
                                        }
                                    }
                                }}>

                                {this.state.widgets.map((widget, key) => {
                                    let { equipamentosData } = this.state
                                    let value = 0
                                    let metric = ""
                                    let type_el = false
                                    let atrasado = false
                                    let date = ""
                                    let unidade = ""
                                    let evento = null
                                    let widgetDescricao = widget.descricao

                                    try {
                                        widgetDescricao = decodeURI(widgetDescricao)
                                    } catch {
                                        widgetDescricao = widget.descricao
                                    }

                                    try {
                                        value = equipamentosData?.[widget.equipamento]?.[widget.campo]
                                        unidade = equipamentosData?.[widget.equipamento]?.unidades?.[widget.campo]
                                        evento = (typeof (equipamentosData[widget.equipamento]) !== "undefined") ? equipamentosData?.[widget.equipamento] : false
                                        date = this.convertTimezone(evento?.['timestamp_dispositivo'] * 1000, 'DD/MM HH:mm')
                                    } catch (err) {
                                        console.error(err)
                                    }

                                    if (evento && "minutos_alerta_semcomunicacao" in widget.parametros) {
                                        const asMinutes = this.calcDifferenceInMinutes(evento?.timestamp_dispositivo)
                                        const hasAlert = widget.parametros.minutos_alerta_semcomunicacao != false && widget.parametros.minutos_alerta_semcomunicacao > 0

                                        if (hasAlert && asMinutes > parseFloat(widget.parametros.minutos_alerta_semcomunicacao)) {
                                            atrasado = true
                                        } else if (asMinutes > 2160) {
                                            atrasado = true
                                        }
                                    }

                                    const common = {
                                        ...widget,
                                        shared: this.props.shared,
                                        id: helper.randomKey(7),
                                        params: widget.parametros,
                                        unidade: unidade,
                                        ultimoEvento: (settings = {}) => {
                                            let ultimaComunicacao = null
                                            let showUnity = true

                                            try {
                                                if (evento && 'timestamp_dispositivo' in evento) {
                                                    ultimaComunicacao = this.convertTimezone(evento['timestamp_dispositivo'] * 1000, 'DD/MM/YYYY HH:mm')
                                                }
                                            } catch (err) {
                                                console.error(err)
                                            }

                                            if (settings?.showUnity == false) {
                                                showUnity = false
                                            }

                                            return (
                                                <div className="hdv-top-bar-bloco">
                                                    {this.state.editEnabled ?
                                                        <i className={this.props.shared ? "hdv-noshow-item" : "hdv-widget-options fa fa-ellipsis-v"}
                                                            onClick={(e) => {
                                                                this.setState({
                                                                    widgetEdit: {
                                                                        widget,
                                                                        element: e.currentTarget.parentElement.querySelector(".hdv-top-bar-bloco span"),
                                                                        input: widgetDescricao
                                                                    }
                                                                })
                                                                this.handleModal("edit")
                                                            }}
                                                        />
                                                        :
                                                        null
                                                    }
                                                    <i data-status={atrasado ? "Atrasado" : "Atualizado"} className="fa fa-square-full"></i>
                                                    <span>{widgetDescricao}</span>
                                                    <div className="hdv-top-bar-hidden">
                                                        <p><b>Equipamento:</b> {evento?.id_dispositivo ?? "---"}</p>
                                                        <p><b>Status:</b> {(!atrasado) ? <span>Atualizado</span> : <span>Com atraso</span>}</p>
                                                        <p><b>Última atualização:</b> {ultimaComunicacao ?? "---"}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }

                                    switch (widget.type) {
                                        case "1":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 12, x: 0, y: 6, minW: 2, minH: 6, maxH: 14 }}>
                                                    <Reservatorio dataWidget={{ ...common, value }} key={key} />
                                                </div>
                                            )
                                        case "2":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                                    <ValorAtual dataWidget={{ ...common, value, unidade, metric, type_el }} key={key} />
                                                </div>
                                            )
                                        case "3":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 2, h: 4, x: 0, y: 6, minW: 2, minH: 4 }}>
                                                    <Imagem key={key} />
                                                </div>
                                            )
                                        case "4":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 5, x: 0, y: 6, minW: 4, minH: 5 }}>
                                                    <MaximoAtual dataWidget={{ ...common, value }} key={key} />
                                                </div>
                                            )
                                        case "5":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 2, h: 12, x: 0, y: 6, minW: 2, minH: 12 }}>
                                                    <Poco dataWidget={{ ...common, id: key, forceupdate: 0, value, event: evento }} key={key} />
                                                </div>
                                            )
                                        case "6":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 12, x: 18, y: 6, minW: 4, minH: 6 }}>
                                                    <GraficoLinha dataWidget={{ ...common, value: value, date }} key={key} />
                                                </div>
                                            )
                                        case "7":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 6, h: 12, x: 0, y: 6, minW: 4, minH: 6 }}>
                                                    <GraficoBarra dataWidget={{ ...common, value: value, date }} key={key} />
                                                </div>
                                            )
                                        case "8":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                                    <Status dataWidget={{ ...common, value }} key={key} />
                                                </div>
                                            )
                                        case "9":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                                    <VolumeDia dataWidget={{ ...common, value, metric, type_el }} key={key} />
                                                </div>)
                                        case "10":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 4, x: 0, y: 6, minW: 3, minH: 4 }}>
                                                    <ValorAtualV2 dataWidget={{ ...common, value, unidade, metric, type_el, }} key={key} />
                                                </div>
                                            )
                                        case "11":
                                            return (
                                                <div className="div-sample" key={widget.id} data-grid={{ w: 3, h: 8, x: 0, y: 6, minW: 3, minH: 8 }}>
                                                    <Comando dataWidget={{ ...common, value }} key={key} comandos={this.state.comandos} />
                                                </div>
                                            )
                                        default:
                                            return null
                                    }
                                })
                                }
                            </ResponsiveReactGridLayout>
                        </TabPanel>
                        <TabPanel>
                            <PlantaBaixa
                                supervisorioSelecionado={supervisorioSelecionado}
                                idSupervisorio={supervisorioSelecionado?.id}
                                shared={this.props?.shared}
                                dataShared={this.props?.dadosPlantaBaixa}
                                hash={this.props.hash}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
                <div className="hdv-overlay" style={{ "visibility": (this.state.blockScreen) ? "visible" : "hidden" }}></div>

                <div className="hdv-icons-list" style={{ "display": (this.state.showIcons) ? "block" : "none" }}>
                    <div><h2>Selecione um Ícone</h2></div>
                    <ul>
                        {helper.getIconList()?.map((icon, index) => (
                            <li key={index}>
                                <i onClick={this.saveSubParamIcon} className={`fa fa-${icon}`} id={`fa-${icon}`}></i>
                            </li>
                        ))
                        }
                    </ul>
                </div>
            </div >
        )
    }
}

function SupervisorioLink(props) {
    const [link, setLink] = useState({
        hash: null,
        supervisorio: null,
        timezone: null,
        expira: null
    })

    const [dadosPlantaBaixa, setDadosPlantaBaixa] = useState({})

    function checkHash() {
        const hash = props.match.params.hash
        const url = `${cfg.base_api_url + cfg.api_v2}/check-supervisorio-link/${hash}/`

        if (hash) {
            axios({
                url: url,
                method: "GET",
                headers: { 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                let supervisoryData = res.data.supervisorio

                setDadosPlantaBaixa(res.data.supervisorio_planta)

                try {
                    let equipamentos = []
                    let link = {
                        hash: hash,
                        timezone: res.data.timezone,
                        expira: res.data.expira ? (res.data?.expira * 1000) : null
                    }

                    const widgets = JSON.parse(supervisoryData?.widgets)
                    widgets.forEach(widget => equipamentos.push(widget.equipamento))
                    equipamentos = Array.from(new Set(equipamentos))

                    let formData = new FormData()
                    formData.append("equipamentos", JSON.stringify(equipamentos))

                    axios({
                        url: url,
                        method: "POST",
                        data: formData,
                        headers: { 'Authorization': 'Bearer ' + auth.getToken() }
                    }).then((res) => {
                        supervisoryData["equipamentos"] = equipamentos
                        supervisoryData["reportes"] = res.data

                        link.supervisorio = supervisoryData
                        setLink(link)
                    })
                }
                catch (err) {
                    console.error(err)
                }
            })
        }
    }

    useEffect(() => checkHash(), [])

    return link.supervisorio ? <Supervisorio hash={props.match.params.hash} link={link} shared={true} dadosPlantaBaixa={dadosPlantaBaixa} /> : null
}

export { Supervisorio, SupervisorioLink }