import React, { Component } from "react";
import Select from "../../../../components/Select";

class ConfiguracoesDados extends Component  {
    
    handleChange = (key, value) => {
        let detalhes = Object.assign({}, this.props.detalhes)

        detalhes[key] = value

        this.props.setDetalhes(detalhes)
    }

    handleInput = (e) => {
        let detalhes = Object.assign({}, this.props.detalhes)
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value

        if(e.target.id === "periodoTodo" && value) {
            detalhes['agruparDados'] = { label: "Fixo", value: null }
        }

        detalhes[e.target.id] = value

        this.props.setDetalhes(detalhes)
        this.props.getCampos(detalhes.campos, detalhes.periodoTodo)
    }

    render() {
        const  { styleFieldset, detalhes } = this.props

        return(
            <details style={ styleFieldset.fieldset } open={true} className='custom-summary'>
                <summary>Configurações dos dados</summary>
                <div style={{padding: "15px 0px"}}>
                    <div style={{ alignItems: "center", margin: "0px"}} className="row">
                        <div className="col-md-4">
                            <label className="required">Agrupar dados</label>
                            <Select
                                value={detalhes.agruparDados}
                                onChange={(e) => this.handleChange("agruparDados", e)}
                                options={[{ label: "Fixo", value: null }, { label: "Hora", value: "hora" }, { label: "Dia", value: "dia" }, { label: "Mês", value: "mes" }, { label: "Ano", value: "ano" }]}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="required">Separador de valores numéricos</label>
                            <Select
                                value={detalhes.separador}
                                onChange={(e) => this.handleChange("separador", e)}
                                options={[
                                    { label: "Padrão", value: 'en-US' },
                                    { label: "Português (Brasil)", value: 'pt-BR' }
                                ]}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="periodo" className="required">Exibir dados pelo periodo todo</label>
                            <br />
                            <input onChange={(e) => this.handleInput(e)} checked={detalhes.periodoTodo} id='periodoTodo' type="checkbox" />
                        </div>
                    </div>
                </div>
            </details>
        )
    }
}

export default ConfiguracoesDados