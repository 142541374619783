import React, { Component } from 'react'
import HeaderCustomizado from './components/HeaderCustomizado'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ReferenciaRelatorio from './components/referencia'
import { RelatorioContext } from '../../contexts/Relatorio'
import ResumoRelatorio from './components/resumo'
import mascaraParametrizacao from "../../components/MascaraParametrizacao"
import ButtonsForm from '../../components/ButtonsForm'
import GraficoRelatorio from './components/grafico'
import Select from '../../components/Select'
import { Link, Redirect } from 'react-router-dom'
import ReactEcharts from "echarts-for-react"
import ReactLoading from 'react-loading'
import helper from '../../utils/helper'
import domToImage from 'dom-to-image'
import pdfMake from 'pdfmake'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'


pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    }
}

class Form extends Component {
    static contextType = RelatorioContext

    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhesDefault: { data: "", agrupar: "", descricao: "", cliente: "" },
        validacao_formulario: { data: true, descricao: true, agrupar: true },
        carregandoDados: false,
        relatorioGerado: false,
        charts: [],
        optionsCliente: [],
        reporte: { data: '', calc: '', referencia: [], resumo: [] },
        redirect: false,
        cooldown: false,
        exportCooldown: false,
        relatorioErro: false,
        cliente: false,
        tabIndex: 0,
        tiposGrafico: [
            { label: 'Linha', value: 'linha' },
            { label: 'Barra', value: 'barra' }
        ],
        disabledCliente: false
    }

    userInfo = auth.getUserInfo()

    componentWillMount() {
        this.context.set({ getData: this.getData })
    }

    componentDidMount() {
        this.setState({ cliente: auth.getUserInfo().tipo === "cliente" })

        if (this.userInfo.tipo === "cliente") {
            let optionsCliente = [{ value: this.userInfo.cliente, label: this.userInfo.nome }]
            this.setState({ optionsCliente })

            if (this.props.match.params.id) {
                this.context.set({ relatorioId: this.props.match.params.id, clientes: optionsCliente })

                this.getData()
            }
        } else {

            this.getClients().then(() => {
                if (this.props.match.params.id) {
                    this.context.set({ relatorioId: this.props.match.params.id })

                    this.getData()
                }
            })
        }
    }

    getClients = async () => {
        await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/cliente/', this.configMe)
            .then(cliente => {
                let optionsCliente = []
                optionsCliente.push({ label: 'Nenhum', value: null })

                if (cliente.data?.results.length > 0) {
                    cliente.data?.results?.forEach((c) => {
                        optionsCliente.push({ "value": c.id, "label": c.nome_fantasia })
                    })
                }

                this.setState({ optionsCliente })
            })
    }

    getReferencias = () => {
        axios.get(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/referencia/?limit=100`, this.configMe)
            .then(res => {
                let referencias = []
                const { resource } = this.context

                if (res.data) {
                    referencias = res.data?.results ?? []
                    referencias.map(ref => {
                        ref['tipo_da_referencia'] = resource.referenciaOptions.find(r => r.value === ref['tipo_da_referencia'])
                        ref['automacao'] = resource.automacaoOptions.find(a => a.value === ref['automacao'])
                    })
                }

                this.context.set({ referencias })
            })
    }

    getResumos = () => {
        axios.get(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/resumo`, this.configMe)
            .then(res => {
                const { resource } = this.context
                let resumos = []

                if (res.data) {
                    resumos = res.data?.results ?? []
                    resumos.map(resumo => {
                        resumo['icone'] = resource.resumoIconOptions.find(icon => icon.value === resumo['icone'])
                        if (resumo?.['resumo_pre_definido']) {
                            resumo['tipo'] = resource.tiposResumo.find(tipo => tipo.value === resumo['resumo_pre_definido'])
                        }
                    })
                }

                this.context.set({ resumos })
            })
    }

    getData = (updateDefault = true) => {
        this.setState({ relatorioGerado: false })

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}`,
            ...this.configMe
        }).then(res => {
            let { detalhesDefault, optionsCliente } = this.state
            const data = res.data

            if (updateDefault) {
                detalhesDefault['descricao'] = data.descricao
                detalhesDefault['agrupar'] = { label: 'Dia', value: 'day' }
                detalhesDefault['cliente'] = optionsCliente?.find(c => c.value == res.data?.cliente)

                this.setState({ detalhesDefault, relatorioGerado: false, disabledCliente: true })
            }

            this.getReferencias()
            this.getResumos()
        }).catch(error => {
            console.error(error)
            this.setState({ relatorioErro: true })
        })

        axios.get(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/grafico/?limit=100`, this.configMe)
            .then(res => {
                let charts = res.data?.results || []

                const tipos = {
                    linha: { label: 'Linha', value: 'linha' },
                    barra: { label: 'Barra', value: 'barra' }
                }

                const visualizacoes = {
                    med: { label: 'Média do período', value: 'med' },
                    tudo: { label: 'Todo o período', value: 'tudo' },
                    min_max: { label: 'Mínima e Máxima', value: 'min_max' },
                }

                const calcularComo = {
                    padrao: { label: 'Padrão', value: 'padrao' },
                    volume: { label: 'Volume', value: 'volume' },
                    horimetro: { label: 'Horímetro', value: 'horimetro' },
                }

                charts?.forEach(chart => {
                    chart['tipo_grafico'] = tipos[chart['tipo_grafico']]
                    chart['visualizacao'] = visualizacoes[chart['visualizacao']]
                    chart['calcular_como'] = calcularComo[chart['calcular_como']]
                })

                this.context.set({ charts })
            })
    }

    saveAction = () => {
        let { detalhesDefault } = this.state
        let not_validate = false
        let validate_form = this.state.validacao_formulario
        let except = ['data', 'agrupar']

        for (let key in validate_form) {
            if (!detalhesDefault[key] && !except.includes(key)) {
                validate_form[key] = false
                not_validate = true
            } else
                validate_form[key] = true
        }

        this.setState({ validacao_formulario: validate_form, carregandoDados: false })

        if (not_validate) {
            window.scrollTo(0, 50)
            return
        }

        this.setState({ cooldown: true })

        let verb = 'POST'
        let url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/`
        if (this.props.match.params.id) {
            verb = 'PATCH'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${this.props.match.params.id}/`
        }

        const bodyFormData = {
            descricao: detalhesDefault.descricao
        }

        if (this.userInfo?.tipo == "cliente" && this.userInfo?.cliente) {
            bodyFormData['cliente_id'] = this.userInfo?.cliente
        } else {
            bodyFormData['cliente_id'] = detalhesDefault.cliente ? detalhesDefault.cliente?.value : null
        }

        axios({ method: verb, url: url, data: bodyFormData, ...this.configMe }).then(res => {
            helper.dispatchEvent("showAviso", {
                message: 'Relatorio salvo com sucesso',
                callback: () => this.setState({ redirect: true, path: "/relatorio-customizado" })
            })
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    getResource = () => {
        let userInfo = auth.getUserInfo()
        let requestArray = []

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/automacao/?limit=100`, this.configMe))

        axios.all(requestArray).then(axios.spread((automacoes) => {
            let automacaoOptions = []
            let resource = this.context.resource

            if (automacoes.data) {
                automacoes?.data?.results?.forEach(resource => {
                    automacaoOptions.push({
                        label: resource.descricao,
                        value: resource.id,
                        condicao: resource.condicao
                    })
                })
            }

            resource['automacaoOptions'] = automacaoOptions
            this.context.set({ resource })
        }))
    }

    getDates = (startDate, endDate, group) => {
        let [dates, currentDate] = [[], startDate]

        if (group === 'day') {
            const addDays = function (days) {
                let date = new Date(this.valueOf())
                date.setDate(date.getDate() + days)
                return date
            }
            while (currentDate <= endDate) {
                dates.push(`${currentDate.getDate()}/${currentDate.getMonth() + 1}`)
                currentDate = addDays.call(currentDate, 1)
            }
        } else if (group === 'month') {
            const addMonths = function (months) {
                let date = new Date(this.valueOf())
                date.setMonth(date.getMonth() + months)
                return date
            }

            while (currentDate <= endDate) {
                dates.push(`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`)
                currentDate = addMonths.call(currentDate, 1)
            }
        }
        return dates
    }

    generateChartSeries = (chart, res, xAxis, group) => {
        let data = []
        let keys = xAxis
        let field = chart?.campo_customizado?.identificacao
        let equipamento = chart?.campo_customizado?.equipamento__imei

        if (chart?.visualizacao.value === 'tudo') {
            xAxis = []

            for (let i = 0; i < keys.length; i++) {
                if (res.data[equipamento][keys[i]]?.length > 0) {
                    res.data[equipamento][keys[i]]?.forEach(reporte => {
                        const result = Number(reporte[field])
                        data.push(isNaN(result) ? 0 : result)

                        if (group === 'hour')
                            xAxis.push(new Date(reporte.timestamp_dispositivo * 1000).toLocaleTimeString('pt-BR').slice(0, 5))
                        else
                            xAxis.push(new Date(reporte.timestamp_dispositivo * 1000).toLocaleDateString('pt-BR', { hour: 'numeric', minute: 'numeric' }))
                    })
                } else {
                    data.push(0)
                }
            }
        }
        else if (chart.calcular_como.value === 'horimetro') {
            try {
                for (let i = 0; i < keys.length; i++) {
                    const item = res.calc[equipamento][field][keys[i]]
                    data.push(item)
                }
            } catch (err) {
                console.error(err)
                console.info("horimetro")
            }
        }
        else if (chart?.visualizacao.value === 'med') {
            if (chart.calcular_como?.value === 'volume') {
                try {
                    for (let i = 0; i < keys.length; i++) {
                        let result = res.calc?.[equipamento]?.[field]?.[keys[i]]

                        if (result === undefined || isNaN(result)) {
                            result = undefined
                        }

                        data.push(result)
                    }
                } catch (err) {
                    console.error(err)
                    console.info("media_volume")
                }
            } else {
                try {
                    for (let i = 0; i < keys.length; i++) {
                        let total = 0
                        let length = res.data[equipamento][keys[i]].length

                        res.data[equipamento][keys[i]].forEach(reporte => {
                            total += Number(reporte[field] ?? 0)
                        })

                        if (total == 0 && !length) {
                            data.push(undefined)
                        } else {
                            const result = Number(total / length)?.toFixed(2)
                            data.push(isNaN(result) ? 0 : result)
                        }
                    }
                } catch (err) {
                    console.error(err)
                    console.info("media_padrao")
                }
            }
        }
        else if (chart?.visualizacao.value === 'min_max') {
            try {
                for (let i = 0; i < keys.length; i++) {
                    let periodo = []
                    let maior, menor

                    res.data[equipamento][keys[i]]?.forEach(reporte => periodo.push(reporte[field]))

                    maior = periodo.length > 0 ? Math.max(...periodo) : undefined
                    menor = periodo.length > 0 ? Math.min(...periodo) : undefined

                    data.push({ menor, maior })
                }
            } catch (err) {
                console.error(err)
                console.info("min_max")
            }
        }

        return [data, xAxis]
    }

    gerarDadosDinamicos = (chart, ref, xAxis, dados) => {
        let data = []
        let keys = xAxis ?? []
        let field = ref?.campo_customizado?.identificacao
        let equipamento = ref?.campo_customizado?.equipamento__imei

        try {
            if (chart?.visualizacao.value === 'tudo') {
                for (let i = 0; i < keys.length; i++) {
                    dados.data[equipamento][keys[i]]?.forEach(reporte => data.push(reporte?.[field]))
                }

                return data
            }

            if (ref.calcula_como_volume) {
                for (let i = 0; i < keys.length; i++) {
                    let result = dados.calc?.[equipamento]?.[field]?.[keys[i]]

                    if (result === undefined || isNaN(result)) {
                        result = undefined
                    }

                    data.push(result)
                }
            } else {
                for (let i = 0; i < keys.length; i++) {
                    let total = 0

                    dados.data[equipamento][keys[i]]?.forEach(reporte => {
                        if (reporte?.[field]) {
                            total += Number(reporte[field])
                        }
                    })

                    const result = Number(total / dados?.data?.[equipamento]?.[keys[i]]?.length)?.toFixed(2)
                    data.push(isNaN(result) ? 0 : result)
                }
            }
        } catch (err) {
            console.error(err)
            console.info("dinamico")
        }

        return data
    }

    buscarDadosRelatorio = () => {
        this.setState({ carregandoDados: true, relatorioGerado: false, relatorioErro: false })
        let { charts } = this.context
        let { detalhesDefault } = this.state
        let notValid = false
        let valiate_form = this.state.validacao_formulario
        const except = ['descricao']

        for (let key in detalhesDefault) {
            if (!detalhesDefault[key] && !except.includes(key)) {
                valiate_form[key] = false
                notValid = true
            } else {
                valiate_form[key] = true
            }
        }

        if (notValid) {
            this.setState({ validacao_formulario: valiate_form, carregandoDados: false })
            return
        }

        let data_ini = detalhesDefault.data.split('-')[0].split('/').reverse().join('-') + ' 00:00:00'
        let data_fim = detalhesDefault.data.split('-')[1].split('/').reverse().join('-') + ' 23:59:59'

        const bodyFormData = new FormData()
        bodyFormData.append('relatorio_id', this.props.match.params.id)
        bodyFormData.append('data_ini', data_ini)
        bodyFormData.append('data_fim', data_fim)
        bodyFormData.append('group', detalhesDefault.agrupar?.value)

        axios.post(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/reporte/relatorio/`, bodyFormData, this.configMe).then(res => {
            const reportData = res.data.data ?? []
            const tiposGrafico = { 'linha': 'line', 'barra': 'bar' }
            const group = detalhesDefault.agrupar?.value

            charts.forEach(chart => {
                const gerarDadosDinamicos = this.gerarDadosDinamicos
                const chartRes = res.data?.graficos?.filter(grafico => grafico.id === chart.id)?.[0]
                let xAxis = []

                if (group === 'day' || group === 'month') {
                    xAxis = this.getDates(new Date(data_ini), new Date(data_fim), group)
                } else {
                    try {
                        const equipamento = chart?.campo_customizado?.equipamento__imei
                        Object.keys(reportData[equipamento]).forEach((key) => xAxis.push(key))
                    } catch (err) {
                        console.error(err)
                    }
                }

                const [chartData, chartXAxis] = this.generateChartSeries(chart, res.data, xAxis, group)

                chart['xAxis'] = chartXAxis
                chart['unidade'] = chartRes?.[0]?.unidade
                chart['sinal'] = chartRes?.sinal
                chart['series'] = []
                chart['resumos'] = res.data.resumos?.filter(res => String(res.grafico) === String(chart.id)) ?? []
                chart['referencias'] = res.data.referencias?.filter(ref => String(ref.grafico) === String(chart.id)) ?? []

                if (typeof chartData?.[0] === "object") {
                    let maior = [], menor = []
                    chartData?.forEach(data => {
                        maior.push(Number(data['maior']))
                        menor.push(Number(data['menor']))
                    })

                    chart['series'].push({
                        descricao: chart.descricao + ' (Mínimo)',
                        cor: "#03a5fc",
                        tipo_grafico: tiposGrafico[chart.tipo_grafico.value],
                        referencia: false,
                        data: menor
                    }, {
                        descricao: chart.descricao + ' (Máximo)',
                        cor: "#34bf57",
                        tipo_grafico: tiposGrafico[chart.tipo_grafico.value],
                        referencia: false,
                        data: maior
                    })
                } else {
                    chart['series'].push({
                        descricao: chart.descricao,
                        cor: chart.cor_linha,
                        tipo_grafico: tiposGrafico[chart.tipo_grafico.value],
                        referencia: false,
                        unidade: chartRes?.unidade,
                        data: chartData
                    })
                }

                chart['referencias'].forEach(ref => {
                    chart.series.push({
                        descricao: ref.descricao,
                        cor: ref.cor_linha,
                        tipo_grafico: 'line',
                        unidade: ref.unidade,
                        referencia: true,
                        data: function () {
                            let data = []
                            if (ref.tipo_da_referencia === 'fixa') {
                                data = new Array(chart.xAxis.length)
                                data.fill(ref?.data?.[0]?.valor, 0, chart.xAxis.length)
                            } else if (ref.tipo_da_referencia === 'automacao') {
                                let valor
                                try {
                                    valor = JSON.parse(ref.data?.[0].condicao)[0]?.valor
                                } catch (err) {
                                    valor = undefined
                                }

                                data = new Array(chart.xAxis.length)
                                data.fill(valor, 0, chart.xAxis.length)
                            } else if (ref.tipo_da_referencia === "dinamico") {
                                data = gerarDadosDinamicos(chart, ref, chart.xAxis, res.data)
                            }

                            return data
                        }(),
                    })
                })

                if (chart?.calcular_como.value === 'horimetro') {
                    if (chart?.series.length)
                        chart.series[0] = { ...chart.series[0], horimetro: true }
                }
            })

            this.setState({ charts, reporte: res.data, carregandoDados: false, relatorioGerado: true })
            this.context.set({ charts })
        })
            .catch(err => {
                console.error(err)
                this.setState({ carregandoDados: false, relatorioGerado: false, relatorioErro: true })
            })
    }

    gerarResumos = (resumos) => {
        if (!(resumos?.length > 0)) {
            return null
        }

        return (
            <div className="hdv-resumo-list">
                {resumos.map((resumo, index) => {

                    return (
                        <div className="hdv-resumo" key={index}>
                            <i className={resumo?.icone} />
                            <div id="content">
                                {resumo.descricao}
                                {resumo.resumo_pre_definido === 'min_med_max'
                                    ?
                                    (
                                        <div>
                                            <small><b>Max:</b> {Number(resumo?.data?.max).toFixed(2)}</small>
                                            <small><b>Med:</b> {Number(resumo?.data?.med).toFixed(2)}</small>
                                            <small><b>Min:</b> {Number(resumo?.data?.min).toFixed(2)}</small>
                                        </div>
                                    )
                                    : resumo.resumo_pre_definido === 'volume_total'
                                        ?
                                        <strong>{resumo.data}</strong>
                                        : resumo.resumo_pre_definido === 'custo'
                                            ?
                                            <>
                                                <strong>{resumo.data}</strong> R$
                                            </>
                                            :
                                            null
                                }
                                <small>{resumo.campo_referencia}</small>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        )
    }

    gerarReferencias(referencias) {
        const condicoes = {
            'menor_igual': '<=',
            'maior_igual': '>=',
            'menor': '<',
            'maior': '>',
            'igual': '=',
            'diferente': '!=',
        }

        if (referencias?.length > 0) {
            return (
                <details className="hdv-chart-refs">
                    <summary>Referências</summary>
                    <section style={{ padding: '8px 0' }}>
                        {referencias?.map((ref, index) => {
                            if (ref.tipo_da_referencia === 'automacao') {
                                let condicao = null

                                try {
                                    condicao = JSON.parse(ref?.data?.[0]?.condicao)[0]
                                } catch (err) {
                                    condicao = null
                                }

                                return (
                                    <div className="hdv-ref-item" key={index}>
                                        <strong>{ref.descricao}</strong><br />
                                        {condicao
                                            ?
                                            <>
                                                <span>
                                                    Condição: {`${condicao.campo} ${condicoes[condicao.condicao]} ${condicao.valor}`}
                                                </span>
                                                <br />
                                            </>
                                            :
                                            null
                                        }
                                        <span className="d-flex align-items-center">
                                            Cor: <div id="ref-line-color" style={{ backgroundColor: `${ref.cor_linha}` }}></div>
                                        </span>
                                        <span>Tipo: {ref.tipo_da_referencia}</span>
                                    </div>
                                )
                            }
                            else if (ref.tipo_da_referencia === 'dinamico') {
                                return (
                                    <div className="hdv-ref-item" key={index}>
                                        <strong>{ref.descricao}</strong><br />
                                        <span className="d-flex align-items-center">
                                            Cor: <div id="ref-line-color" style={{ backgroundColor: `${ref.cor_linha}` }}></div>
                                        </span>
                                        <span>Parâmetro: {ref?.campo_customizado?.descricao}</span><br />
                                        <span>Tipo: {ref.tipo_da_referencia}</span>
                                    </div>
                                )
                            } else {
                                const valor = ref.data?.[0]?.valor
                                return (
                                    <div className="hdv-ref-item" key={index}>
                                        <strong>{ref?.descricao}</strong><br />
                                        <span>Valor {valor}</span><br />
                                        <span className="d-flex align-items-center">
                                            Cor: <div id="ref-line-color" style={{ backgroundColor: `${ref?.cor_linha}` }}></div>
                                        </span>
                                        <span>Tipo: {ref?.tipo_da_referencia}</span>
                                    </div>
                                )
                            }
                        })}
                    </section>
                </details>
            )
        } else return null
    }

    formatTooltip = (params, mascara) => {
        if (Array.isArray(params)) {
            let formatted = ""

            for (let i = 0; i < params.length; i++) {
                let firstValue = Number(Number(params[i].data).toFixed(2)).toLocaleString("pt-BR")
                let value = mascara === "padrao" ? firstValue : mascaraParametrizacao[mascara](firstValue)

                formatted += `<div style="display: flex; align-items: center;"><div style="background-color: ${params?.[i]?.color}; height: 14px; width: 14px; border-radius: 7px; margin-right: 5px"></div>`
                formatted += `${params[i]?.seriesName}:&nbsp; <b>${value}</b>`
                formatted += (i !== (params.length - 1)) ? "</div>\n" : "</div>"
            }

            return formatted
        }
        return ""
    }

    decimalToTime = (data, group) => {
        if (group === "hour" && data > 1) {
            data = 1
        }

        let decimal = Number("0." + String(data).split('.')[1])
        let hours = data.toString().split('.')[0]
        let minutes = decimal * 60
        let seconds = Number("0." + String(minutes).split('.')[1]) * 60
        minutes = String(minutes).split('.')[0]

        if (isNaN(hours)) hours = "00"
        else if (String(hours).length === 1) hours = "0" + hours

        if (isNaN(minutes)) minutes = "00"
        else if (String(minutes).length === 1) minutes = "0" + minutes

        if (isNaN(seconds)) seconds = "00"
        else if (String(seconds).length === 1) seconds = "0" + seconds

        return `${hours}:${minutes}:${String(seconds).includes('.') ? Number(seconds).toFixed(0) : seconds}`
    }

    gerarGrafico = () => {
        const { charts } = this.context
        const { detalhesDefault } = this.state
        const axisType = { day: 'Dias', month: 'Meses', hour: 'Horas' }
        let generatedCharts = []
        let legend = []

        charts.forEach((chart, index) => {
            let series = []

            series = chart?.series?.map((serie, index) => {
                if (serie.data.length > 0) {
                    legend.push(serie.descricao + (serie.unidade ? ` (${serie.unidade})` : ''))

                    let label = {
                        show: true,
                        position: 'top',
                        color: "rgba(0, 0, 0, 0.85)",
                        fontWeight: '600',
                        textBorderColor: '#fff',
                        textBorderWidth: 2,
                        fontSize: 12,
                        distance: 5,
                        rotate: chart.xAxis.length > 20 ? 55 : 0,
                        formatter: (params) => !isNaN(params.value) ? Number(params.data).toLocaleString("pt-BR") : params.value
                    }

                    if (serie.horimetro) {
                        label['formatter'] = (params) => {
                            return this.decimalToTime(params.data, detalhesDefault.agrupar?.value)
                        }
                    }

                    let result = {
                        name: serie.descricao + (serie.unidade ? ` (${serie.unidade})` : ''),
                        data: serie.data,
                        type: serie.tipo_grafico,
                        itemStyle: { color: serie.cor, opacity: serie.referencia ? 0.85 : 1 },
                        lineStyle: { width: serie.referencia ? 2 : 3, opacity: serie?.referencia ? 0.85 : 1 },
                        labelLayout: { hideOverlap: true },
                        showSymbol: !serie.referencia,
                    }

                    if (serie.referencia) {
                        result.lineStyle.type = "dashed"
                        result.markPoint = {
                            data: [
                                {
                                    coord: [0, serie.data[0]],
                                    symbol: 'circle',
                                    symbolSize: 10,
                                    itemStyle: { color: serie.cor },
                                    label: {
                                        show: true,
                                        position: 'top',
                                        fontWeight: 'bold',
                                        formatter: (params) => Number(params.data.coord[1]).toLocaleString("pt-BR")
                                    }
                                },
                                {
                                    coord: [serie.data.length - 1, serie.data[serie.data.length - 1]],
                                    symbol: 'circle',
                                    symbolSize: 10,
                                    itemStyle: { color: serie.cor }
                                }
                            ]
                        }
                    } else {
                        result["label"] = label
                    }

                    return result
                }
                return null
            }).filter(s => s !== null)

            generatedCharts.push(
                <div key={index} className="chart-item" style={{ padding: '3px' }} id={`chart-id-${chart.id}`}>
                    <h5>{chart.descricao} {chart.unidade ? `(${chart.unidade})` : ''}</h5>

                    {this.gerarResumos(chart.resumos)}

                    {this.gerarReferencias(chart.referencias)}

                    <div className="hdv-chart">
                        {series?.length > 0 ?
                            <ReactEcharts
                                style={{ height: '400px', width: '100%' }}
                                option={{
                                    series: series,
                                    xAxis: {
                                        data: chart.xAxis,
                                        name: chart?.visualizacao?.value == "tudo" ? null : axisType[detalhesDefault.agrupar?.value],
                                        nameTextStyle: { fontWeight: '600', fontSize: '12' },
                                        nameLocation: 'center',
                                        nameGap: 22
                                    },
                                    yAxis: {
                                        type: 'value',
                                        max: (value) => {
                                            if (value?.max < 0)
                                                return Math.ceil((value.max + (Math.abs(value.max) * 0.1)))
                                            return Math.ceil((value.max + (Math.abs(value.max) * 0.1)))
                                        },
                                        min: (value) => Math.floor(value.min - (Math.abs(value.min) * 0.6)),
                                    },
                                    tooltip: {
                                        show: true,
                                        trigger: "axis",
                                        axisPointer: { type: "cross", label: { show: true } },
                                        formatter: (params) => this.formatTooltip(params, chart.campo_customizado.mascara)
                                    },
                                    legend: { data: legend },
                                    grid: { left: 65, right: 50, bottom: 80 },
                                    dataZoom: [
                                        { type: 'inside', start: 0, end: 100 },
                                        {
                                            bottom: 0,
                                            brushSelect: false, start: 0, end: 10, handleSize: '100%',
                                            handleStyle: { borderWidth: '2', borderColor: 'rgba(0, 0, 0, 0.42)' }
                                        }
                                    ],
                                }}
                            />
                            :
                            <p style={{ margin: '12px 0', textAlign: 'center', color: '#a33131', fontWeight: 'bold' }}>
                                Erro ao carregar gráfico
                            </p>
                        }
                    </div>
                    <hr />
                </div>)
        })

        return this.state.carregandoDados ? null : generatedCharts
    }

    handleChangeRelatorio = (e, field) => {
        const { detalhesDefault } = this.state
        detalhesDefault[field] = e
        this.setState({ detalhesDefault })
    }

    hideRef = () => {
        let referencias = document.querySelectorAll('.hdv-chart-refs')
        let icones = document.querySelectorAll('.hdv-resumo i')
        referencias.forEach(ref => ref.hidden = true)
        icones.forEach(icone => icone.classList.add('hide-icon'))

        setTimeout(() => {
            referencias.forEach(ref => ref.hidden = false)
            icones.forEach(icone => icone.classList.remove('hide-icon'))
        }, 1000)
    }

    exportarPDF = async () => {
        const { charts } = this.context
        const { detalhesDefault } = this.state
        let canvas = document.querySelectorAll('.chart-item') ?? []
        let infoEmpresa
        let graficos = []
        this.setState({ exportCooldown: true })
        this.hideRef()

        canvas.forEach(async c => {
            let data
            const item = charts.find(chart => `chart-id-${chart.id}` === c.id)
            data = await domToImage.toPng(c)

            graficos.push({ ...item, dom: c, img: data })
        })

        await axios.get(cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/configuracao-sistema`, this.configMe)
            .then(res => {
                infoEmpresa = res.data?.results?.[0]
                if (detalhesDefault.cliente?.label && infoEmpresa) {
                    infoEmpresa.cliente = detalhesDefault.cliente.label.split(" - ")[0]
                    infoEmpresa.data = detalhesDefault.data.replace("-", " - ")
                }
            })

        const dadosEmpresa = []
        if (infoEmpresa?.email_contato) {
            dadosEmpresa.push(infoEmpresa.email_contato)
            dadosEmpresa.push('\n')
        }
        if (infoEmpresa?.endereco) {
            dadosEmpresa.push(infoEmpresa.endereco)
            dadosEmpresa.push('\n')
        }
        if (infoEmpresa?.telefone) {
            dadosEmpresa.push(infoEmpresa.telefone)
            dadosEmpresa.push('\n')
        }

        const pdfOptions = {
            content: [
                {
                    table: {
                        widths: ['*', 120],
                        body: [[
                            {
                                text: dadosEmpresa,
                                border: [false, false, false, true]
                            },
                            { image: 'logo', style: 'logo', width: 80, border: [false, false, false, true] }
                        ]]
                    },
                },
            ],
            images: { 'logo': document.querySelector('div.hdv-logomarca img')?.src, },
            styles: {
                logo: { margin: [40, -18, 70, 0] },
                info: { margin: [5, 8], fontSize: '11', color: '#444444' }
            }
        }

        graficos?.forEach(async (grafico, index) => {
            pdfOptions.images['chart_' + index] = grafico.img
            pdfOptions.content.push({
                image: 'chart_' + index,
                margin: [0, 20],
                width: 516,
            })
        })

        pdfMake.createPdf(pdfOptions).open({}, window.open('', '_blank'))
        this.setState({ exportCooldown: false })
    }

    resetarGrafico = () => {
        this.context.set({
            graficoModal: {
                ativo: true, novo: true,
                grafico: {
                    campo_customizado: '', descricao: '', tipo_grafico: '', campo_referencia: '', data: [],
                    cor_linha: '#4287f5', label: true, visualizacao: { label: 'Média do período', value: 'med' },
                    calcular_como: { label: 'Padrão', value: 'padrao' }
                },
            },
        })
    }

    selectChart = (chart) => {
        let { graficoModal } = this.context
        if (graficoModal.grafico) {
            graficoModal.grafico['valido'] = true
        }

        this.context.set({
            graficoModal: { ativo: true, grafico: chart },
            selected: { cliente: '', monitorado: '', campo: '' }
        })
    }

    render() {
        const { relatorioGerado, detalhesDefault, tabIndex, disabledCliente } = this.state
        const { charts } = this.context

        if (this.state.redirect)
            return <Redirect to="/relatorio-customizado" />

        return (
            <>
                <ResumoRelatorio detalhes={detalhesDefault} />

                <ReferenciaRelatorio detalhes={detalhesDefault} />

                <GraficoRelatorio detalhes={detalhesDefault} />

                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Relatório Customizado</h4>
                            <div className="gray-background">
                                <i className="fa fa-file-alt fa-2x"></i>
                            </div>
                        </span>
                        <ReactLoading
                            id="loading"
                            className={this.state.carregandoDados ? "" : "hdv-noshow-item"}
                            type="cylon"
                            color="#589bd4"
                            height={20}
                            width={50}
                        />
                    </div>

                    <Tabs
                        selectedIndex={this.state.tabIndex}
                        onSelect={(tabIndex) => this.setState({ tabIndex })}>
                        <TabList>
                            <Tab>Configuração</Tab>
                            <Tab>Gráficos</Tab>
                        </TabList>

                        <TabPanel>
                            <details open={true} className='custom-summary'>
                                <summary>Filtros</summary>
                                <div style={{ padding: "20px" }} className="row">
                                    <div className={this.userInfo?.tipo != "cliente" ? "col-md-8 pr-4" : "col-md-12"}>
                                        <label className="required">Descrição</label>
                                        <input
                                            type="text"
                                            onChange={(e) => this.handleChangeRelatorio(e.target.value, 'descricao')}
                                            value={detalhesDefault.descricao}
                                        /*disabled={this.state.cliente}*/
                                        />
                                        <span className={(this.state.validacao_formulario.descricao === false) ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>
                                            Campo Obrigatório
                                        </span>
                                    </div>

                                    {this.userInfo?.tipo != "cliente"
                                        ?
                                        <div className="col-md-4 pl-0">
                                            <label className="required mt-0">Cliente</label>
                                            <Select
                                                disabled={disabledCliente}
                                                value={detalhesDefault.cliente}
                                                onChange={(e) => this.handleChangeRelatorio(e, 'cliente',)}
                                                options={this.state.optionsCliente}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                    <HeaderCustomizado
                                        altForm={true}
                                        cliente={this.state.cliente}
                                        DataChange={this.state.detalhesDefault}
                                        change={(key, value) => this.setState({ detalhesDefault: { ...detalhesDefault, [key]: value } })}
                                        fn={this.buscarCanais}
                                        validacao={this.state.validacao_formulario}
                                    />
                                </div>
                            </details>
                        </TabPanel>
                        <TabPanel>
                            <button
                                style={{ position: "relative", width: "100%", height: "50px", background: "var(--fonte_titulo_abas)" }}
                                className="hdv-btn-forms hdv-btn-green"
                                onClick={() => {
                                    this.resetarGrafico()
                                    this.getResource()
                                }}>
                                Novo Gráfico
                            </button>

                            {this.context.charts.length > 0 ?
                                this.context.charts?.map((chart) => (
                                    <details style={{ margin: "10px 0px" }} key={chart.id} className="hdv-relatorio-chart" open={false}>
                                        <summary style={{ backgroundColor: "#eeeeee", padding: "10px", borderRadius: "5px" }}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i
                                                        style={{ fontSize: "20px" }}
                                                        className={chart.tipo_grafico.value === "linha" ? "fa fa-chart-line mr-1" : "fa fa-chart-bar mr-1"}></i>
                                                    <span>{chart.descricao}</span>
                                                </div>
                                                {this.props.cliente
                                                    ?
                                                    null
                                                    :
                                                    <div className="row" style={{ marginRight: '6px' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <button
                                                                onClick={() => { this.selectChart(chart); this.getResource() }}
                                                                style={{ color: '#333', width: '60px', border: '1px solid #cacaca' }}
                                                                className="hdv-chart-edit hdv-automacao-delete-button d-flex align-items-center">
                                                                <i className="fa fa-pen" style={{ fontSize: '22px' }}></i> Editar
                                                            </button>

                                                            <button
                                                                onClick={() => this.context.confirmDelete(chart, 'grafico')}
                                                                style={{ color: '#fff', width: '65px' }}
                                                                className="hdv-chart-close  hdv-btn-red hdv-automacao-delete-button d-flex align-items-center">
                                                                <i className="fa fa-times" style={{ fontSize: '22px' }}></i> Deletar
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </summary>
                                        <div style={{ display: 'flex' }}>
                                            <div id="hdv-content">
                                                <strong>Configurações</strong>
                                                <span>Descrição: {chart.descricao}</span>
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                    Cor: <div style={{ width: '16px', height: '16px', backgroundColor: `${chart.cor_linha}`, margin: '0 4px', borderRadius: '20px' }}></div>
                                                </span>
                                                <span>Parâmetro: {chart?.campo_customizado?.descricao} ({chart?.campo_customizado?.identificacao})</span>
                                                <span>Tipo: {chart.tipo_grafico.label}</span>
                                            </div>
                                            {this.context.referencias?.filter(ref => ref.grafico === chart.id).length > 0
                                                ?
                                                <div id="hdv-content">
                                                    <strong>Referências</strong>
                                                    {this.context.referencias?.filter(ref => ref.grafico === chart.id)?.map((ref, index) => (
                                                        <span style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                            <div style={{ width: '16px', height: '16px', backgroundColor: `${ref?.cor_linha}`, margin: '0 4px', borderRadius: '20px' }}></div>
                                                            <span>{ref?.descricao}</span>
                                                        </span>
                                                    ))}

                                                </div>
                                                :
                                                null}
                                            {this.context.resumos?.filter(res => res.grafico === chart.id).length > 0
                                                ?
                                                <div id="hdv-content">
                                                    <strong>Resumos</strong>
                                                    {this.context.resumos?.filter(res => res.grafico === chart.id)?.map((res, index) => (
                                                        <span style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                            {res?.icone ? <i className={res.icone.value} style={{ fontSize: '24px' }} /> : null}
                                                            <span>{res?.descricao}</span>
                                                        </span>
                                                    ))}

                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </details>
                                ))
                                :
                                <div style={{ minHeight: "150px" }}>
                                    <p style={{ textAlign: 'center', marginTop: '30px', opacity: '0.8' }}>
                                        Sem gráficos cadastrados
                                    </p>
                                </div>
                            }
                        </TabPanel>
                    </Tabs>

                    <div style={{ display: 'flex', justifyContent: tabIndex === 1 ? 'end' : 'space-between' }}>
                        <div style={{ display: tabIndex === 1 ? "none" : 'block' }} className="hdv-btn-group">
                            <button
                                onClick={this.buscarDadosRelatorio}
                                className="hdv-btn-forms hdv-btn-blue"
                                disabled={this.state.carregandoDados || charts?.length === 0}>
                                Gerar Relatório
                            </button>

                            <button
                                onClick={this.exportarPDF}
                                className="hdv-btn-forms hdv-btn-red"
                                disabled={!(this.state.relatorioGerado && this.state.exportCooldown === false)}>
                                Exportar PDF
                            </button>
                        </div>

                        <div className="hdv-btn-group">
                            <ButtonsForm
                                route="/relatorio"
                                edit={this.props.match.params.edit ? true : false}
                                cooldown={this.state.cooldown || this.state.relatorioErro}
                                buttons={[
                                    {
                                        method: "post",
                                        button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                                    },
                                    {
                                        method: "patch",
                                        button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green">Salvar</button>
                                    },
                                ]}
                            />
                            <Link to="/relatorio-customizado">
                                <button disabled={this.state.cooldown} style={{ margin: "0px" }} className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                            </Link>
                        </div>
                    </div>

                    {relatorioGerado ?
                        <>
                            <div className="col-md-12 p-0" style={{ padding: '8px 10px', marginTop: '15px' }}>
                                {this.gerarGrafico()}
                            </div>
                        </>
                        : null
                    }

                    {this.state.relatorioErro
                        ?
                        <p style={{ margin: '12px 0', textAlign: 'center', color: '#a33131', fontWeight: 'bold' }}>
                            Erro ao carregar relatório
                        </p>
                        :
                        null
                    }
                </div>
            </>
        )
    }
}

export default Form


