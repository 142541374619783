import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import ConfirmDelete from '../../components/ConfirmDelete'
import ButtonsGrid from '../../components/ButtonsGrid'
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'


class Grid extends Component {
    state = {
        data: [],
        loading: true,
        redirectToEdit: false,
        table: null
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/mira/?limit=' + state?.lineCount + state.filter

        if(userInfo?.cliente) {
            url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/mira/?monitorado__cliente_id=${userInfo?.cliente}&limit=`+ state?.lineCount + state.filter
        } else {
            url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/mira/?limit=' + state?.lineCount + state.filter
        }

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            this.setState({
                loading: false,
                data: res.data.results,
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous
            })
        })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        this.setState({ loading: true, enableDeletar: false, enableEditar: false })

        this.state?.table?.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/mira/${value}/`, config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state?.table?.fetch()
            this.state?.table?.unselect()
        }))
    }

    editar = () => {
        let toGo = "mira/" + this.state?.table?.state.selected?.[0] + "/editar"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    convertDate = (date) => new Date(Date.parse(date)).toLocaleDateString('pt-BR',
        { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    )

    relatorio = () => {
        let toGo = "mira/" + this.state?.table?.state.selected?.[0] + "/relatorio"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    render() {
        const { redirectToEdit, table } = this.state
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: 'Descrição',
            Accessor: 'descricao'
        }, {
            Header: 'Criado em',
            Accessor: 'data_criado',
            sortable: false,
            Cell: (original) => {
                return <span>{this.convertDate(original.data_atualizado)}</span>
            }
        }, {
            Header: 'Atualizado em',
            Accessor: 'data_atualizado',
            sortable: false,
            Cell: (original) => {
                return <span>{this.convertDate(original.data_atualizado)}</span>
            }
        }, {
            Header: 'Rótulo',
            Accessor: 'rotulo',
        }]

        return (
            <>
                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Cadastro Mira</h4>
                            <div className="gray-background">
                                <i className="fa fa-network-wired fa-2x"></i>
                            </div>
                        </span>
                    </div>
                    <div className="hdv-btn-group">
                        <ButtonsGrid 
                            route="/mira"
                            buttons={[
                                {
                                    method: "post",
                                    button: <Link to={'/mira/novo'}><button className="hdv-btn-forms hdv-btn-green ">Novo</button></Link>
                                },

                                {
                                    method: "patch",
                                    button: <button onClick={table?.state.enableEditar ? this.editar : null} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                                },
                                
                                {
                                    method: "patch",
                                    button: <button onClick={table?.state.enableEditar ? this.relatorio : null} className={"hdv-btn-forms hdv-btn-blue " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Relatório</button>
                                },

                                {
                                    method: "delete",
                                    button: <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar} />
                                }
                            ]}
                        />
                    </div>

                    <Table
                        checkbox
                        data={this.state.data}
                        loading={this.state.loading}
                        count={this.state.count}
                        next={this.state.next}
                        previous={this.state.previous}
                        columns={columns}
                        onFetchData={this.getData}
                        onUpdate={(table) => this.setState({ table })}
                    />
                </div>
            </>
        )
    }
}

export default Grid