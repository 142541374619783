import { Fragment } from "react"

export default function Stepper(props) {

    const handleTab = (direction) => {
        let { activeStep, steps } = props

        if (direction && activeStep < (steps.length - 1)) {
            activeStep = activeStep + 1
        } else if (!direction && activeStep >= 1) {
            activeStep = activeStep - 1
        }

        props?.onChange(activeStep)
    }

    return (
        <>
            <div className={props.className ? `afira-stepper ${props.className}` : "afira-stepper"}>
                <button onClick={() => handleTab(false)} className="outline-btn" disabled={props.activeStep === 0}>
                    <i className="fa fa-angle-left fa-2x"></i>
                </button>
                {props.steps?.map((step, index) => (
                    <Fragment key={index}>
                        {index != 0
                            ?
                            <span style={{ backgroundColor: props.activeStep >= index ? "var(--background-menu) " : "#888" }} className="afira-stepper-line"></span>
                            :
                            null
                        }
                        <div
                            key={index}
                            className="afira-step"
                            title={index + 1}
                            onClick={() => props?.onChange(index)}
                            data-active-step={props.activeStep >= index ? "on" : "off"}>
                            <span>{step}</span>
                        </div>
                    </Fragment>
                ))}
                <button onClick={() => handleTab(true)} className="outline-btn" disabled={props.activeStep === props.steps.length - 1}>
                    <i className="fa fa-angle-right fa-2x"></i>
                </button>
            </div>
        </>
    )
}
