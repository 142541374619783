import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import auth from '../../utils/auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route
        {...rest}
        render={(props) => {
            if (rest?.required) {
                const myModules = auth.getUserInfo()?.menu?.map((modulo => modulo.item)) ?? []
                if (!myModules.includes(rest?.required)) {
                    return null
                }
            }

            return auth.getToken() !== null
                ?
                (<Component {...props} />)
                :
                (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
        }}
    />
}

export default PrivateRoute