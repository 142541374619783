import React, { Component } from "react"
import Table from "../../components/Table"
import Select from "../../components/Select"
import { Badge, ModalFooter, Modal, ModalHeader, ModalBody } from "reactstrap"
import auth from "../../utils/auth"
import cfg from "../../utils/config"
import helper from "../../utils/helper"
import { Link } from "react-router-dom"
import axios from "axios"

class GerenciarAcessos extends Component {
    state = {
        data: [],
        loading: true,
        optionsEmpresa: [],
        acessosSelecionados: [],
        detalhes: { empresa: null },
        modal_status: false,
        modal_status_acessos: false,
        dataTableAcesso: []
    }

    componentDidMount() {
        this.getEmpresas(true, false)
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/acesso/?limit=${state.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {

            this.setState({
                loading: false,
                data: res.data.results,
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous,
            })
        })
    }

    getEmpresas = (createOptions, dataTableAcesso) => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/acesso-quantidade-empresa/"

        axios.get(url, config_me) 
        .then((res) => {
            const empresas = res.data

            if(createOptions) {
                this.createOptionsEmpresa(empresas)
            }
           
            if(dataTableAcesso) {
               this.setState({dataTableAcesso: empresas})
            }
            
        })
    }

    createOptionsEmpresa = (empresas) => {
        let optionsEmpresa = []

        empresas.forEach(empresa => {
            optionsEmpresa.push({label: empresa.empresa_nome, value: empresa.empresa_id})    
        })

        this.setState({optionsEmpresa})
    }

    changeAcessos = (checked, id) => {
        let acessos = [...this.state.acessosSelecionados]

        if(checked) {
            acessos.push(id)
        } else {
            acessos = acessos.filter((id_acesso) => id_acesso != id) 
        }

        this.setState({acessosSelecionados: acessos})
    }

    changeEmpresa = (field, value) => {
        const detalhes = Object.assign({}, this.state.detalhes)
        detalhes[field] = value
        this.setState({detalhes})

        this.toggleModal(true, "default")
    }
    
    clearState = () => {
        this.setState({
            acessosSelecionados: [],
            detalhes: { empresa: null }
        })
    }

    migrar = () => {
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + "/acesso/migrar/" + `${this.state.detalhes.empresa.value}/`

        const data = {
            acessos: this.state.acessosSelecionados
        }
    
        axios({
            url: url,
            method: "POST",
            data: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((res) => {
            this.toggleModal(false, "default")
            this.clearState()
            this.state.table.fetch()
        
            helper.dispatchEvent("showAviso", {
                message: res.data
            })
        })
        .catch((error) => {
            this.toggleModal(false, "default")

            helper.dispatchEvent("showAviso", {
                message: error.response.data
            })
        })
    }

    toggleModal = (status, modal) => {

        if(modal == 'default') {
            this.setState({modal_status: status})
        } else if(modal == "acessos") {
            this.setState({modal_status_acessos: status, dataTableAcesso: []})

            if(status) {
                this.getEmpresas(false, true)
            }
        }
       
    }

    render() {
        const { optionsEmpresa, acessosSelecionados, modal_status, detalhes, modal_status_acessos, dataTableAcesso } = this.state

        const columns = [
            {
                Header: "Selecionar Acesso",
                Accessor: "",
                Cell: (original) => {
                    if(acessosSelecionados.length == 0) {
                        return <input checked={false} onChange={(e) => this.changeAcessos(e.target.checked, original.id)} type="checkbox"/>
                    } else {
                        return <input onChange={(e) => this.changeAcessos(e.target.checked, original.id)} type="checkbox"/>
                    }
                   
                }
            },
            {
                Header: "Descrição do Acesso",
                Accessor: "descricao"
            },
            {   
                Header: "Empresa",
                Accessor: "empresa_nome_fantasia"
            }, {
                Header: 'Equipamento',
                Accessor: 'equipamento.imei',
                Cell: (original) => {
                    if (original?.equipamento?.imei) {
                        return<Link to={`/equipamento/${original.equipamento.id}/editar`}>{original?.equipamento?.imei}</Link>
                    } else {
                        return <span>---</span>
                    }
                }
            }, {
                Header: 'Data de Criação',
                Accessor: 'data_criado',
                filterable: false,
                Cell: (original) => {
                    if (original.data_criado) {
                        let date = new Date(Date.parse(original.data_criado))
                        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                        date = new Intl.DateTimeFormat('pt-Br', options).format(date).replace(",", "")
                        return <span>{date}</span>
                    } else {
                        return <span>---</span>
                    }
                }
            }, {
                Header: 'Data do Cancelamento',
                Accessor: 'data_cancelamento',
                filterable: false,
                Cell: (original) => {
                    if (original.data_cancelamento) {
                        let date = new Date(Date.parse(original.data_cancelamento))
                        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }

                        return <span>{new Intl.DateTimeFormat('pt-Br', options).format(date)}</span>
                    } else {
                        return <span>---</span>
                    }
                }
            }, {
                Header: 'Ativo',
                Accessor: 'ativo',
                sortable: false,
                Filter: ({ filter, onChange }) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}>
                        <option value="">Todos</option>
                        <option value="true">Ativo</option>
                        <option value="false">Desativado</option>
                    </select>
                ),
                Cell: (original) => {
                    return original.ativo ?
                        <Badge href="#" color="success">Sim</Badge>
                        :
                        <Badge href="#" color="secondary">Não</Badge>
                }
            }
        ]

        return(
            <>
                <Modal toggle={() => this.toggleModal(false, "default")} isOpen={modal_status}>
                    <ModalHeader toggle={() => this.toggleModal(false, "default")}>Confirmar Migração</ModalHeader>
                    <ModalBody>
                        Tem certeza que deseja realizar a migração dos acessos selecionados para a empresa <span style={{fontWeight: "bold", fontSize: "15px"}}>{detalhes.empresa?.label}</span> ?
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={this.migrar} className="hdv-btn-forms hdv-btn-green">Confirmar</button>
                        <button onClick={() => this.toggleModal(false, "default")} className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                    </ModalFooter>
                </Modal>
        
                <Modal toggle={() => this.toggleModal(false, "acessos")}  isOpen={modal_status_acessos}>
                    <ModalHeader toggle={() => this.toggleModal(false, "acessos")} >Acessos</ModalHeader>
                    <ModalBody>
                        <table className="afira-table">
                            <thead>
                                <tr>
                                    <th>Empresa</th>
                                    <th>Status</th>
                                    <th>Utilizados</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataTableAcesso.map((acesso, key) => {
                                        return (
                                            <tr key={key}>
                                                <td title={acesso.empresa_nome}>{acesso.empresa_nome}</td>
                                                <td title={acesso.disponiveis}>
                                                    <progress style={{ width: "80px" }} className={acesso.quantidade_acessos === acesso.utilizados ? "progressMax" : ""} value={acesso.utilizados} max={acesso.quantidade_acessos}>
                                                    </progress>
                                                </td>
                                                <td title={acesso.utilizados}>{acesso.utilizados}</td>
                                                <td title={acesso.quantidade_acessos}>{acesso.quantidade_acessos}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </ModalBody>    
                </Modal>

                <div style={{alignItems: "center"}} className='row'>
                    <div className='col-md-3'>
                        <Select
                            value={detalhes.empresa}
                            onChange={(e) => this.changeEmpresa('empresa', e)} 
                            options={optionsEmpresa}
                            disabled={acessosSelecionados.length == 0}
                        />
                    </div>
                    <div>
                        <button onClick={() => this.toggleModal(true, "acessos")} className="hdv-btn-forms hdv-btn-blue">Ver Acessos</button>
                    </div> 
                </div>

                <Table
                    notEditFirstColum={true}
                    data={this.state.data}
                    loading={this.state.loading}
                    next={this.state.next}
                    count={this.state.count}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />
            </>
        )
    }
}

export default GerenciarAcessos