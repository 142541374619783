import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import ConfirmDelete from '../../components/ConfirmDelete'
import ButtonsGrid from '../../components/ButtonsGrid'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import Table from '../../components/Table'
import helper from '../../utils/helper'
import axios from 'axios'


class Grid extends Component {

    state = {
        data: [],
        loading: true,
        table: null,
        redirectToEdit: false
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/empresa/?limit=' + state?.lineCount + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            this.setState({
                loading: false,
                data: res.data.results,
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous,
            })
        })
    }

    deletar = async () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        this.setState({ loading: true, enableDeletar: false, enableEditar: false })

        this.state.table?.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/empresa/' + value + '/', config_me))
        )

        await axios.all(requestArray).catch((error) => {
            helper.dispatchEvent("showAviso", { message: error.response.data.detail })
        })

        this.state.table?.fetch()
        this.state.table?.unselect()
    }

    editar = () => {
        this.setState({
            redirectToEdit: { pathname: "empresa/" + this.state.table?.state.selected[0] + "/editar" }
        })
        this.state.table?.unselect()
    }

    render() {

        const { redirectToEdit, table } = this.state;
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />;
        }

        const columns = [{
            Header: 'Nome Fantasia',
            Accessor: 'nome_fantasia'
        }, {
            Header: 'Razão Social',
            Accessor: 'razao_social'
        }, {
            Header: 'Pessoa',
            Accessor: 'pessoa_tipo',
            Cell: (original) => (
                <div style={{ textTransform: "capitalize" }}>
                    {original.pessoa_tipo}
                </div>
            )
        }, {
            Header: 'CPF/CNPJ',
            Accessor: 'cpf_cnpj'
        }, {
            Header: 'Situação',
            Accessor: 'status',
        }]

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Cadastro de Empresas</h4>
                        <div className="gray-background">
                            <i className="fa fa-building fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsGrid
                        route="/empresa"
                        buttons={[
                            {
                                method: "post",
                                button: <Link to="/empresa/novo"><button className="hdv-btn-forms hdv-btn-green ">Novo</button></Link>
                            },

                            {
                                method: "patch",
                                button: <button onClick={() => { if (table?.state.enableEditar) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                            },

                            {
                                method: "delete",
                                button: <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar} />
                            }
                        ]}
                    />
                </div>
                <Table
                    checkbox
                    data={this.state.data}
                    loading={this.state.loading}
                    count={this.state.count}
                    next={this.state.next}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />
            </div>
        )
    }
}

export default Grid
