import React, { Component } from "react"

class ModalIcone extends Component {
    handleClick = (e) => {
        if (e.target.classList.contains("background-modal")) {
            this.props?.toggle()
        }
    }

    render() {
        return (
            <>
                <div
                    onClick={this.handleClick}
                    className="background-modal" style={{ display: this.props.open ? "block" : "none" }}>
                    <div className="hdv-icons-list" style={{ left: "0px" }}>
                        <div><h2>Selecione um Ícone</h2></div>
                        <ul>
                            {this.props.children}
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default ModalIcone