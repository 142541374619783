import React, { Component } from "react";
import Select from "../../components/Select";
import { Link, Redirect } from 'react-router-dom'
import { JSONTree } from 'react-json-tree'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from "../../utils/helper";
import axios from "axios";

const today = new Date();
const dataAtual = today.toISOString().slice(0, 10)
const horaAtual = today.toLocaleTimeString('pt-BR')

class EquipamentoVirtual extends Component {
    
    state = {
        detalhes: { parametro: {}, data: dataAtual, hora: horaAtual },
        parametros: {},
        optionsParametros: [],
        optionsBooleano: [{label: "Falso", value: "False"}, {label: "Verdadeiro", value: "True"}],
        cooldown: false,
        redirect: false,
        path: ""
    }

    componentDidMount() {
        this.getParametros()
    }

    getParametros = () => {
        const userInfo = auth.getUserInfo()
        const imei = this.props.match.params.imei
        const config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/lancamento-manual-dados/${imei}/`, config_me)
        .then((res) => {
            let parametros = {}
            let optionsParametros = []
            let valores = {
                inteiro: 0,
                decimal: 0,
                string: "",
                booleano: "False"
            }

            res.data.forEach(parametro => {
                optionsParametros.push({label: parametro.label, value: parametro.key, type: parametro.type})
                parametros[parametro.key] = parametro.value ?? valores[parametro.type]
            })

            this.setState({optionsParametros, parametros})
        })
    }

    handleChange = (field, value) => {
        const detalhes = Object.assign({}, this.state.detalhes)
        detalhes[field] = value
        this.setState({ detalhes })
    }

    changeParametros = (field, value) => {
        const parametros = Object.assign({}, this.state.parametros)
        parametros[field] = value
        this.setState({ parametros })
    }

    saveAction = () => {
        const { detalhes } =  this.state
        const parametros = Object.assign({}, this.state.parametros)
        const imei = this.props.match.params.imei
        const userInfo = auth.getUserInfo()
        const dateString = detalhes.data + " " + detalhes.hora
        const timestamp = new Date(dateString).getTime()
        
        this.setState({ cooldown: true })

        parametros["timestamp_dispositivo"] = timestamp

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/lancamento-manual-dados/${imei}/`,
            data: JSON.stringify(parametros),
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
        .then((response) => {
            helper.dispatchEvent("showAviso", {
                message: `Lançamento realizado com sucesso.`,
                callback: () => this.setState({redirect: true, path: "/virtual"})
            })
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    render() {
        const { optionsParametros, optionsBooleano, detalhes, cooldown, parametros } = this.state

        if (this.state.redirect) return <Redirect to={this.state.path} />

        const theme = {
            scheme: 'monokai',
            author: 'wimer hazenberg (http://www.monokai.nl)',
            base00: '#F2F2F2',
            base01: '#212529',
            base02: '#212529',
            base03: '#212529',
            base04: '#212529',
            base05: '#212529',
            base06: '#212529',
            base07: '#212529',
            base08: '#212529',
            base09: '#212529',
            base0A: '#212529',
            base0B: '#212529',
            base0C: '#212529',
            base0D: '#212529',
            base0E: '#212529',
            base0F: '#212529',
        }

        return(
            <>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Reporte Manual - Equipamento Virtual</h4>
                        <div className="gray-background">
                            <i className="fa fa-mobile fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div style={{alignItems: "baseline"}} className="row">

                    <form className="hdv-form-default col-md-8"> 
                        <div className="row">
                            <div className="col-md-3">
                                <label className="required">Parâmetros</label>
                                <Select
                                    onChange={(e) => this.handleChange("parametro", e)}
                                    options={optionsParametros}
                                    value={detalhes.parametro}
                                />
                            </div>
                            <div className="col-md-2">
                                <label className="required">Tipo</label>
                                <input disabled type="text" value={detalhes.parametro?.type}/>
                            </div>
                            <div className="col-md-3">
                                <label className="required">Valor</label>
                                
                                {
                                    detalhes.parametro?.type === "decimal" || detalhes.parametro?.type === "inteiro" ?
                                        <input 
                                            onChange={(e) => this.changeParametros(detalhes.parametro.value, Number(e.target.value))} 
                                            type="number"
                                            disabled={detalhes.parametro.value ? false : true}
                                            value={parametros[detalhes.parametro.value] ?? 0}
                                        />
                                    : detalhes.parametro?.type === "booleano" ?
                                        <Select
                                            onChange={(e) => this.changeParametros(detalhes.parametro.value, e.value)}
                                            options={optionsBooleano}
                                            disabled={detalhes.parametro.value ? false : true}
                                            value={parametros[detalhes.parametro.value] === "True" ? optionsBooleano[1] : optionsBooleano[0]}
                                        />
                                    : 
                                        <input 
                                            onChange={(e) => this.changeParametros(detalhes.parametro.value, e.target.value)} 
                                            type="text"
                                            disabled={detalhes.parametro.value ? false : true}
                                            value={parametros[detalhes.parametro.value] ?? ""}
                                        />
                                }
                                
                            </div>
                            <div className="col-md-2">
                                <label className="required">Data</label>
                                <input value={detalhes.data} onChange={(e) => this.handleChange("data", e.target.value)} type="date"/>
                            </div>
                            <div className="col-md-2">
                                <label className="required">Hora</label>
                                <input style={{width: "100%"}} value={detalhes.hora} onChange={(e) => this.handleChange("hora", e.target.value)} type="time"/>
                            </div>
                        </div>
                    </form>

                    <div className="col-md-4">
                        <JSONTree
                            data={parametros}
                            labelRenderer={([key]) => <span>{key}: </span>}
                            hideRoot={true}
                            theme={theme}
                            valueRenderer={(valueAsString, value, ...keyPath) => (
                                <button
                                    style={{ backgroundColor: "#44bb52", color: "white", borderRadius: "5px" }}>
                                    {valueAsString}
                                </button>
                            )}
                        />
                    </div>
                </div>

                <div className="hdv-btn-group">
                    <button disabled={cooldown} onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-green">Realizar lançamento</button>
                    <Link to="/virtual">
                        <button disabled={cooldown} className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>
            </>
        )
    }
}

export default EquipamentoVirtual