import React, { Component } from "react";

class ConfiguracoesGrupos extends Component {
    state = {
        formularioGrupo: { descricao: "", colspan: 0, th_colspan: 0 },
        validacao: {descricao: true},
        keyEdit: false
    }

    handleChangeInputSelect = (field, selectedOption) => {
        let formularioGrupo = Object.assign({}, this.state.formularioGrupo)

        formularioGrupo[field] = selectedOption

        this.setState({ formularioGrupo })
    }

    validacao = () => {
        let { formularioGrupo } = this.state
        let validacao = Object.assign({}, this.state.validacao)
        let erro = formularioGrupo.descricao === ""

        validacao['descricao'] =  !erro

        this.setState({ validacao })

        if (erro) return

        this.adicionarGrupo(this.state.keyEdit)
    }

    adicionarGrupo = (keyEdit) => {
        let formularioGrupo = Object.assign({}, this.state.formularioGrupo)
        let estrutura = Object.assign({}, this.props.estrutura)

        let value = { descricao: formularioGrupo.descricao, colspan: formularioGrupo.colspan, th_colspan: formularioGrupo.th_colspan }
    
        if (keyEdit !== false) {
            estrutura.grupos[keyEdit] = value
        } else {
            estrutura.grupos.push(value)
        }

        this.props.setEstrutura(estrutura)
        this.setState({ 
            formularioGrupo: { descricao: "", colspan: 0, th_colspan: 0 }, 
            keyEdit: false 
        })
    }

    editarGrupo = (key) => {
        let grupo = this.props.estrutura.grupos.filter((grupo, index) => index === key)[0]

        this.setState({ formularioGrupo: grupo, keyEdit: key })
    }

    excluirGrupo = (key) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        let grupos = this.props.estrutura.grupos.filter((grupo, index) => index != key)

        estrutura.grupos = grupos

        this.props.setEstrutura(estrutura)
    }

    render() {
        const { formularioGrupo, validacao, keyEdit} = this.state
        const {styleFieldset, estrutura} = this.props

        return(
            <details style={ styleFieldset.fieldset } open={true} className="custom-summary">
                <summary>Configurações dos grupos</summary>
                <div style={{padding: "15px 0px"}}>
                    <div style={{margin: "0px"}} className="row">
                        <div className="col-md-3">
                            <label className="required">Nome do grupo</label>
                            <input value={formularioGrupo.descricao} onChange={(e) => this.handleChangeInputSelect("descricao", e.target.value)} type="text" />
                            {!validacao.descricao ? <span id="validacao-descricao">Campo Obrigatório</span> : null}
                        </div>
                        <div className="col-md-3">
                            <label className="required">Quantidade de colunas</label>
                            <input min={0} value={formularioGrupo.th_colspan} type="number" onChange={(e) => this.handleChangeInputSelect('th_colspan', Number(e.target.value))} />
                            {/*!validacao.th_colspan ? <span id="validacao-th_colspan">Campo Obrigatório</span> : null*/}
                        </div>
                        <div className="col-md-2">
                            <label className="required">Ignorar colunas</label>
                            <input min={0} value={formularioGrupo.colspan} type="number" onChange={(e) => this.handleChangeInputSelect('colspan', Number(e.target.value))} />
                            {/*!validacao.colspan ? <span id="validacao-colspan">Campo Obrigatório</span> : null*/}
                        </div>
                        <div style={{ position: "relative" }} className="col-md-3">
                            <button onClick={this.validacao} style={{ position: "absolute", top: "32px" }} className="hdv-btn-forms hdv-btn-blue button-details">{keyEdit !== false ? "Atualizar" : "Adicionar"}</button>
                        </div>
                    </div>
                    <div style={{ padding: "0px 15px", margin: "0px" }} className="row">
                        <table className="table afira-table">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Colunas</th>
                                    <th>Ignorar colunas</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    estrutura.grupos.map((grupo, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{grupo.descricao}</td>
                                                <td>{grupo.th_colspan}</td>
                                                <td>{grupo.colspan}</td>
                                                <td>
                                                    <div style={{ display: "flex" }}>
                                                        <button disabled={keyEdit !== false ? true : false} onClick={() => { this.editarGrupo(key) }} className="hdv-btn-forms hdv-btn-yellow button-details">Editar</button>
                                                        <button disabled={keyEdit !== false ? true : false} onClick={() => { this.excluirGrupo(key) }} className="hdv-btn-forms hdv-btn-red button-details">Excluir</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </details>
        )
    }
}

export default ConfiguracoesGrupos