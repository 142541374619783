import React, { Component } from "react"
import HeaderConsumo from "../../RelatorioConsumo/components/HeaderConsumo"
import auth from "../../../utils/auth"
import cfg from '../../../utils/config'
import axios from "axios"
import Select from "../../../components/Select"

import ConfiguracoesCabecalho from "./componentsCamposGrupos/configuracoesCabecalho"
import PreVisualizacaoCabecalho from "./componentsCamposGrupos/previsualizacaoCabecalho"
import ConfiguracoesGrupos from "./componentsCamposGrupos/configuracoesGrupos"
import ConfiguracoesDados from "./componentsCamposGrupos/configuracoesDados"
import ConfiguracoesEscalas from "./componentsCamposGrupos/configuracoesEscalas"
import ConfiguracoesColunas from "./componentsCamposGrupos/configuracoesColunas"

class CamposGrupos extends Component {
    userInfo = auth.getUserInfo()

    state = {
        modal: false,
        campos: [],
        unidades: [],
    }

    componentDidMount() {
        this.getCampos(this.props.detalhes.campos, this.props.detalhes.periodoTodo)
    }

    getCampos = (campos, periodoTodo) => {
        let estrutura = Object.assign({}, this.props.estrutura)
        const identificadores = []
        const unidades = []
        let escalas = []

        campos.forEach((campo) => {
            let identificacao = campo.identificacao
            let unidade = campo.unidade
            let imei = campo.imei

            if (periodoTodo) {
                identificadores.push(/*imei + "_" +*/ identificacao)
                unidades.push({ id: /*imei + "_" +*/ identificacao, unidade: unidade })
            } else {
                let escala = estrutura.escalas.filter((escala) => escala.identificacao === campo.identificacao)

                escala = escala.length > 0 ? escala[0].escala : { label: "Nenhum", value: null }

                escalas.push({ campo: campo.descricao, escala: escala, identificacao: campo.identificacao, usuario_mqtt: campo.usuario_mqtt, imei: campo.imei })

                identificadores.push(`${imei}_` + identificacao + "_inicial")
                identificadores.push(`${imei}_` + identificacao + "_final")
                unidades.push({ id: `${imei}_` + identificacao + "_inicial", unidade: unidade })
                unidades.push({ id: `${imei}_` + identificacao + "_final", unidade: unidade })

                if (escala.value != null) {
                    identificadores.push(`${imei}_` + identificacao + "_" + escala.value)
                    unidades.push({ id: `${imei}_` + identificacao + "_" + escala.value, unidade: unidade })
                }
            }
        })

        if (campos.length === 0) {
            escalas = []
        }

        estrutura['escalas'] = escalas

        this.props.setEstrutura(estrutura)
        this.setState({ campos: identificadores, unidades: unidades, estrutura })
    }


    adicionarCampos = (itens) => {
        let detalhes = Object.assign({}, this.props.detalhes)

        let bodyFormData = new FormData()
        let campos = []
        let userInfo = auth.getUserInfo()

        for (let campo of itens) {
            campos.push(campo.value)
        }

        bodyFormData.append('campos', campos)

        if (this.props.edit) {
            axios({
                method: 'PATCH',
                url: cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/exportacao-dados/${this.props.idEdit}/`,
                data: JSON.stringify({ campos: campos }),
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((res) => {
                    if (res.data?.campos) {
                        detalhes['campos'] = res.data.campos_detalhes
                    }

                    this.props.setDetalhes(detalhes)
                    this.getCampos(res.data.campos_detalhes, detalhes.periodoTodo)
                    this.setState({ modal: false })
                })
                .catch(console.error)
        } else {
            detalhes['campos'] = itens
            this.props.setDetalhes(detalhes)
            this.toggleModal()
        }
    }
    
    handleChange = (key, value) => {
        let detalhes = Object.assign({}, this.props.detalhes)

        detalhes[key] = value

        this.props.setDetalhes(detalhes)
    }

    render() {
        const { campos, unidades } = this.state
        const estrutura = this.props.estrutura
        const styleFieldset = {
            fieldset: { margin: "10px 0px" },
            legend: { marginBottom: "0px" }
        }

        return (
            <>
                <HeaderConsumo
                    DataChange={this.props.detalhes}
                    toggleModal={() => this.setState({ modal: false })}
                    adicionar={this.adicionarCampos}
                    validacao={this.props.validacao}
                    modal={this.state.modal}
                    handleChange={this.handleChange}
                    order={true}
                    clientes={this.props.clientes}
                />

                <button style={{ position: "relative", width: "100%", height: "50px", background: "var(--fonte_titulo_abas)" }} className="hdv-btn-forms hdv-btn-green" onClick={() => { this.setState({ modal: true }) }}>Gerenciar Parâmetros</button>
                
                <div style={{display: campos.length > 0 ? "block" : "none"}}>
                    {
                        this.userInfo?.tipo != "cliente" ?
                            <details style={ styleFieldset.fieldset } open={true} className='custom-summary'>
                                <summary>Visualização cliente</summary>
                                
                                <div style={{padding: "15px 0px"}}>
                                    <div className="col-md-3">
                                    <label>Cliente</label>
                                    <Select
                                        disabled={true}
                                        value={this.props.detalhes.cliente}
                                        onChange={(e) => this.handleChange('cliente', e)}
                                        options={this.props.clientes}
                                    />
                                </div>
                                </div>
                            </details>
                        : null
                    }
                    
                    
                    <ConfiguracoesDados
                        styleFieldset={styleFieldset}
                        detalhes={this.props.detalhes}
                        setDetalhes={(detalhes) => this.props.setDetalhes(detalhes)}
                        getCampos={(campos, periodoTodo) => this.getCampos(campos, periodoTodo)}
                    />

                    <ConfiguracoesEscalas
                        styleFieldset={styleFieldset}
                        estrutura={this.props.estrutura}
                        setEstrutura={(estrutura) => this.props.setEstrutura(estrutura)}
                        detalhes={this.props.detalhes}
                        campos={this.state.campos}
                        setCampos={(campos) => this.setState({campos})}
                        unidades={this.state.unidades}
                        setUnidades={(unidades) => this.setState({unidades})}
                    />

                    <ConfiguracoesColunas
                         styleFieldset={styleFieldset}
                         estrutura={this.props.estrutura}
                         setEstrutura={(estrutura) => this.props.setEstrutura(estrutura)}
                         detalhes={this.props.detalhes}
                         campos={this.state.campos}
                         setCampos={(campos) => this.setState({campos})}
                         unidades={this.state.unidades}
                         setUnidades={(unidades) => this.setState({unidades})}
                    />
                    
                    <ConfiguracoesCabecalho 
                        styleFieldset={styleFieldset}
                        estrutura={this.props.estrutura}
                        setEstrutura={(estrutura) => this.props.setEstrutura(estrutura)}
                    />
                    
                    <ConfiguracoesGrupos
                        styleFieldset={styleFieldset}
                        estrutura={this.props.estrutura}
                        setEstrutura={(estrutura) => this.props.setEstrutura(estrutura)}
                    />

                    <PreVisualizacaoCabecalho
                        styleFieldset={styleFieldset}
                        estrutura={estrutura}
                        detalhes={this.props.detalhes}
                    />
                </div>
            </>
        )
    }
}

export default CamposGrupos