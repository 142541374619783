import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Table from '../../components/Table'
import { Badge } from 'reactstrap'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import _ from 'underscore'
import axios from 'axios'

class Grid extends Component {
    state = {
        data: [],
        loading: true,
        redirectToLancamento: false,
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/equipamento/?limit=${state?.lineCount}&modelo=8` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            let { next, previous, results,count } = res.data
            this.setState({ data: results ?? [], loading: false, next, previous, count })
        })
    }

    realizarLancamento = () => {
        let toGo, from
        let selecionado = this.state.data.find(d => d.id === this.state.table?.state?.selected[0])

        if (selecionado) {
            toGo = `virtual/${selecionado.imei}/lancamento`
            from = { pathname: toGo }

            this.setState({ redirectToLancamento: from })
        }
    }

    render() {
        const { redirectToLancamento, table } = this.state

        if (redirectToLancamento)
            return <Redirect to={redirectToLancamento} />

        const columns = [{
            Header: 'Imei',
            Accessor: 'imei'
        }, {
            Header: 'Fabricante',
            Accessor: 'fabricante.nome'
        }, {
            Header: 'Modelo',
            Accessor: 'modelo.modelo',
        }, {
            Header: 'Status',
            Accessor: 'status',
            Cell: (original) => {
                if (original.status === "ativo") {
                    return <Badge href="#" color="success">Ativo</Badge>
                } else if (original.status === "cancelado") {
                    return <Badge href="#" color="secondary">Cancelado</Badge>
                } else if (original.status === "bloqueado") {
                    return <Badge href="#" color="danger">Bloqueado</Badge>
                }
            }
            /*Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}>
                    <option value="">Mostrar Todos</option>
                    <option value="ativo">Ativo</option>
                    <option value="bloqueado">Bloqueado</option>
                </select>*/
        }]

        return (
            <>
                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Cadastro Equipamentos Virtuais</h4>
                            <div className="gray-background">
                                <i className="fa fa-mobile fa-2x"></i>
                            </div>
                        </span>
                    </div>

                    <div className="hdv-btn-group" >
                        <button
                            onClick={this.realizarLancamento}
                            className="hdv-btn-forms hdv-btn-blue"
                            disabled={!table?.state.enableEditar}>
                            Realizar Lançamento
                        </button>
                    </div>

                    <Table
                        checkbox
                        notEditFirstColum={true}
                        data={this.state.data}
                        loading={this.state.loading}
                        count={this.state.count}
                        next={this.state.next}
                        previous={this.state.previous}
                        columns={columns}
                        onFetchData={this.getData}
                        onUpdate={(table) => this.setState({ table })}
                    />
                </div>
            </>
        )
    }
}

export default Grid
