import { Component } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { RelatorioContext } from "../../../contexts/Relatorio"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Select from "../../../components/Select"
import auth from "../../../utils/auth"
import cfg from "../../../utils/config"
import SelectCampo from "./SelectCampo"
import helper from "../../../utils/helper"
import axios from "axios"

class GraficoRelatorio extends Component {
    static contextType = RelatorioContext

    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        monitorados: [],
        campos: [],
        valid: true,
        cooldown: false,
    }

    renderNewButton = () => {
        const { mainTabIndex, graficoModal } = this.context
        let mensagem = ''
        let funcao = null

        switch (mainTabIndex) {
            case 1:
                mensagem = "Nova linha de Referência"
                funcao = () => {
                    this.context.set({
                        referenciaModal: {
                            ativo: true, novo: true,
                            referencia: {
                                descricao: '', calcula_como_volume: false, cor_linha: '#20db6e', valor: '',
                                alerta: '', automacao: '', tipo_da_referencia: null
                            }
                        }
                    })
                    this.context.reloadState()
                }
                break
            case 2:
                mensagem = "Novo Resumo"
                funcao = () => {
                    this.context.set({
                        resumoModal: {
                            ativo: true, novo: true,
                            resumo: { descricao: '', formula: '', icone: '', custo: 0.00, tipo: { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' } }
                        }
                    })
                    this.context.reloadState()
                }
                break
        }

        return mainTabIndex == 0 ? null :
            (
                <button
                    onClick={funcao}
                    disabled={!graficoModal?.grafico?.id}
                    className={graficoModal.grafico ? "hdv-btn-forms hdv-btn-blue d-flex align-items-center" : "hdv-noshow-item"}>
                    <i className="fa fa-plus-circle fa-2x"></i> &nbsp; {mensagem}
                </button>
            )
    }

    saveAction = async () => {
        const { graficoModal, relatorioId } = this.context
        let url, verb

        if (graficoModal.novo) {
            verb = 'POST'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${relatorioId}/grafico/`
        } else {
            verb = 'PATCH'
            url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/${relatorioId}/grafico/${graficoModal.grafico.id}/`
        }

        let valid = true
        let except = ['label', 'data', 'valido', 'unidade', 'campo_referencia', 'sinal', 'cliente_detalhes']

        for (let key in graficoModal.grafico) {
            if (!graficoModal.grafico[key] && !except.includes(key)) {
                console.log(key)
                valid = false
            }
        }

        if (!valid) {
            graficoModal.valido = false
            this.context.set({ graficoModal })
            helper.dispatchEvent("showAviso", { message: 'Preencha todos os campos!' })
            return
        }

        this.setState({ valid, cooldown: true })

        const bodyFormData = new FormData()
        bodyFormData.append('relatorio', relatorioId)
        bodyFormData.append('campo_referencia', graficoModal.grafico?.campo_customizado?.identificacao)
        bodyFormData.append('campo_customizado', graficoModal.grafico?.campo_customizado?.id)
        bodyFormData.append('descricao', graficoModal.grafico.descricao)
        bodyFormData.append('calcular_como', graficoModal.grafico.calcular_como.value)
        bodyFormData.append('cor_linha', graficoModal.grafico.cor_linha)
        bodyFormData.append('visualizacao', graficoModal.grafico.tipo_grafico?.value === 'barra' ? 'med' : graficoModal.grafico.visualizacao?.value)
        bodyFormData.append('tipo_grafico', graficoModal.grafico.tipo_grafico.value)

        await axios({ method: verb, url: url, data: bodyFormData, ...this.configMe })
            .then(res => {
                this.context.getData?.(false)
                this.context.set({
                    graficoModal: {
                        ativo: false, novo: true,
                        grafico: {
                            descricao: '', tipo_grafico: '', campo_referencia: '', data: [], cor_linha: '#4287f5', label: true,
                            visualizacao: { label: 'Média do período', value: 'med' },
                            calcular_como: { label: 'Padrão', value: 'padrao' }
                        },
                    },
                })
            })
            .catch(console.error)

        this.setState({ cooldown: false })
    }

    handleChange = (e, field) => {
        let { graficoModal } = this.context
        graficoModal.grafico[field] = e

        this.context.set({ graficoModal })
    }

    render() {
        let { graficoModal, mainTabIndex } = this.context

        return (
            <Modal isOpen={graficoModal.ativo} toggle={() => this.context.set({ graficoModal: { ativo: false, grafico: null } })} className="modal-large">
                <ModalHeader toggle={() => { this.context.set({ graficoModal: { ativo: false, grafico: null } }) }}>
                    {graficoModal.novo ? "Novo gráfico" : graficoModal.grafico?.descricao}
                </ModalHeader>
                <ModalBody>
                    <Tabs selectedIndex={mainTabIndex} onSelect={(index) => this.context.set({ mainTabIndex: index })}>
                        <TabList>
                            <Tab onClick={this.context.reloadState}>Configurações</Tab>
                            <Tab disabled={graficoModal.novo}>Referências</Tab>
                            <Tab disabled={graficoModal.novo}>Resumos</Tab>
                        </TabList>
                        <TabPanel style={{ minHeight: '310px' }}>
                            <div className="hdv-relatorio-details row" style={{ border: 'none' }}>
                                <div className="col-md-12 hdv-form-default">
                                    <label>Descrição</label>
                                    <input
                                        type="text"
                                        value={graficoModal.grafico?.descricao}
                                        onChange={(e) => this.handleChange(e.target.value, 'descricao')}
                                    />
                                    <div className="row hdv-top15 d-flex align-items-center">
                                        <div className="col-md-6">
                                            <label>Tipo do gráfico</label>
                                            <Select
                                                value={graficoModal.grafico?.tipo_grafico}
                                                onChange={(e) => this.handleChange(e, 'tipo_grafico')}
                                                options={[{ label: 'Linha', value: 'linha' }, { label: 'Barra', value: 'barra' }]}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectCampo
                                                detalhes={this.props.detalhes}
                                                value={graficoModal.grafico?.campo_customizado}
                                                cliente={graficoModal.grafico?.cliente_detalhes}
                                                monitorado={graficoModal.grafico?.monitorado_detalhes}
                                                onChange={(value) => this.handleChange({ ...value, id: value.value }, 'campo_customizado')}
                                            />
                                        </div>
                                        <div className={graficoModal.grafico?.tipo_grafico.value === 'linha' ? "col-md-6 hdv-top15" : "hdv-noshow-item"}>
                                            <label>Modo de visualização</label>
                                            <Select
                                                value={graficoModal.grafico?.visualizacao}
                                                onChange={(e) => this.handleChange(e, 'visualizacao')}
                                                options={[
                                                    { label: 'Média do período', value: 'med' },
                                                    { label: 'Todo o período', value: 'tudo' },
                                                    { label: 'Mínima e Máxima', value: 'min_max' },
                                                ]}
                                            />
                                        </div>

                                        <div className="col-md-6 hdv-top15">
                                            <label>Calcular como:</label>
                                            <Select
                                                value={graficoModal.grafico?.calcular_como}
                                                onChange={(e) => this.handleChange(e, 'calcular_como')}
                                                options={[
                                                    { label: 'Padrão', value: 'padrao' },
                                                    { label: 'Volume', value: 'volume' },
                                                    { label: 'Horímetro', value: 'horimetro' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-3 align-center hdv-top15">
                                            <label>Cor
                                                <input
                                                    type="color"
                                                    onChange={(e) => this.handleChange(e.target.value, 'cor_linha')}
                                                    value={graficoModal.grafico?.cor_linha}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel style={{ minHeight: '310px' }}>
                            <table className="table afira-table table-sm">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th>Cor</th>
                                        <th>Tipo</th>
                                        <th>Calcular volume</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.context.referencias?.map((referencia, index) => {
                                        return referencia.grafico === graficoModal.grafico?.id ? (
                                            <tr key={index}>
                                                <td>{referencia?.descricao}</td>
                                                <td>
                                                    <div style={{ width: '20px', height: '20px', backgroundColor: `${referencia?.cor_linha}`, borderRadius: '32px' }}>
                                                    </div>
                                                </td>
                                                <td>{referencia?.tipo_da_referencia.value}</td>
                                                <td>{referencia?.calcula_como_volume ? "Sim" : "Não"}</td>
                                                <td>
                                                    <div style={{ display: 'flex' }}>
                                                        <button
                                                            onClick={() => {
                                                                this.context.set({ referenciaModal: { ativo: true, referencia } })
                                                                this.context.reloadState()
                                                            }}
                                                            className="hdv-automacao-delete-button icon-button">
                                                            <i className="fa fa-pen fa-2x"></i>
                                                        </button>
                                                        <button
                                                            onClick={() => this.context.confirmDelete(referencia, 'referencia')}
                                                            className="hdv-automacao-delete-button icon-button">
                                                            <i className="fa fa-times fa-2x"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                            : null
                                    }
                                    )}
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel style={{ minHeight: '310px' }}>
                            <table className="table afira-table table-sm">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th>Tipo</th>
                                        <th>Parâmetro</th>
                                        <th>Ícone</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.context.resumos?.map((resumo, index) => {
                                        return resumo.grafico === graficoModal.grafico?.id ? (
                                            <tr key={index}>
                                                <td>{resumo.descricao}</td>
                                                <td>{resumo?.tipo?.label}</td>
                                                <td>{resumo?.campo_customizado?.descricao}</td>
                                                <td><i className={resumo?.icone?.value} /></td>
                                                <td>
                                                    <div className="d-flex">
                                                        <button
                                                            onClick={() => {
                                                                this.context.set({ resumoModal: { ativo: true, novo: false, resumo } })
                                                                this.context.reloadState()
                                                            }}
                                                            className="hdv-automacao-delete-button icon-button">
                                                            <i className="fa fa-pen fa-2x"></i>
                                                        </button>
                                                        <button
                                                            onClick={() => this.context.confirmDelete(resumo, 'resumo')}
                                                            className="hdv-automacao-delete-button icon-button">
                                                            <i className="fa fa-times fa-2x"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : null
                                    })}
                                </tbody>
                            </table>
                        </TabPanel>
                    </Tabs>
                    {!this.state.valid ? <span className="text-danger">Houve algum erro</span> : null}
                </ModalBody>
                <ModalFooter>
                    {this.renderNewButton()}
                    <button
                        className={mainTabIndex === 0 ? "hdv-btn-forms hdv-btn-green d-flex align-items-center" : "hdv-noshow-item"}
                        disabled={this.state.cooldown}
                        onClick={this.saveAction}>
                        <i className="fa fa-save fa-2x"></i> &nbsp; Salvar
                    </button>
                    <button
                        className="hdv-btn-forms hdv-btn-default d-flex align-items-center"
                        onClick={() => this.context.set({ graficoModal: { ativo: false, grafico: null } })}>
                        <i className="fa fa-times-circle fa-2x"></i> &nbsp; Fechar
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default GraficoRelatorio