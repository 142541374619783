import React, { Component } from 'react'
import ModalAlerta from '../../components/ModalAlerta'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import moment from 'moment-timezone'
import axios from 'axios'

class Alerta extends Component {
    state = {
        data: [],
        loading: true,
        table: null,
        pageSize: 10,
        lastKey: null,
        end: false,
        interval: null
    }

    componentDidMount() {
        if (!this.props.compact) {
            const interval = setInterval(() => {
                let { data, pageSize } = this.state

                if (data.length <= pageSize) {
                    this.getData({ clear: true })
                }
            }, 60_000)

            this.setState({ interval: interval })
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    getData = ({ clear = false }) => {
        if (this.props.compact && this.props.data) {
            this.setState({ data: this.props.data, loading: false })
            return
        }

        let { end, pageSize, lastKey } = this.state
        let config_me = {}
        let userInfo = {}
        let url = ""

        if (this.props.dataSharedSupervisory) {
            url = cfg.base_api_url + cfg.api_v2 + `/${this.props.dataSharedSupervisory.hash}/alerta-historico-supervisorio-link/?limit=${pageSize}`
            config_me = null
        } else {
            config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
            userInfo = auth.getUserInfo()
            url = cfg.base_api_url + cfg.api_v2 + `/${userInfo.empresa}/alerta-historico/?limit=${pageSize}`
        }

        if (end) return

        this.setState({ loading: true })

        if (this.props.customFilter) {
            url += this.props.customFilter
        }

        let bodyForm = new FormData()

        if (clear) {
            bodyForm.set("last_key", null)
        } else {
            bodyForm.set("last_key", Boolean(lastKey) ? JSON.stringify(lastKey) : null)
        }

        axios({ url, method: "POST", data: bodyForm, ...config_me }).then((res) => {
            let { results, lastKey } = res.data
            let data = []
            let end = false

            if (lastKey == null) {
                end = true
                lastKey = this.state.lastKey
            }
            
            data = clear ? results : [...this.state.data, ...results]

            this.setState({ data, lastKey, end, loading: false })
        }).catch(err => {
            this.setState({ loading: false })
        })
    }

    render() {
        const columns = [{
            Header: 'Descrição',
            Accessor: 'descricao',
            sortable: false,
            Cell: (original) => <ModalAlerta id={original?.uuid} label={original?.descricao} getData={this.getData} />
        }, {
            Header: 'Data',
            Accessor: 'timestamp',
            sortable: false,
            filterable: false,
            Cell: (original) => {
                if (original?.timestamp) {
                    let dateFromUserTZ = moment.tz(original?.timestamp * 1000, 'UTC')
                    let formatted = dateFromUserTZ.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')
                    return (<div>{formatted}</div>)
                }
                else return ""
            }
        }, {
            Header: 'Status',
            Accessor: 'status',
            sortable: false,
            Cell: (original) => {
                const style = { fontSize: "11px", height: "16px", lineHeight: "11px" }
                const status = {
                    "new": <Badge style={style} color="primary">Novo</Badge>,
                    "pending": <Badge style={style} color="warning">Pendente</Badge>,
                    "finished": <Badge style={style} color="success">Finalizado</Badge>
                }

                if (original?.status) {
                    return status[original?.status]
                } else return "---"
            },
        }, {
            Header: 'Interações',
            Accessor: 'interacoes',
            sortable: false,
            Cell: (original) => original?.interacoes?.length ?? 0,
        }, {
            Header: 'Automação',
            Accessor: 'automacao_id',
            sortable: false,
            Cell: (original) => {
                if (original?.automacao_id) {
                    return <Link to={`/automacao/${original?.automacao_id}/editar`}>{original?.descricao}</Link>
                } else return "---"
            },
        }]

        return (
            <div className={this.props.compact ? "" : "hdv-render-div"}>
                {this.props.compact ? null
                    :
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Alertas</h4>
                            <div className="gray-background">
                                <i className="fa fa-exclamation-triangle fa-2x"></i>
                            </div>
                        </span>
                    </div>
                }
                <Table
                    notEditFirstColum={true}
                    columns={columns}
                    search={false}
                    config={false}
                    data={this.state.data}
                    count={this.state.count}
                    loading={this.state.loading}
                    next={this.state.next}
                    previous={this.state.previous}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                    noFooter
                />
                <div className={this.props.compact ? "hdv-noshow-item" : "report-table-pagination"}>
                    <button
                        disabled={this.state.data.length === 0 || this.state.end}
                        onClick={this.state?.table?.fetch} style={{ cursor: "pointer" }}>
                        Carregar Mais
                    </button>
                    <select onChange={(e) => this.setState({ pageSize: Number(e.target.value) })}>
                        <option value="10">10 linhas</option>
                        <option value="20">20 linhas</option>
                        <option value="50">50 linhas</option>
                        <option value="100">100 linhas</option>
                    </select>
                </div>
            </div>
        )
    }
}

export default Alerta
