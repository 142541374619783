import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Modal, ModalHeader, ModalBody, Button, Badge } from 'reactstrap'
import CardComando from '../../components/CardComando'
import mascaraParametrizacao from '../../components/MascaraParametrizacao'
import { pt } from 'react-date-range/dist/locale'
import ModalImage from '../../components/ModalImage'
import { DateRangePicker } from 'react-date-range'
import AutomacaoStatus from './AutomacaoStatus'
import ChangeMonitorado from './ChangeMonitorado'
import ReactECharts from 'echarts-for-react'
import ReactLoading from 'react-loading'
import Mapa from '../../components/Mapa'
import { Link } from 'react-router-dom'
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import { isBoolean } from 'underscore'
import Alerta from '../Alerta'
import axios from 'axios'


class MonitoradoStatus extends Component {
    mapObject = null
    userInfo = auth.getUserInfo()

    state = {
        monitorado: { equipamentos: [], fotos: [], parametros: [], automacoes: [], automacoesAtivas: 0 },
        detalhes: { cliente: [], data: new Date().toISOString().split("T")[0] },
        chart: null,
        selectedParam: null,
        chartLoading: true,
        comandos: [],
        reportes: {},
        pageSize: 10,
        totalMoveSlides: 0,
        actualMoveSlides: 0,
        totalMoveSlidesComando: 0,
        actualMoveSlidesComando: 0,
        equipamentoSelecionado: null,
        tabs: { alerta: 0, equipamentos: 0, automacao: 0 },
        modal: { automacao: false, alerta: false, fotos: false, mapa: false },
        datePicker: {
            grafico: { show: false, startDate: new Date(), endDate: new Date() },
            mapa: { show: false, startDate: new Date(), endDate: new Date() },
            eventos: { show: false, startDate: new Date(), endDate: new Date() },
            csv: { show: false, startDate: new Date(), endDate: new Date() }
        },
        reloadInterval: null,
        autoReload: true,
        buttonCsv: false,
        isClient: auth.isClient()
    }

    componentDidMount = () => {
        this.getData(true)
        this.getComandos()

        this.setState({ reloadInterval: setInterval(this.reloadInterval, 60_000) })
    }

    reloadInterval = () => {
        let { equipamentoSelecionado, autoReload } = this.state
        this.getData()

        if (equipamentoSelecionado && autoReload) {
            this.getReports(equipamentoSelecionado, true)
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.reloadInterval)
    }

    getData = () => {
        const configMe = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() } }
        const requestURL = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/monitorado-status/${this.props.match.params.id}/`

        axios.get(requestURL, configMe).then(async (res) => {
            let selectedParam = this.state.selectedParam
            const monitorado = res.data

            if (monitorado?.parametros?.length > 0) {
                if (selectedParam == null) {
                    selectedParam = monitorado.parametros[0]
                }

                this.getChart({ param: selectedParam })
            } else {
                this.setState({ chartLoading: false })
            }

            monitorado.automacoesAtivas = monitorado?.equipamentos?.filter(equipamento => {
                let ativas = 0

                equipamento?.validacao?.automacoes?.forEach((auto) => {
                    if (auto?.esta_ativo) {
                        ativas++
                    }
                })

                return ativas > 0
            })?.length ?? 0

            try {
                this.mapObject?.map?.setCenter({ lat: monitorado.latitude, lng: monitorado.longitude })

                let marker = new this.mapObject.google_map.maps.Marker({
                    title: "Monitorado",
                    position: { lat: monitorado.latitude, lng: monitorado.longitude }
                })

                marker.setMap(this.mapObject?.map)
            } catch (err) {
                console.error(err)
            }

            this.setState({ monitorado, selectedParam })
            this.quantidadeSlides("parametro")
        }).catch(console.error)
    }

    getComandos = () => {
        axios({
            method: "GET",
            url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/monitorado-comando/?limit=100&monitorado=${this.props.match.params.id}`,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            let monitoradoComandos = []
            res.data?.results?.forEach((comando) => monitoradoComandos.push(comando.comando))

            this.setState({ comandos: monitoradoComandos })
            this.quantidadeSlides("comando")
        }).catch(console.error)
    }

    quantidadeSlides = (tipo) => {
        setTimeout(() => {
            let widthVision = document.getElementById(`slide-${tipo}`)?.clientWidth
            let widthList = document.getElementById(`list-${tipo}`)?.clientWidth / 2
            let moveSlide = Math.ceil((widthVision / widthList)) - 2

            if (Math.sign(moveSlide) === -1 || Math.sign(moveSlide) === -0) {
                moveSlide = 0
            }

            this.setState(tipo === "parametro" ? { totalMoveSlides: moveSlide } : { totalMoveSlidesComando: moveSlide })
        }, 2000)
    }

    slideMove = (direcao, tipo) => {
        let newValue = 0
        let actualMoveSlides = tipo === "parametro" ? this.state.actualMoveSlides : this.state.actualMoveSlidesComando
        let totalMoveSlides = tipo === "parametro" ? this.state.totalMoveSlides : this.state.totalMoveSlidesComando

        if (direcao) {
            newValue = actualMoveSlides + 1
        } else {
            newValue = actualMoveSlides - 1
        }

        if (newValue > totalMoveSlides)
            newValue = totalMoveSlides

        document.getElementById(`slide-${tipo}`).style.transform = "translateX(" + -Math.abs(newValue * (document.getElementById(`list-${tipo}`).clientWidth) / 2) + "px)"
        this.setState(tipo === "parametro" ? { actualMoveSlides: newValue } : { actualMoveSlidesComando: newValue })
    }

    handleModal = (state) => {
        let { modal } = this.state
        modal[state] = !modal[state]
        this.setState({ modal })
    }

    handleTab = (state, value) => {
        let { tabs } = this.state
        tabs[state] = value
        this.setState({ tabs })
    }

    handleSelectDate = (ranges, state) => {
        let { datePicker } = this.state

        datePicker[state]["startDate"] = ranges.selection.startDate
        datePicker[state]["endDate"] = ranges.selection.endDate

        this.setState({ datePicker })
    }

    handleDatePicker = (state) => {
        let { datePicker } = this.state
        datePicker[state].show = !datePicker[state].show

        this.setState({ datePicker })
    }

    getReports = (equipamento, reload = false) => {
        this.setState({ equipamentoSelecionado: equipamento })
        const { reportes, datePicker, monitorado } = this.state
        const imei = equipamento.imei
        let campos = []
        let camposRef = {}

        monitorado.parametros.forEach((parametro) => {
            if (parametro?.campo) {
                camposRef[parametro.campo.identificacao] = parametro?.campo
            }

            if (parametro?.campo?.equipamento?.imei == imei) {
                campos.push(parametro.campo?.identificacao)
            }
        })

        if (reload || !reportes[imei]?.lastKey) {
            let bodyForm = new FormData()

            bodyForm.append("equipamento", imei)
            bodyForm.append("monitorado", monitorado.id)
            bodyForm.append("data_inicio", datePicker.eventos.startDate.toLocaleDateString().split("/").reverse().join("-"))
            bodyForm.append("data_fim", datePicker.eventos.endDate.toLocaleDateString().split("/").reverse().join("-"))

            if (campos.length > 0) {
                bodyForm.append("campos", JSON.stringify(Array.from(new Set(campos))))
            }

            axios({
                url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/monitorado-status-reportes/?limit=${this.state.pageSize}`,
                method: "POST",
                data: bodyForm,
                headers: { 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                const data = res.data?.results ?? []
                const columns = []

                if (data.length > 0) {
                    columns.push({
                        Header: 'Data',
                        Accessor: 'data_hora',
                        sortable: false,
                        Cell: (original) => {
                            if (original?.data_hora) {
                                return original?.data_hora?.replaceAll(/-/g, "/")
                            } else {
                                return <div>---</div>
                            }
                        }
                    })

                    Object.keys(data?.[0])?.forEach((key) => {
                        if (key != "data_hora") {
                            columns.push({
                                Header: camposRef[key]?.descricao,
                                Accessor: key,
                                sortable: false,
                                Cell: (original) => {
                                    if (typeof original[key] === 'boolean') {
                                        return (
                                            <Badge color={original[key] ? "success" : "secondary"}>
                                                {original[key] ? "Verdadeiro" : "Falso"}
                                            </Badge>
                                        )
                                    }

                                    if (original[key] == null || original[key] == undefined) return "---"

                                    return `${original[key] ?? "---"} ${camposRef[key]?.unidade ?? ""}`
                                }
                            })
                        }
                    })
                }

                reportes[imei] = { data, columns, lastKey: res.data?.lastKey }
                this.setState({ reportes, loading: false })
            })
        } else {
            this.setState({ loading: true })
            let bodyForm = new FormData()

            bodyForm.append("equipamento", imei)
            bodyForm.append("monitorado", monitorado.id)
            bodyForm.append("last_key", JSON.stringify(reportes[imei]?.lastKey))
            bodyForm.append("data_inicio", datePicker.eventos.startDate.toLocaleDateString().split("/").reverse().join("-"))
            bodyForm.append("data_fim", datePicker.eventos.endDate.toLocaleDateString().split("/").reverse().join("-"))

            if (campos.length > 0) {
                bodyForm.append("campos", JSON.stringify(campos))
            }

            axios({
                url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/monitorado-status-reportes/?limit=${this.state.pageSize}`,
                method: "POST",
                data: bodyForm,
                headers: { 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                reportes[imei]["data"] = [...reportes[imei].data, ...res.data?.results]
                reportes[imei]["lastKey"] = res.data.lastKey

                this.setState({ reportes, loading: false })
            })
        }
    }

    addReferenceLineChart = (referencias) => {
        let data = []

        if (referencias?.length > 0) {
            referencias?.forEach((referencia) => {
                data.push({
                    yAxis: Number(referencia.valor),
                    name: referencia.descricao,
                    lineStyle: {
                        color: referencia.cor,
                        width: 1,
                        type: 'dashed',
                    }
                })
            })
        }

        return data
    }

    getChart = async ({ param = null, forceUpdate = false }) => {
        let { datePicker, selectedParam } = this.state
        if (this.state.chart == null) {
            this.setState({ chartLoading: true })
        }

        let parametro = Boolean(param) ? param : selectedParam

        if (parametro.campo?.tipo_grafico === "linha") {
            let bodyForm = new FormData()
            bodyForm.append("equipamento", parametro.campo?.equipamento?.imei)
            bodyForm.append("campo", parametro.campo?.identificacao)
            bodyForm.append("campo_id", parametro.campo?.id)
            bodyForm.append("data_inicio", datePicker.grafico.startDate.toLocaleDateString().split("/").reverse().join("-"))
            bodyForm.append("data_fim", datePicker.grafico.endDate.toLocaleDateString().split("/").reverse().join("-"))

            await axios({
                url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/monitorado-status-grafico/`,
                method: "POST",
                data: bodyForm,
                headers: { 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                const { data, group, field, campo, extra } = res.data
                const configuracoes = campo.configuracao ? JSON.parse(campo.configuracao) : ""
                let chartSeries = []
                let xAxis = []

                if (group === "hour" && data) {
                    let serie = {
                        name: `${campo?.descricao}`,
                        type: 'line',
                        data: [],
                        markLine: {
                            data: [],
                            label: { position: 'insideStartTop' },
                            symbol: 'circle'
                        }
                    }

                    serie.markLine.data = this.addReferenceLineChart(configuracoes?.referencias)

                    Object.keys(data)?.forEach((key) => {
                        if (Array.isArray(data?.[key])) {
                            data?.[key]?.forEach((item) => {
                                xAxis.push(new Date(item.timestamp_dispositivo * 1000).toLocaleTimeString('pt-BR').slice(0, 5))
                                serie.data.push(item[field])
                            })
                        }
                    })

                    chartSeries.push(serie)
                } else if (group === "day") {
                    let series = [
                        { type: 'line', data: [], name: "Máxima", markLine: { data: [], label: { position: 'insideStartTop' }, symbol: 'circle' } },
                        { type: 'line', data: [], name: "Média", markLine: { data: [], label: { position: 'insideStartTop' }, symbol: 'circle' } },
                        { type: 'line', data: [], name: "Mínima", markLine: { data: [], label: { position: 'insideStartTop' }, symbol: 'circle' } },
                    ]
                    Object.keys(data)?.forEach((key) => {
                        xAxis.push(key)
                        series[0].data.push(data?.[key]?.max)
                        series[1].data.push(data?.[key]?.med)
                        series[2].data.push(data?.[key]?.min)
                    })

                    series[0].markLine.data = this.addReferenceLineChart(configuracoes?.referencias)
                    series[1].markLine.data = this.addReferenceLineChart(configuracoes?.referencias)
                    series[2].markLine.data = this.addReferenceLineChart(configuracoes?.referencias)

                    chartSeries = series
                }

                let title = null

                if (!["booleano", "string"].includes(parametro?.campo?.tipo_dado_saida)) {
                    title = `Min: ${extra?.min} | Med: ${extra?.med} | Max: ${extra?.max} (${parametro?.campo?.unidade ?? ""})`
                    title = title?.replaceAll("undefined", "--")
                    title = title?.replaceAll("null", "--")
                }

                this.renderChart(parametro, chartSeries, xAxis, null, forceUpdate, title)
            }).catch(console.error)
        } else if (parametro.campo?.tipo_grafico === "barra") {
            const configuracoes = parametro.campo.configuracao ? JSON.parse(parametro.campo.configuracao) : ""

            const dispositivos = [{
                id: parametro.campo?.equipamento?.usuario_mqtt,
                field: parametro.campo?.identificacao,
            }]

            const start = datePicker.grafico.startDate
            const end = datePicker.grafico.endDate
            const diffInDays = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24))
            let group = diffInDays >= 1 ? "day" : "hour_current"

            let bodyForm = new FormData()
            bodyForm.append("dispositivos", JSON.stringify(dispositivos))
            bodyForm.append("grupo", group)
            bodyForm.append("calcular_projecao", "1")
            bodyForm.append("timestamp_dispositivo_ini", start.toLocaleDateString().split("/").reverse().join("-") + " 00:00:00")
            bodyForm.append("timestamp_dispositivo_fim", end.toLocaleDateString().split("/").reverse().join("-") + " 23:59:59")

            await axios({
                url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/reporte/consumo/`,
                method: "POST",
                data: bodyForm,
                headers: { 'Authorization': 'Bearer ' + auth.getToken() }
            }).then((res) => {
                const { data } = res.data
                let xAxis = []

                try {
                    let chartSeries = {
                        name: parametro?.campo?.descricao,
                        type: 'bar',
                        data: [],
                        markLine: {
                            data: [],
                            label: { position: 'insideStartTop' },
                            symbol: 'circle'
                        }
                    }

                    Object.keys(data.TOTAL_GROUP).forEach(key => xAxis.push(Number(key)))

                    xAxis.forEach(key => {
                        if (data.TOTAL_GROUP[key]) {
                            chartSeries.data.push(data.TOTAL_GROUP[key].diff)
                        } else {
                            chartSeries.data.push(0)
                        }
                    })

                    let title = null
                    let groupBy = diffInDays >= 1 ? "day" : "hour"

                    if (!["booleano", "string"].includes(parametro?.campo?.tipo_dado_saida)) {
                        title = `Total: ${data?.TOTAL} (${parametro?.campo?.unidade ?? ""})`

                        if (data?.PROJECTION != 0) {
                            if (group == "day") {
                                title += ` | Estimativa do mês: ${data?.PROJECTION} (${parametro?.campo?.unidade ?? ""})`
                            } else if (group == "hour_current") {
                                title += ` | Estimativa do dia: ${data?.PROJECTION} (${parametro?.campo?.unidade ?? ""})`
                            }
                        }
                    }

                    chartSeries.markLine.data = this.addReferenceLineChart(configuracoes?.referencias)

                    if (group == "hour_current") {
                        xAxis = xAxis.map(axis => axis + ":00")
                    }

                    this.renderChart(parametro, chartSeries, xAxis, groupBy, forceUpdate, title)
                } catch (err) {
                    console.error(err)
                }
            }).catch(console.error)
        }

        this.setState({ chartLoading: false })
    }

    tooltipFormat = (parametro, values) => {
        let html = []

        if (values?.length > 0) {
            html.push(values?.[0]?.axisValue)
        }

        for (let item of values) {
            let mascara = parametro.campo.mascara
            let value = mascara === "padrao" ? item?.value : mascaraParametrizacao[mascara](item?.value)
            let unidade = mascara === "padrao" ? parametro?.campo?.unidade : ""

            html.push(
                `<div style="display: flex; align-items: center;">
                    <div style="width: 14px; height: 14px; background-color: ${item?.color}; margin-right: 5px; margin-bottom: 3px; border-radius: 4px;"></div>
                    <strong>${item?.seriesName}:</strong> &nbsp;${value} ${unidade}
                </div>`
            )
        }

        return html.join("")
    }

    renderChart(parametro, series, xAxis, groupBy = null, forceUpdate = false, title = null) {
        const chart = (
            <ReactECharts
                style={{ height: '370px', width: '100%' }}
                option={{
                    title: { text: title, textStyle: { fontSize: 14 } },
                    legend: { top: 20, show: true },
                    grid: { top: 60, right: 10, bottom: 20, left: 50 },
                    series: series,
                    xAxis: {
                        data: xAxis,
                        name: "name",
                        nameTextStyle: { fontWeight: '600', fontSize: '12' },
                        nameLocation: 'center',
                        axisLabel: {
                            formatter: (value) => groupBy === "hour" ? `${value}:00` : value
                        },
                    },
                    yAxis: {
                        type: 'value',
                        max: (value) => {
                            if (value?.max < 0)
                                return Math.ceil((value.max + (Math.abs(value.max) * 0.05)))
                            return Math.ceil((value.max + (Math.abs(value.max) * 0.05)))
                        },
                        min: (value) => Math.floor(value.min - (Math.abs(value.min) * 0.6)),
                    },
                    tooltip: {
                        show: true,
                        trigger: "axis",
                        axisPointer: { type: "cross", label: { show: true } },
                        extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
                        formatter: (values) => this.tooltipFormat(parametro, values)
                    },
                    dataZoom: [
                        { type: 'inside', start: 0, end: 100 },
                        {
                            bottom: 0,
                            brushSelect: false, start: 0, end: 10, handleSize: '100%',
                            handleStyle: { borderWidth: '2', borderColor: 'rgba(0, 0, 0, 0.42)' }
                        }
                    ],
                }}
            />
        )

        if (forceUpdate) {
            this.setState({ chart: null })
            setTimeout(() => this.setState({ chart }), 30)
        } else {
            this.setState({ chart })
        }
    }

    getDataByDay = (state) => {
        let { datePicker, monitorado } = this.state

        if (state === "eventos") {
            this.setState({ reportes: {}, loading: true })
            setTimeout(() => this.state.table?.fetch(), 500)
        } else if (state === "grafico" && monitorado?.parametros?.length > 0) {
            this.setState({ chartLoading: true })
            this.getChart({ forceUpdate: true })
        } else if (state === "mapa") {

        }

        datePicker[state].show = false
        this.setState({ datePicker })
    }

    generateCSV = async (equipamento) => {
        this.setState({ buttonCsv: true })

        const { datePicker, monitorado } = this.state
        const imei = equipamento.imei
        let campos = []

        monitorado.parametros.forEach((parametro) => {
            if (parametro?.campo?.equipamento?.imei == imei) {
                campos.push(parametro.campo?.identificacao)
            }
        })

        let bodyForm = new FormData()

        const dateStart = datePicker.csv.startDate.toLocaleDateString().split("/").reverse().join("-") + " 00:00:00"
        const dateEnd = datePicker.csv.endDate.toLocaleDateString().split("/").reverse().join("-") + " 23:59:59"

        bodyForm.append("equipamento", imei)
        bodyForm.append("monitorado", monitorado.id)
        bodyForm.append("data_inicio", dateStart)
        bodyForm.append("data_fim", dateEnd)

        if (campos.length > 0) {
            bodyForm.append("campos", JSON.stringify(campos))
        }

        await axios({
            url: cfg.base_api_url + cfg.api_v2 + '/' + auth.getUserInfo().empresa + '/monitorado-exportar/',
            method: "POST",
            data: bodyForm,
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }).then((res) => {
            if (res.status === 200) {
                const filename = `${imei}_${new Date().getTime()}.csv`
                const blob = new Blob([res.data], { type: 'text/csv' })
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', filename)
                a.click()
            }
        }).catch(console.error)

        this.setState({ buttonCsv: false })
    }

    renderDate = (item) => {
        let start = item?.startDate?.toLocaleDateString()
        let end = item?.endDate?.toLocaleDateString()

        if (start == end) return start
        return `${start} - ${end}`
    }

    onOpenMapModal = () => {
        let { monitorado } = this.state
        this.mapObjectModal?.map?.setCenter({ lat: monitorado.latitude, lng: monitorado.longitude })

        let markerModal = new this.mapObjectModal.google_map.maps.Marker({
            title: "Monitorado",
            position: { lat: monitorado.latitude, lng: monitorado.longitude }
        })

        markerModal.setMap(this.mapObjectModal?.map)
    }

    render() {
        const { monitorado, datePicker, selectedParam, chartLoading, isClient } = this.state

        return (
            <div className="hdv-render-div">
                <ModalImage
                    open={this.state.modal.fotos}
                    images={monitorado?.fotos}
                    toggle={() => this.handleModal("fotos")}
                />

                <Modal
                    isOpen={this.state.modal.mapa}
                    toggle={() => this.handleModal("mapa")}
                    className="modal-large" onOpened={this.onOpenMapModal}>
                    <ModalHeader toggle={() => this.handleModal("mapa")}>Mapa</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12 map-status">
                                <div className="screen-status-options status-date-picker d-flex justify-content-end mb-2" style={{ backgroundColor: "transparent" }}>
                                    <button
                                        className="date-range-selection d-flex align-items-center"
                                        style={{ position: "initial", backgroundColor: '#f8f8f8', boxShadow: '0 0px 4px rgba(0, 0, 0, 0.45)' }}
                                        onClick={() => this.handleDatePicker("mapa")}>
                                        <span className="mr-2">
                                            {datePicker.mapa?.startDate.toLocaleDateString("pt-BR")} - {datePicker.mapa?.endDate.toLocaleDateString("pt-BR")}
                                        </span>
                                        <i className={datePicker.mapa.show ? "fa fa-times fa-2x" : "fa fa-calendar fa-2x"}></i>
                                    </button>
                                    <div className={(datePicker.mapa.show) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                        <DateRangePicker
                                            locale={pt}
                                            ranges={[{ ...datePicker.mapa, key: 'selection' }]}
                                            maxDate={new Date()}
                                            onChange={(e) => this.handleSelectDate(e, "mapa")}
                                        />
                                        <Button color="primary" onClick={() => this.getDataByDay("mapa")} style={{ width: "160px", margin: "0 auto" }}>
                                            Aplicar
                                        </Button>
                                    </div>
                                </div>
                                <Mapa
                                    mapObjectFn={(mapObj) => { this.mapObjectModal = mapObj }}
                                    mapTypeControl={false}
                                    style={{ height: "500px", width: "100%" }}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modal.automacao} toggle={() => this.handleModal("automacao")} className="modal-large">
                    <ModalHeader toggle={() => this.handleModal("automacao")}>Automações</ModalHeader>
                    <ModalBody>
                        {this.state.modal.automacao ?
                            <AutomacaoStatus
                                monitorado={monitorado}
                                customFilter={`&monitorados__contains=${monitorado?.id} `}
                                history={this.props.history}
                                onUpdate={(table) => this.setState({ automacaoTable: table })}
                            />
                            :
                            null
                        }
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modal.alerta} toggle={() => this.handleModal("alerta")} className="modal-large">
                    <ModalHeader toggle={() => this.handleModal("alerta")}>Alertas</ModalHeader>
                    <ModalBody>
                        <Alerta compact customFilter={`&monitorado=${monitorado?.id}`} />
                    </ModalBody>
                </Modal>

                <div className="hdv-default-header mx-3">
                    <div style={{ display: 'flex', alignItems: "center", width: "100%" }}>
                        <span className="screen-menu-desc">
                            <h4 title={monitorado?.nome}>
                                {monitorado?.nome?.length > 18 ? monitorado?.nome?.substring(0, 18) + "..." : monitorado?.nome}
                            </h4>
                            <div className={monitorado?.nome ? "gray-background" : "hdv-noshow-item"} style={{ maxHeight: "42px" }}>
                                <i className="fa fa-hdd fa-2x"></i>
                            </div>
                        </span>
                        <ChangeMonitorado idMonitorado={this.props.match.params.id} />
                    </div>
                    <div className="afr-header-options inline-menu-options-page">
                        <Link to={`/monitorado/${this.props.match.params.id}/editar?status=true`}>
                            <summary className="hdv-popover-button gray-background" onClick={() => this.handleModal("fotos")}>
                                <i className="fa fa-pen fa-2x gray-primary"></i>
                                <span>Editar</span>
                            </summary>
                        </Link>
                        <summary className="hdv-popover-button gray-background" onClick={() => this.handleModal("fotos")}>
                            <i className="fa fa-camera fa-2x gray-primary"></i>
                            <span>Fotos</span>
                        </summary>
                        <summary className={monitorado.automacoesAtivas > 0 ? "hdv-popover-button gray-background popover-active" : "hdv-popover-button gray-background"} onClick={() => this.handleModal("automacao")}>
                            <i className="fa fa-robot fa-2x gray-primary"></i>
                            <span>Automações</span>
                        </summary>
                        <summary className="hdv-popover-button gray-background" onClick={() => this.handleModal("alerta")}>
                            <i className="fa fa-exclamation-triangle fa-2x gray-primary"></i>
                            <span>Alertas</span>
                        </summary>
                    </div>
                </div>
                <div style={{ marginTop: "32px" }}></div>
                <div id='list-parametro' className="fields-list" style={{ display: monitorado?.parametros.length > 0 ? "flex" : "none" }}>
                    <div id='slide-parametro' className="fields-slide">
                        {monitorado?.parametros?.map((parametro) => {
                            let mascara = parametro.campo.mascara
                            let icone = parametro.campo?.icone?.icone_on
                            let value = mascara === "padrao" ? String(parametro?.valor_atual) : mascaraParametrizacao[mascara](parametro?.valor_atual)
                            let elementValue = value.length > 10 ? `${value.slice(0, 10)} ...` : value

                            if (elementValue == "null") {
                                elementValue = "---"
                            }

                            if (isBoolean(parametro?.valor_atual)) {
                                let color = ""

                                if (parametro?.valor_atual) {
                                    icone = parametro.campo?.icone?.icone_on
                                    color = "#44bb52"
                                } else {
                                    icone = parametro.campo?.icone?.icone_off
                                    color = "silver"
                                }

                                elementValue = <div style={{ padding: "15px", width: "20px", borderRadius: "30px", background: color }}></div>
                            }

                            let settings = parametro?.campo?.configuracao
                            let color = "transparent"
                            let descricao = ""

                            if (settings != null) {
                                try {
                                    settings = typeof settings == "string" ? JSON.parse(settings) : settings
                                    let referencias = settings.referencias
                                    let menor = []
                                    let maior = []

                                    if (referencias.length > 0) {
                                        referencias.forEach((referencia) => {
                                            if (referencia.condicao.value === "<") {
                                                menor.push(referencia)
                                            } else {
                                                maior.push(referencia)
                                            }
                                        })

                                        menor = menor.sort((a, b) => { return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0 })
                                        maior = maior.sort((a, b) => { return a.nome > b.nome ? -1 : a.nome < b.nome ? 1 : 0 })

                                        if (Number(value) > Number(maior[0]?.valor)) {
                                            maior.forEach((item) => Number(value) > Number(item.valor) ? [color, descricao] = [item.cor, item.descricao] : "")
                                        } else {
                                            menor.forEach((item) => Number(value) < Number(item.valor) ? [color, descricao] = [item.cor, item.descricao] : "")
                                        }
                                    }
                                } catch (err) {
                                    console.error(err)
                                }
                            }

                            return (
                                <div
                                    key={parametro.id}
                                    className={selectedParam?.id === parametro.id ? "status-box-field active" : "status-box-field"}
                                    style={{ cursor: chartLoading ? 'default' : 'pointer' }}
                                    onClick={(e) => {
                                        if (!chartLoading && e.target.tagName != "I") {
                                            this.setState({ selectedParam: parametro, chartLoading: true })
                                            this.getChart({ param: parametro })
                                        }
                                    }}
                                >
                                    <div className="status-box-bg"></div>
                                    <div>
                                        <label style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                            <div>{parametro.campo?.descricao}</div>
                                            <div
                                                title={descricao}
                                                style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    background: color,
                                                    borderRadius: "100%"
                                                }}
                                            >
                                            </div>
                                        </label>
                                    </div>
                                    <strong title={value}>{elementValue} {parametro.campo?.unidade}</strong>
                                    <i id='icon-status' style={{ position: "relative", left: "calc(100% - 85px)", fontSize: "85px", bottom: "100%", zIndex: "-1"}} className={`fa ${icone}`}></i>

                                    {
                                        !isClient ?
                                            <Link to={`/campo-parametrizado/${parametro.campo?.equipamento?.id}/editar/${parametro?.campo?.id}`}>
                                                <button><i className="fa fa-pen fa-2x"></i></button>
                                            </Link>
                                        : null
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div
                        style={{ display: (this.state.actualMoveSlides == this.state.totalMoveSlides) ? "none" : "flex" }}
                        onClick={() => this.slideMove(true, "parametro")}
                        className="fields-box-move fields-box-move-right">
                        <i className="fa fa-angle-right fa-2x"></i>
                    </div>
                    <div
                        style={{ display: (this.state.actualMoveSlides == 0) ? "none" : "flex" }}
                        onClick={() => this.slideMove(false, "parametro")}
                        className="fields-box-move fields-box-move-left">
                        <i className="fa fa-angle-left fa-2x"></i>
                    </div>
                </div>

                <div style={{ marginLeft: "0px", marginBottom: "0px" }} className="status-chart-map-box row mb-1">
                    <div className="col-md-8" style={{ padding: "0" }}>
                        <div className={monitorado.parametros?.length === 0 ? "hdv-noshow-item" : "screen-status-options status-date-picker"}>
                            <button
                                className={chartLoading ? "hdv-noshow-item" : "date-range-selection"}
                                style={{ backgroundColor: '#f8f8f8', boxShadow: '0 0px 4px rgba(0, 0, 0, 0.45)', top: "7px", zIndex: "1", right: "8px" }}
                                onClick={() => this.handleDatePicker("grafico")}>
                                <i className={datePicker.grafico.show ? "fa fa-times fa-2x" : "fa fa-calendar fa-2x"}></i>
                                {this.renderDate(datePicker.grafico)}
                            </button>
                            <div className={(datePicker.grafico.show) ? "hdv-datepicker" : "hdv-datepicker-display-none"} style={{ top: "48px", right: "8px" }}>
                                <DateRangePicker
                                    locale={pt}
                                    ranges={[{ ...datePicker.grafico, key: 'selection' }]}
                                    maxDate={new Date()}
                                    onChange={(e) => this.handleSelectDate(e, "grafico")}
                                />
                                <Button color="primary" onClick={() => this.getDataByDay("grafico")} style={{ width: "160px", margin: "0 auto" }}>
                                    Aplicar
                                </Button>
                            </div>
                        </div>

                        {this.state.chartLoading
                            ?
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "330px", paddingLeft: "24px", paddingTop: "30px" }}>
                                <ReactLoading className="hdv-report-loading" type="spin" color={"#589bd4"} width={35} />
                            </div>
                            :
                            monitorado.parametros?.length === 0 ?
                                <p style={{ textAlign: "center", marginTop: "150px", opacity: "0.85", fontSize: "14px", userSelect: "none" }}>
                                    Sem parametros para exibir
                                </p>
                                :
                                <>{this.state.chart}</>
                        }
                    </div>
                    <div style={{ paddingRight: "0px" }} className="map-status col-md-4">
                        <button
                            id="map-details"
                            style={{ backgroundColor: '#f8f8f8', boxShadow: '0 0px 4px rgba(0, 0, 0, 0.45)', top: '4px !important', left: "24px", width: "38px" }}
                            onClick={() => this.handleModal("mapa")}>
                            <i className="fa fa-expand fa-2x"></i>
                        </button>
                        <Mapa
                            mapObjectFn={(mapObj) => { this.mapObject = mapObj }}
                            options={{ gestureHandling: "none", zoomControl: false }}
                        />
                    </div>
                </div>

                <div
                    id="list-comando"
                    className="fields-list"
                    style={{ display: this.state.comandos?.length > 0 ? "flex" : "none", marginTop: "13px" }}>
                    <div id='slide-comando' className="fields-slide">
                        {this.state.comandos?.map((comando, index) => (
                            <CardComando width="300px" detalhes={comando.equipamento} getComandos={this.getComandos} comando={comando} key={index} compact />
                        ))}
                    </div>
                    <div
                        style={{ display: (this.state.actualMoveSlidesComando == this.state.totalMoveSlidesComando) ? "none" : "flex", height: "200px" }}
                        onClick={() => this.slideMove(true, "comando")}
                        className="fields-box-move fields-box-move-right">
                        <i className="fa fa-angle-right fa-2x"></i>
                    </div>
                    <div
                        style={{ display: (this.state.actualMoveSlidesComando == 0) ? "none" : "flex" }}
                        onClick={() => this.slideMove(false, "comando")}
                        className="fields-box-move fields-box-move-left">
                        <i className="fa fa-angle-left fa-2x"></i>
                    </div>
                </div>

                <div className={monitorado?.equipamentos?.length === 0 ? "hdv-noshow-item" : ""} style={{ position: "relative", marginTop: "12px" }}>
                    <Tabs selectedIndex={this.state.tabs.equipamentos} onSelect={(i) => this.handleTab("equipamentos", i)}>
                        <div className="screen-status-options status-date-picker" style={{ position: 'absolute', top: '5px' }}>
                            <label htmlFor="auto-reload">
                                <input
                                    type="checkbox"
                                    id="auto-reload"
                                    checked={this.state.autoReload}
                                    onChange={(e) => this.setState({ autoReload: e.target.checked })}
                                />
                                Atualização automática
                            </label>
                            <button
                                className="date-range-selection"
                                onClick={() => this.handleDatePicker("eventos")}>
                                <i className={datePicker.eventos.show ? "fa fa-times fa-2x" : "fa fa-calendar fa-2x"}></i>
                            </button>

                            <div className={(datePicker.eventos.show) ? "hdv-datepicker" : "hdv-datepicker-display-none"} style={{ zIndex: "15" }}>
                                <DateRangePicker
                                    locale={pt}
                                    ranges={[{ ...datePicker.eventos, key: 'selection' }]}
                                    maxDate={new Date()}
                                    onChange={(e) => this.handleSelectDate(e, "eventos")}
                                />
                                <Button color="primary" style={{ width: "160px", margin: "0 auto" }} onClick={() => this.getDataByDay("eventos")}>
                                    Aplicar
                                </Button>
                            </div>
                        </div>

                        <TabList className="react-tabs__tab-list">
                            {monitorado?.equipamentos.map((equipamento, index) => (
                                <Tab key={index}>{equipamento.imei}</Tab>
                            ))}
                        </TabList>
                        {monitorado?.equipamentos.map((equipamento) => {
                            const { reportes } = this.state

                            const data = reportes[equipamento.imei] ? reportes[equipamento.imei]?.data : []
                            const columns = reportes[equipamento.imei] ? reportes[equipamento.imei]?.columns : []

                            return (
                                <TabPanel key={equipamento.id}>
                                    <div className="hdv-btn-group mt-0">
                                        <button onClick={() => this.handleDatePicker("csv")} className='hdv-btn-forms hdv-btn-blue'>
                                            Exportar CSV
                                        </button>

                                        <div
                                            style={{ marginTop: "45px", left: "0" }}
                                            className={(datePicker.csv.show) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                            <DateRangePicker
                                                locale={pt}
                                                ranges={[{ ...datePicker.csv, key: 'selection' }]}
                                                maxDate={new Date()}
                                                onChange={(e) => this.handleSelectDate(e, "csv")}
                                            />
                                            <Button
                                                disabled={this.state.buttonCsv} color="success"
                                                style={{ border: "none", width: "160px", margin: "0 auto" }}
                                                onClick={() => this.generateCSV(equipamento)}>
                                                Gerar CSV
                                            </Button>
                                        </div>
                                    </div>

                                    <Table
                                        noFooter
                                        notEditFirstColum={true}
                                        search={false}
                                        config={false}
                                        data={data}
                                        loading={this.state.loading}
                                        next={this.state.next}
                                        previous={this.state.previous}
                                        columns={columns}
                                        onFetchData={(_) => this.getReports(equipamento)}
                                        onUpdate={(table) => this.setState({ table })}
                                    />
                                    <div className="report-table-pagination">
                                        <button
                                            disabled={data.length === 0}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                this.state?.table?.fetch()?.then(() => {
                                                    setTimeout(() => {
                                                        document.querySelector(".report-table-pagination").scrollIntoView()
                                                    }, 300)
                                                })
                                            }}>
                                            Carregar Mais
                                        </button>
                                        <select onChange={(e) => this.setState({ pageSize: Number(e.target.value) })}>
                                            <option value="10">10 linhas</option>
                                            <option value="20">20 linhas</option>
                                            <option value="50">50 linhas</option>
                                            <option value="100">100 linhas</option>
                                        </select>
                                    </div>
                                </TabPanel>
                            )
                        }
                        )}
                    </Tabs>
                </div>
            </div >
        )
    }
}

export default MonitoradoStatus
