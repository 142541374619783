import ReactSelect from "react-select"

export default function Select(props) {
    let height = props.height ? props.height : '44px'

    if (props.isMulti) {
        height = (Math.floor((props?.value?.length ?? 1) / 5) * 16) + 44
        height = height + "px"
    }

    if (props.forceHeightAuto) {
        height = "auto"
    }

    const style = {
        option: (provided, state) => {
            let backgroundColor = state.isSelected ? '#4e9bed !important' : '#fff'

            if (state.isDisabled) backgroundColor = '#ededed !important'

            return {
                ...provided,
                backgroundColor: backgroundColor,
                color: state.isSelected ? '#fff' : '#333',
                fontSize: '12px',
                textAlign: 'left',
                '&:hover': { backgroundColor: '#ccdfff', cursor: 'pointer' }
            }
        },
        noOptionsMessage: () => ({
            fontSize: '12px',
            padding: '8px 14px',
            color: '#888888'
        }),
        menu: () => ({
            width: '100%',
            border: '1px solid #d4d4d4',
            marginTop: '3px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            borderRadius: '8px',
            position: 'absolute',
            backgroundColor: '#fff',
            zIndex: '999'
        }),
        placeholder: (provided) => ({ ...provided, color: '#111', opacity: '0.7' }),
        singleValue: (provided) => ({ ...provided, color: '#111' }),
        control: (_, state) => ({
            height: height,
            borderRadius: '10px',
            padding: '0px',
            fontSize: '12px',
            display: 'flex',
            minHeight: '20px',
            alignItems: 'center',
            backgroundColor: props.disabled ? '#efefef4d' : '#fff',
            border: state.isFocused ? '1px solid var(--fonte_titulo_abas) !important' : '1px solid #dfdfdf',
            '&:hover': { cursor: 'pointer', border: '1px solid #bebebe' }
        }),
        multiValue: (base) => ({
            ...base,
            fontSize: '16px',
            borderRadius: '6px',
        }),
    }

    return (
        <ReactSelect
            value={props.value}
            onChange={props.onChange}
            options={props.options}
            defaultValue={props.defaultValue}
            isDisabled={props.disabled ?? false}
            closeMenuOnSelect={props.closeMenuOnSelect ?? true}
            style={props.style}
            isMulti={props.isMulti ?? false}
            components={props.components}
            placeholder="Selecione"
            noOptionsMessage={() => props.noOptionsMessage ?? "Sem Opções"}
            styles={style}
        />
    )
}
