import React, { Component } from 'react'
import PrivateRoute from '../../containers/PrivateRoute'
import { Switch } from 'react-router-dom'
import FormGrupo from './form_grupo'
import Grid from './grid'
import Form from './form'

class Automacao extends Component {
	render() {
		return (
			<div className="hdv-render-div">
				<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
					<Switch>
						<PrivateRoute exact path='/automacao' component={Grid} />
						<PrivateRoute path='/automacao/novo' component={Form} />
						<PrivateRoute path='/automacao/:id/editar' component={Form} />
						<PrivateRoute exact path='/automacao-grupo/novo' component={FormGrupo} />
						<PrivateRoute exact path='/automacao-grupo/:id/editar' component={FormGrupo} />
					</Switch>
				</div>
			</div>
		)
	}
}

export default Automacao
