import React, { Component } from 'react'
import BulletChart from './BulletChart'

class MaximoAtual extends Component {

    state = {
        params: {
            descricao: "Maximo x Atual",
            data: {
                size: 100,
                bars: [
                    { title: 'Máximo', value: 100, css: '' },
                    { title: 'Atual', value: 50, css: 'blue' }
                ],
                markers: [{ title: 'Limite', value: 75, css: 'orange' }],
                ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                showLegend: true
            }
        }
    }

    componentWillMount = () => {
        if (this.props.dataWidget) {
            let params = this.state.params
            params['descricao'] = this.props.dataWidget.descricao
            params.data['bars'] = [
                { title: 'Máximo', value: 100, css: '' },
                { title: 'Atual', value: (100 / parseFloat(this.props.dataWidget.params['valor_maximo'])) * parseFloat(this.props.dataWidget.value), css: 'blue' }
            ]
            params.data['markers'] = [
                { title: 'Limite', value: (100 / parseFloat(this.props.dataWidget.params['valor_maximo'])) * parseFloat(this.props.dataWidget.params['valor_alerta']), css: 'orange' },
            ]
            this.setState({ params: params })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.dataWidget.value !== nextProps.dataWidget.value) {
            let params = this.state.params
            params['descricao'] = nextProps.dataWidget.descricao
            params.data['bars'] = [
                { title: 'Máximo', value: 100, css: '' },
                {
                    title: 'Atual',
                    value: (100 / parseFloat(nextProps.dataWidget.params['valor_maximo'])) * parseFloat(nextProps.dataWidget.value),
                    css: 'blue'
                }
            ]
            params.data['markers'] = [{
                title: 'Limite',
                value: (100 / parseFloat(nextProps.dataWidget.params['valor_maximo'])) * parseFloat(nextProps.dataWidget.params['valor_alerta']),
                css: 'orange'
            }]
            this.setState({ params: params })
        }
    }

    render() {
        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento({ showUnity: false })}
                <div className="box-gauge">
                    <div className="box-gauge-margintop" style={{ width: '90%' }}>
                        <BulletChart data={this.state.params.data} />
                    </div>
                </div>
            </div>
        )
    }
}

export default MaximoAtual