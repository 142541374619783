import React, { Component } from 'react'
import LiquidFillGauge from 'react-liquid-gauge'

class Reservatorio extends Component {

    state = {
        gaugeRef: { h: 150 },
        params: {
            descricao: "Reservatório",
            value: 100,
            id: "resr01",
            params: {}
        }
    }

    componentDidMount = () => {
        if (this.props.dataWidget) {
            let params = this.state.params
            params = this.props.dataWidget

            params['value'] = (100 / parseFloat(this.props.dataWidget.params['valor_maximo'])) * parseFloat(params['value'])

            this.setState({ params: params })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.dataWidget.value !== nextProps.dataWidget.value) {
            let params = this.state.params
            params = nextProps.dataWidget
            params['value'] = (100 / parseFloat(nextProps.dataWidget.params['valor_maximo'])) * parseFloat(params['value'])

            this.setState({ params: params })
        }
    }

    render() {

        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento({ showUnity: false })}
                <div className={"box-gauge id-reserv-" + this.state.params.id}>
                    <div className="box-gauge-margintop">
                        <LiquidFillGauge
                            style={{ margin: '0 auto' }}
                            width={(document.querySelector('.id-reserv-' + this.state.params.id)) ? document.querySelector('.id-reserv-' + this.state.params.id).offsetHeight / 2 : 150}
                            height={(document.querySelector('.id-reserv-' + this.state.params.id)) ? document.querySelector('.id-reserv-' + this.state.params.id).offsetHeight / 2 : 150}
                            value={this.state.params.value}
                            percent="%"
                            textSize={1}
                            textOffsetX={0}
                            textOffsetY={0}
                            textRenderer={(props) => {
                                const value = Math.round(this.state.params.value)
                                const radius = Math.min(props.height / 2, props.width / 2)
                                const textPixels = (props.textSize * radius / 2)
                                const valueStyle = { fontSize: textPixels }
                                const percentStyle = { fontSize: textPixels * 0.6 }

                                return (
                                    <tspan>
                                        <tspan className="value" style={valueStyle}>{value}</tspan>
                                        <tspan style={percentStyle}>{props.percent}</tspan>
                                    </tspan>
                                )
                            }}
                            riseAnimation
                            waveAnimation
                            waveFrequency={2}
                            waveAmplitude={1}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Reservatorio