import React from "react"

class TimePicker extends React.Component {
    state = {
        numbers: [],
    }

    componentDidMount() {
        let numbers = []
        for (let i = 0; i < 60; i++) {
            numbers.push(i < 10 ? `0${i}` : String(i))
        }

        this.setState({ numbers })
    }

    handleChange = (type, value) => {
        let split = this.props.value?.split(":")
        let newValue

        if (type === "minute") {
            newValue = `${value}:${split[1]}`
        } else if (type === "second") {
            newValue = `${split[0]}:${value}`
        }

        this.props?.onChange({
            type: "time",
            target: {
                id: this.props?.id,
                name: this.props?.name,
                value: newValue
            }
        })
    }

    render() {
        return (
            <details className="w-100 afira-time-picker">
                <summary>
                    <span>{this.props.value}</span>
                    <i className="fa fa-clock fa-2x" style={{ transform: "scale(0.7)" }}></i>
                </summary>
                <div id="afira-time-menu">
                    <div>
                        {this.state.numbers.map((minute, index) => (
                            <span onClick={() => this.handleChange("minute", minute)} key={index}>
                                {minute}
                            </span>
                        ))}
                    </div>
                    <div>
                        {this.state.numbers.map((second, index) => (
                            <span onClick={() => this.handleChange("second", second)} key={index}>
                                {second}
                            </span>
                        ))}
                    </div>
                </div>
            </details>
        )
    }
}

export default TimePicker