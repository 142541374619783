import { Component } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Select from "../../components/Select"

class ReferenciaParametrizacao extends Component {
    state = {
        detalhes: {descricao: "", cor: "#000000", valor: "", condicao: {label: "Menor", value: "<"}},
        validation: {descricao: true, cor: true, valor: true, condicao: true },
        keyEdit: ""
    }

    handleChange = (key, value) => {
        const detalhes = Object.assign({}, this.state.detalhes)
        detalhes[key] = value
        this.setState({ detalhes })
    }

    validation = () => {
        let detalhes = Object.assign({}, this.state.detalhes)
        let validation = Object.assign({}, this.state.validation)
        let erro = false

        for(let i in detalhes) {
            if(detalhes[i] == "") {
                validation[i] = false
                erro = true
            } else {
                validation[i] = true
            }
        }

        this.setState({validation})

        return erro
    }

    add = () => {
        if(this.validation()) {
            return
        }

        const detalhes = this.state.detalhes
        const keyEdit = this.state.keyEdit
        const referencias = [...this.props.referencias]

        if(keyEdit !== "") {
            referencias[keyEdit] = detalhes
        } else {
           referencias.push(detalhes)
        }

        this.props.setReferecias(referencias)
        this.clearState()
        this.props.toggleModal(false)
    }

    clearState = () => {
        this.setState({
            detalhes: {descricao: "", cor: "#000000", valor: "", condicao: {label: "Menor", value: "<"}},
            validation: {descricao: true, cor: true, valor: true, condicao: true },
            keyEdit: ""
        })
    }

    cancel = () => {
        this.clearState()
        this.props.toggleModal(false)
    }

    edit = (keyEdit) => {
        const referencias = [...this.props.referencias]
        const referencia = referencias.filter((item, index) => index == keyEdit)
        
        this.setState({
            detalhes: referencia[0],
            keyEdit
        })

        this.props.toggleModal(true)
    }

    delete = (keyDelete) => {
        let referencias = [...this.props.referencias]
        referencias = referencias.filter((item, index) => index != keyDelete)

        this.props.setReferecias(referencias)
    }

    render() {
        const  { detalhes, validation, keyEdit } = this.state

        return (
            <>
                <Modal className="modal-md" isOpen={this.props.modalReferencia} toggle={this.cancel}>
                    <ModalHeader toggle={() => this.cancel}>
                        {keyEdit !== "" ? "Editar referência" : "Nova referência"}
                    </ModalHeader>
                    <ModalBody>
                        <div className="hdv-form-default">                        
                            <div className="row">
                                <div className="col-md-9">
                                    <label className="required">Descrição</label>
                                    <input value={detalhes.descricao} id="descricao" onChange={(e) => this.handleChange("descricao", e.target.value)} type="text"/>
                                    {!validation.descricao ? <span id="validacao-descricao">Campo não pode ser nulo!</span> : null}
                                    
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}} className="col-md-3">
                                    <label className="required">Cor</label>
                                    <input value={detalhes.cor} id="cor" onChange={(e) => this.handleChange("cor", e.target.value)} style={{width: "80px", height: "40px", border: "none"}} type="color"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="required">Condição</label>
                                    <Select
                                        options={[
                                            {label: "Menor", value: "<"},
                                            {label: "Maior", value: ">"}
                                        ]}
                                        value={detalhes.condicao}
                                        onChange={(e) => this.handleChange('condicao', e)}
                                    />
                                </div> 
                                <div className="col-md-6">
                                    <label className="required">Valor</label>
                                    <input value={detalhes.valor} id="valor" onChange={(e) => this.handleChange("valor", e.target.value)} type="number" />
                                    {!validation.valor ? <span id="validacao-valor">Campo não pode ser nulo!</span> : null}
                                </div> 
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hdv-btn-group">
                            <button onClick={this.add} className="hdv-btn-forms hdv-btn-green">{keyEdit? "Atualiar" : "Adicionar"}</button>
                            <button onClick={this.cancel} className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <table className="table afira-table">
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th>Cor</th>
                            <th>Condição</th>
                            <th>Valor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.referencias.map((referencia, keyReferencia) => {
                                return (
                                    <tr key={keyReferencia}>
                                        <td>{referencia.descricao}</td>
                                        <td>
                                            <div style={{background: referencia.cor, width: "50px", height: "30px", borderRadius: "5px"}}></div>
                                        </td>
                                        <td>{referencia.condicao.label}</td>
                                        <td>{referencia.valor}</td>
                                        <td>
                                            <button style={{padding: "0px 5px", height: "30px"}} onClick={() => this.edit(keyReferencia)} className="hdv-btn-forms hdv-btn-yellow">Editar</button>
                                            <button style={{padding: "0px 5px", height: "30px"}} onClick={() => this.delete(keyReferencia)} className="hdv-btn-forms hdv-btn-red">Excluir</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }
}

export default ReferenciaParametrizacao