import React, { Component } from 'react'
import helper from '../utils/helper'
let GoogleMapsLoader = require('google-maps')

GoogleMapsLoader.KEY = 'AIzaSyBHNL4tu1ZLvrXR_oBhyIHrlGWPlj5gtts'
GoogleMapsLoader.LANGUAGE = 'pt-br'
GoogleMapsLoader.REGION = 'BR'
GoogleMapsLoader.LIBRARIES = ['geometry', 'drawing']
GoogleMapsLoader.VERSION = '3.52'

class Mapa extends Component {
    mapId = helper.randomKey(12)

    state = {
        google_object: false,
        map: false,
        loading: true,
    }

    componentDidMount = () => {
        GoogleMapsLoader.load((google) => {
            this.setState({ google_object: google })
        })
    }

    componentDidUpdate = () => {
        if (this.state.google_object && this.state.map === false) {
            this.createMap()
        } else if (this.state.google_object && this.state.map && this.state.loading) {
            this.setState({ loading: false })
            this.props.mapObjectFn({ map: this.state.map, google_map: this.state.google_object })
        }
    }

    createMap = () => {
        let mapElement = document.querySelector(`*[data-map-id="${this.mapId}"]`)
        let options = {
            mapId: this.mapId,
            center: { lat: -17.408282, lng: -53.117169 },
            zoom: 4,
            mapTypeId: 'roadmap',
            mapTypeControl: true,
            streetViewControl: false,
            mapTypeControlOptions: {
                style: this.state.google_object.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: this.state.google_object.maps.ControlPosition.BOTTOM_CENTER,
            },
            fullscreenControl: false,
            styles: [{
                "featureType": "poi",
                "stylers": [{ "visibility": "off" }]
            }, {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }]
            }, {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }]
            }, {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }]
            }, {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }]
            }, {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }]
            }, {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }]
            }, {
                "elementType": "labels.text.stroke",
                "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }]
            }, {
                "elementType": "labels.text.fill",
                "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }]
            }, {
                "elementType": "labels.icon",
                "stylers": [{ "visibility": "off" }]
            }, {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }]
            }, {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }]
            }, {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }]
            }]
        }

        if (this.props.options) {
            options = { ...options, ...this.props.options }
        }

        let map = new this.state.google_object.maps.Map(mapElement, options)

        this.setState({ map: map })
    }

    componentWillReceiveProps(props) {
        if (props.filter) {
            this.state.map?.setCenter({ lat: Number(props.filter[0]), lng: Number(props.filter[1]) })
            this.state.map?.setZoom(17)
        }
    }

    render() {
        return (
            <div id="map" style={{ boxShadow: '0', ...this.props.style }} data-map-id={this.mapId}>
            </div>
        )
    }
}

export default Mapa