import React, { Component } from "react"
import auth from "../utils/auth"

class ButtonsGrid extends Component {
    state = {
        actions: [],
        typeUser: auth.getUserInfo()?.tipo
    }

    componentDidMount() {
        let permissions = auth.getUserInfo()?.permissions ?? []
        let route = this.props.route

        if (route in permissions) {
            this.setState({ actions: permissions[route] })
        }
    }

    render() {
        const { typeUser, actions } = this.state

        return (
            <>
                {this.props.buttons.map((item, key) => {
                    if (typeUser === 'admin' || typeUser === 'master') {

                        return <React.Fragment key={key}>{item.button}</React.Fragment>
                    } else if (actions.includes(item.method)) {

                        return <React.Fragment key={key}>{item.button}</React.Fragment>
                    }
                })
                }
                <div style={{ height: "38px", width: "1px" }}></div>
            </>
        )
    }
}

export default ButtonsGrid