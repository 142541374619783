import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import auth from '../utils/auth'
import cfg from "../utils/config"
import axios from 'axios'

const DEFAULT_STATE = {
    relatorioId: null,
    confirmDelete: { ativo: false, resource: null },
    mainTabIndex: 0,
    referencias: [],
    charts: [],
    resumos: [],
    clientes: [],
    resource: {
        agruparOptions: [
            { label: 'Hora', value: 'hour' },
            { label: 'Dia', value: 'day' },
            { label: 'Mês', value: 'month' }
        ],
        automacaoOptions: [],
        ultimoReporte: null,
        tiposResumo: [
            { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' },
            { label: 'Volume Total', value: 'volume_total' },
            { label: 'Custo por unidade', value: 'custo' },
        ],
        resumoIconOptions: [
            { label: <i className='fa fa-ruler fa-2x'></i>, value: 'fa fa-ruler fa-2x' },
            { label: <i className='fa fa-bolt fa-2x'></i>, value: 'fa fa-bolt fa-2x' },
            { label: <i className='fa fa-clock fa-2x'></i>, value: 'fa fa-clock fa-2x' },
            { label: <i className='fa fa-tint fa-2x'></i>, value: 'fa fa-tint fa-2x' },
            { label: <i className='fa fa-coins fa-2x'></i>, value: 'fa fa-coins fa-2x' },
            { label: <i className='fa fa-exclamation fa-2x'></i>, value: 'fa fa-exclamation fa-2x' },
            { label: <i className='fa fa-balance-scale fa-2x'></i>, value: 'fa fa-balance-scale fa-2x' },
            { label: <i className='fa fa-chart-bar fa-2x'></i>, value: 'fa fa-chart-bar fa-2x' },
            { label: <i className='fa fa-chart-line fa-2x'></i>, value: 'fa fa-chart-line fa-2x' },
            { label: <i className='fa fa-percent fa-2x'></i>, value: 'fa fa-percent fa-2x' },
        ],
        referenciaOptions: [
            { label: 'Valor Fixo', value: 'fixa' },
            { label: 'Valor Dinâmico', value: 'dinamico' },
            { label: 'Automação', value: 'automacao' },
        ],
    },
    selected: { cliente: '', monitorado: '', campo: '' },
    graficoModal: {
        ativo: false,
        novo: true,
        grafico: {
            descricao: '', tipo_grafico: { label: 'Linha', value: 'linha' }, campo_customizado: '',
            campo_referencia: '', data: [], cor_linha: '#4287f5', label: true,
            visualizacao: { label: 'Média do período', value: 'med' }, calcular_como: { label: 'Padrão', value: 'padrao' }
        },
    },
    referenciaModal: {
        ativo: false, novo: true,
        referencia: {
            descricao: '', calcula_como_volume: false, cor_linha: '#20db6e', valor: '',
            automacao: '', tipo_da_referencia: null, campo_customizado: ''
        },
    },
    resumoModal: {
        ativo: false, novo: true,
        resumo: {
            descricao: '', formula: '', icone: null, custo: 0.00, campo_customizado: '',
            tipo: { label: 'Mínimo, Médio e Máximo', value: 'min_med_max' }
        }
    },
}

export const RelatorioContext = React.createContext(DEFAULT_STATE)

export class RelatorioContextProvider extends Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = DEFAULT_STATE

    removeResource = (resource) => {
        const { confirmDelete, relatorioId } = this.state
        const baseUrl = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/relatorio/${relatorioId}`

        const urls = {
            'grafico': baseUrl + `/grafico/${resource.id}`,
            'referencia': baseUrl + `/referencia/${resource.id}`,
            'resumo': baseUrl + `/resumo/${resource.id}`
        }

        axios.delete(urls[confirmDelete.tipo], this.configMe).then(res => this.state.getData?.(false))

        this.setState({ confirmDelete: { ativo: false, resource: null } })
    }

    confirmDeletePrompt = (resource, tipo) => this.setState({ confirmDelete: { ativo: true, resource: resource, tipo } })

    setContext = (value) => {
        let state = { ...this.state, ...value }
        this.setState(state)
    }

    reloadState = () => setTimeout(() => this.setState({ reload: '1' }), 400)

    render() {
        const value = {
            ...this.state,
            set: this.setContext, reloadState: this.reloadState,
            confirmDelete: this.confirmDeletePrompt
        }

        return (
            <RelatorioContext.Provider value={value}>
                <Modal
                    isOpen={this.state.confirmDelete.ativo}
                    toggle={() => this.setState({ confirmDelete: { ativo: false, resource: null } })}>
                    <ModalHeader
                        toggle={() => { this.setState({ confirmDelete: { ativo: false, resource: null } }) }}>
                        Confirmar
                    </ModalHeader>
                    <ModalBody>
                        Você tem certeza que deseja deletar?
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="hdv-btn-forms hdv-btn-red"
                            onClick={() => this.removeResource(this.state.confirmDelete.resource)}>
                            Sim
                        </button>
                        <button
                            className="hdv-btn-forms hdv-btn-default"
                            onClick={() => this.setState({ confirmDelete: { ativo: false, resource: null } })}>
                            Cancelar
                        </button>
                    </ModalFooter>
                </Modal>
                {this.props.children}
            </RelatorioContext.Provider>
        )
    }
}