import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ButtonsForm from '../../components/ButtonsForm'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from '../../utils/helper'
import axios from 'axios'


class Form extends Component {

    state = {
        detalhes: { "nome": "" },
        formerrors: [],
        redirect: false,
        path: "",
        permissoes: [],
        open_group_block: {},
        selected_permission: [],
        cooldown: false,
    }

    componentWillMount = () => {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/permissoes-cliente/', config_me).then((res => {
            let open_close_group_block = {}
            
            res.data.results?.forEach(grupo => {
                open_close_group_block[grupo.nome.toLowerCase()] = true
            })

            this.setState({ permissoes: res.data.results, open_group_block: open_close_group_block })
        }))
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let userInfo = auth.getUserInfo()
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/perfil/' + this.props.match.params.id + '/'
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(requestURL, config_me).then((res) => {
            let permissoes = res.data.permissoes
            let selected_permission = []
            delete res.data.empresa
            delete res.data.permissoes
            permissoes.forEach((v) => selected_permission.push(v.id))
            this.setState({ detalhes: res.data, selected_permission: selected_permission })
        })
    }

    handleChange = (e) => {
        let obj = this.state.detalhes

        if (e.target.type === "checkbox") {
            obj[e.target.id] = (e.target.checked === true) ? true : false
        } else {
            obj[e.target.id] = e.target.value
        }

        if (document.querySelector(`#validacao-${e.target.name}`))
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        this.setState({ "detalhes": obj })
    }

    saveAction = async (novo_cadastro) => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo()
        let resource = "cliente/perfil"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/'
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/' + resource + '/'
            verb = 'post'
        }
        let bodyFormData = new FormData()

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key])
        }

        if (this.state.selected_permission.length > 0) {
            bodyFormData.append('permissoes', this.state.selected_permission)
        }

        await axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    window.location.reload()
                } else {
                    helper.dispatchEvent("showAviso", {
                        message: `Perfil ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`,
                        callback: () => this.setState({ redirect: true, path: "/perfil/cliente" }),
                        callbackClose: verb === 'post' ? () => {
                            window.location.href = `/perfil/cliente/${response.data.id}/editar`
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000)
                        } : null
                    })
                }
            })
            .catch(error => {
                let erros = Object.entries(error.response.data)
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span) {
                            span.innerHTML = erros[i][1]
                            window.scrollTo({ top: 50, behavior: 'smooth' })
                        }
                    }
                }
            })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    changePermissionOption = (id) => {
        let selected_permission = this.state.selected_permission
        if (selected_permission.includes(id)) {
            selected_permission = selected_permission.filter(item => item !== id)
        } else {
            selected_permission.push(id)
        }
        this.setState({ selected_permission: selected_permission })
    }

    changePermissionGroup = (event, group) => {
        let selected_permission = this.state.selected_permission
        if (event.target.checked) {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selected_permission.push(permissao.id)
            })
        } else {
            this.state.permissoes[group].permissoes.forEach((permissao) => {
                selected_permission = selected_permission.filter(item => item !== permissao.id)
            })
        }
        this.setState({ selected_permission: selected_permission })
    }

    createGroupPermission = () => {
        return this.state.permissoes.map((v, i) => {
            let permissionsId = v.permissoes?.map(p => p.id)
            let allSelected = permissionsId?.every(id => this.state.selected_permission.includes(id))

            return permissionsId?.length > 0 ?
                <details key={i} className="custom-summary" style={{ marginBottom: '8px' }} open>
                    <summary>
                        <label style={{ width: "fit-content", margin: "0" }}>
                            <input checked={allSelected} onChange={(e) => this.changePermissionGroup(e, i)} type="checkbox" className="mr-2" />
                            {v.nome}
                        </label>
                    </summary>
                    <div style={{ padding: '4px 5px', backgroundColor: '#fff' }}>
                        {
                            v.permissoes.map((p, i) => {
                                return (
                                    <div key={i} className={(this.state.open_group_block[v.nome.toLowerCase()]) ? "hdv-permission-block" : "hdv-noshow-item"} >
                                        <label style={{ margin: 0 }}>
                                            <input checked={this.state.selected_permission.includes(p.id)} onChange={() => this.changePermissionOption(p.id)} type="checkbox" />
                                            <span className="hdv-permission-description">{p.descricao}</span>
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </details>
                :
                null
        })
    }

    render() {
        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar Perfil</h4> : <h4>Novo Perfil</h4>}
                        <div className="gray-background">
                            <i className="fa fa-id-card fa-2x"></i>
                        </div>
                    </span>
                </div>

                <form className="hdv-form-default">
                    <label htmlFor="nome">Nome do Perfil</label>
                    <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                    <span id="validacao-nome"></span>
                    <label htmlFor="">Selecionar Recursos</label>
                    {this.createGroupPermission()}
                </form>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/cliente/perfil"
                        edit={this.props.match.params.edit ? true : false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>,
                            }, {
                                method: "post",
                                button: <button style={{ display: this.props.match.params.id ? "none" : "inline-block" }} onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-greenlight">Salvar e Cadastrar um Novo</button>
                            }, {
                                method: "patch",
                                button: <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-green">Salvar</button>,
                            },
                        ]}
                    />
                    <Link to="/perfil/cliente">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Form