import React, { Component } from "react"
import auth from "../utils/auth"

class ButtonsForm extends Component {
    state = {
        buttons: [],
    }

    componentDidMount() {
        const edit = this.props.edit
        let permissions = auth.getUserInfo()?.permissions ?? []
        let route = this.props.route
        let buttons = []

        if (auth.isMasterOrAdmin()) {
            this.props.buttons.forEach((button) => buttons.push(button))
        } else {
            if (route in permissions) {
                let buttons_permissions = permissions[route]

                this.props.buttons.forEach((button) => {
                    if (buttons_permissions.includes(button.method)) {
                        buttons.push(button)
                    }
                })
            }
        }

        let method = edit ? "post" : "patch"
        buttons = buttons.filter((button) => button.method != method)

        this.setState({ buttons: buttons })
    }

    render() {
        let { buttons } = this.state
        let { cooldown } = this.props
        let buttonStyle = { display: 'inline-block' }

        if (cooldown) {
            buttonStyle['pointerEvents'] = 'none'
        }

        return buttons.map((button, key) => (
            <div className={this.props.cooldown ? "btn-form-wrapper" : ""} style={buttonStyle} key={key}>
                {button.button}
            </div>
        ))
    }
}

export default ButtonsForm