import React, { Component } from "react";

class PreVisualizacaoCabecalho extends Component {
    
    render() {
        const { estrutura, detalhes, styleFieldset } = this.props
        const style_table = {
            table: {
                borderCollapse: "collapse",
                tableLayout: "auto"
            },
            th_cabecalho: {
                textAlign: "",
                background: "white",
                color: "black",
                verticalAlign: "baseline",
                width: "400px",
                border: "1px solid black"
            },
            th: {
                textAlign: "center",
                background: "var(--fonte_titulo_abas)",
                color: "var(--fonte_menu_lateral)",
                verticalAlign: "middle",
            },
            th_individual: {
                background: "rgb(222 222 222)",
                textAlign: "center"
            }
        }

        return(
            <details style={ styleFieldset.fieldset } open={true} className="custom-summary">
                <summary>Pré visualização do cabeçalho</summary>
                <div style={{padding: "15px 0px"}}>
                    <div style={{ overflow: "auto", margin: "0px" }} className="row">
                        <table style={{margin: "0px 25px", ...style_table.table }} className="table">
                            <thead>
                                {
                                    estrutura.colunasCabecalho.map((linha, key) => {
                                        return (
                                            <tr key={key}>
                                                {
                                                    linha.map((coluna, keyColuna) => {
                                                        if(!coluna.disabled) {
                                                            return(
                                                                <th colSpan={coluna.colspan} rowSpan={coluna.rowspan} style={style_table.th_cabecalho} key={keyColuna} dangerouslySetInnerHTML={{ __html: coluna.informacao.replace(/\n/g, '<br />') }}></th>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </thead>
                        </table>
                    </div>

                    <div style={{ overflow: "auto", margin: "0px" }} className="row">
                        <table style={{ margin: "10px 25px", ...style_table.table }} className="table">
                            <thead>
                                <tr>
                                    {
                                        estrutura.grupos.map((grupo, key) => {
                                            let th = []

                                            for (let i = 1; i <= grupo.colspan; i++) {
                                                th.push(<th style={{ borderStyle: "none" }} key={i + 1}></th>)
                                            }

                                            th.push(<th style={style_table.th} colSpan={grupo.th_colspan} key={key}>{grupo.descricao}</th>)

                                            return th
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        detalhes.agruparDados.value != null && estrutura.colunas.length > 0 ?
                                            <th style={style_table.th_individual}>Data</th>
                                            : ""
                                    }
                                    {
                                        estrutura.colunas.map((coluna, key) => {
                                            return (
                                                <th style={style_table.th_individual} key={key}>{coluna.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </details>
        )
    } 
}

export default PreVisualizacaoCabecalho