import React, { Component } from 'react'
import Mapa from '../../components/Mapa'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import mascaraParametrizacao from '../../components/MascaraParametrizacao'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Redirect } from 'react-router-dom'
import Alerta from '../Alerta'
import ReactLoading from 'react-loading'
import { flatten } from 'underscore'
import ObservacaoGrid from './Observacao/grid'
import auth from '../../utils/auth'
import config from '../../utils/config'
import axios from 'axios'

class Dashboard extends Component {
    constructor() {
        super()

        window.addEventListener('resize', (e) => {
            this.setState({ pageHeight: window.innerHeight })
        })
    }

    state = {
        mapObject: false,
        markersCreated: [],
        markersState: { warning: true, off: true, on: true, danger: true },
        filter: { toggle: false, value: "", filtered: [] },
        permissions: [],
        markers: [],
        atualizados: 0,
        monitorado: null,
        inativos: 0,
        loading: true,
        alertas: 0,
        semcomunicacao: 0,
        pageHeight: window.innerHeight,
        tabIndex: 0,
        modal: false,
        mapFilter: null,
        redirect: null,
    }

    componentDidMount = () => {
        this.buscarDadosMapa()
        this.setState({})

        let permissions = []
        if (["admin", "master"].includes(auth.getUserInfo()?.['tipo'])) {
            this.setState({ permissions: ["alerta", "observacao"] })
            return
        }

        let endpoints = auth.getUserInfo()?.menu?.find(m => m.item === "base")?.endpoints ?? []

        if (endpoints.includes("/observacao")) permissions.push("observacao")
        if (endpoints.includes("/alerta")) permissions.push("alerta")

        this.setState({ permissions })
    }

    calcDifferenceInDays(timestamp) {
        const ultimoReporte = new Date(timestamp * 1000)
        const diffTime = (new Date().getTime() - ultimoReporte.getTime())
        let days = (diffTime / (1000 * 60 * 60 * 24)).toFixed(2)
        return days
    }

    buscarDadosMapa = async () => {
        let configUser = auth.getUserInfo()
        let url = config.base_api_url + config.api_v2 + "/" + configUser['empresa'] + "/dados-dashboard/"
        let headers = {
            headers: { 'Authorization': 'Bearer ' + auth.getToken() }
        }

        await axios.get(url, headers).then((res) => {
            let markers = []
            let atualizados = 0
            let alertas = 0
            let inativos = 0
            let semcomunicacao = 0

            res.data?.forEach((monitorado) => {
                let status = "off"
                let days = 1

                if (monitorado?.campos?.length > 0) {
                    if (monitorado?.ultimo_evento !== null) {
                        try {
                            days = this.calcDifferenceInDays(monitorado.ultimo_evento.timestamp_dispositivo)
                        } catch (err) {
                            console.error(err)
                        }

                        if (days >= 1) {
                            status = 'danger'
                            semcomunicacao++
                        } else {
                            if (!monitorado.ultimo_evento) {
                                status = 'danger'
                                semcomunicacao++
                            } else {
                                atualizados++
                                status = 'on'
                            }
                        }
                    }
                } else {
                    inativos++
                }

                if (monitorado.tem_alerta) {
                    status = 'warning'
                    alertas++
                }

                let existeMonitorado = markers.filter((marker) => marker.id === monitorado.id)

                if (existeMonitorado.length === 0) {
                    markers.push({
                        id: monitorado.id,
                        latitude: monitorado.latitude,
                        longitude: monitorado.longitude,
                        identificacao: monitorado.nome,
                        icon: "waterfilter",
                        alertas: monitorado?.alertas ?? [],
                        tem_alerta: monitorado.tem_alerta,
                        status: status,
                        nome: monitorado.nome,
                        campos: monitorado?.campos
                    })
                } else {
                    let markerExist = existeMonitorado?.[0]
                    delete markers[markerExist]

                    markers.push(markerExist)
                }
            })

            this.setState({ alertas, semcomunicacao, inativos, atualizados, markers })
            this.createMarkers(markers)
        }).catch(console.error)

        this.setState({ loading: false })
    }

    createMarkers = async (markerList, active) => {
        this.destroyMarker()

        if (this.state.mapObject.google_map && markerList) {
            const { AdvancedMarkerElement } = await this.state.mapObject.google_map.maps.importLibrary("marker")

            let activeMarkers = []
            if (active) {
                for (let i = 0; i < active.length; i++) {
                    activeMarkers.push(markerList.filter(marker => marker.status === active[i]))
                }

                activeMarkers = flatten(activeMarkers)
            } else
                activeMarkers = markerList

            let arrayMarkers = this.state.markersCreated

            activeMarkers.forEach(monitorado => {
                let marker = new this.state.mapObject.google_map.maps.Marker({
                    position: { lat: parseFloat(monitorado.latitude), lng: parseFloat(monitorado.longitude) },
                    title: monitorado.identificacao,
                    icon: "/map-icon/" + monitorado.status + "/" + monitorado.icon + ".png"
                })

                // const status = { 
                //     'on': '#2ad167',
                //     'off': '#424242',
                //     'warning': '#ffb142',
                //     'danger': '#cf5b4e'
                // }
                // let icons = ["fa-tint", "fa-bolt", "fa-wifi", "fa-exclamation"]
                // let markerElement = document.createElement("div")
                // markerElement.style.backgroundColor = status[monitorado.status]
                // markerElement.className = "icon-dashboard"
                // markerElement.innerHTML = `<i class="fa ${icons[Math.floor(Math.random() * 4)]} fa-2x"></i>`

                // let marker = new AdvancedMarkerElement({
                //     map: this.state.mapObject.map,
                //     position: { lat: parseFloat(monitorado.latitude), lng: parseFloat(monitorado.longitude) },
                //     title: monitorado.identificacao,
                //     content: markerElement
                // })

                marker.addListener('click', () => {
                    this.setState({ monitorado })
                    this.toggleModal()
                })

                arrayMarkers.push(marker)
                marker.setMap(this.state.mapObject.map)
            })

            this.setState({ markersCreated: arrayMarkers })
            // this.fixCenterMap()
        }
    }

    updateMarkers(key) {
        let { markersState } = this.state
        let activeMarkers = []
        markersState[key] = !markersState[key]
        this.setState({ markersState })

        Object.entries(markersState).map(marker => {
            if (marker[1]) activeMarkers.push(marker[0])
            return null
        })

        this.createMarkers(this.state.markers, activeMarkers)
    }

    destroyMarker = () => {
        this.state.markersCreated.forEach(element => element.setMap(null))
        this.setState({ markersCreated: [] })
    }

    filterMarkers = (e) => {
        let { markers, filter } = this.state
        if (e.target.value.length !== 0) {
            const filtered = markers.filter(marker => marker.nome?.toLowerCase()?.startsWith(e.target.value?.toLowerCase()))
            filter['filtered'] = filtered
        } else {
            filter['filtered'] = []
        }

        filter['value'] = e.target.value
        this.setState({ filter })
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
            selected: [],
            tabIndex: 0,
            data: [],
            erros: [],
        })
    }

    fixCenterMap = () => {
        if (this.state.mapObject.google_map) {
            let bounds = new this.state.mapObject.google_map.maps.LatLngBounds()
            this.state.markersCreated.forEach(element => {
                try {
                    if (element?.getPosition)
                        bounds.extend(element?.getPosition())
                } catch (err) {
                    console.error(err)
                }
            })
            this.state.mapObject.map.fitBounds(bounds)
        }
    }

    renderClientList() {
        let { filter } = this.state
        let markers = filter.filtered.length > 0 ? filter.filtered : this.state.markers
        let elements = []
        const status = {
            'on': '#2ad167',
            'off': '#424242',
            'warning': '#ffb142',
            'danger': '#cf5b4e'
        }

        markers?.map((marker, index) => {
            elements.push(
                <li
                    key={index}
                    onClick={() => {
                        let { mapFilter, filter } = this.state
                        mapFilter = [marker?.latitude, marker?.longitude]
                        filter["toggle"] = false
                        this.setState({ mapFilter, filter })
                    }}>
                    <span className="badge" style={{ backgroundColor: status[marker.status] }}>&nbsp;</span>
                    <span>{marker?.nome}</span>
                </li>
            )
        })

        return elements
    }

    redirect = (to) => {
        history.pushState({}, "", location.href)
        this.setState({ redirect: to })
    }

    render() {
        const { redirect, markersState, monitorado, permissions, pageHeight } = this.state

        if (redirect) {
            return <Redirect to={redirect} />
        }

        return (
            <div className="hdv-render-div home-new-style">
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="hdv-observacao-modal">
                    <ModalHeader toggle={this.toggleModal}>{this.state.monitorado?.nome}</ModalHeader>
                    <ModalBody style={{ padding: '12px', minHeight: '450px' }}>
                        <Tabs
                            selectedIndex={this.state.tabIndex}
                            onSelect={tabIndex => this.setState({ tabIndex, selected: [], enableEditar: false, enableDeletar: false })}>
                            <TabList>
                                <Tab>Informações</Tab>
                                {permissions.includes("observacao") ? <Tab>Observações</Tab> : null}
                                {permissions.includes("alerta") ? <Tab>Alertas</Tab> : null}
                            </TabList>
                            <TabPanel>
                                {this.state.monitorado?.campos?.length > 0
                                    ?
                                    <div className="grid-informacoes-dashboard">
                                        {this.state.monitorado?.campos.map((campo, key) => {
                                            let mascara = campo.campo__mascara
                                            let time = "---"
                                            let status = "---"
                                            let value = mascara === "padrao" ? campo?.ultimo_evento?.[campo.campo__identificacao] ?? "" : mascaraParametrizacao[mascara](campo?.ultimo_evento?.[campo.campo__identificacao] ?? "")
                                            let icone = null

                                            if (campo.ultimo_evento && campo.ultimo_evento?.timestamp_dispositivo > 0) {
                                                let date = new Date(campo.ultimo_evento.timestamp_dispositivo * 1000)
                                                let difference = this.calcDifferenceInDays(campo.ultimo_evento.timestamp_dispositivo)

                                                if (difference >= 1) {
                                                    status = "Desatualizado"
                                                    time = (
                                                        <span className="last-report-span" style={{ backgroundColor: "#d6584f" }}>
                                                            {date.toLocaleString("pt-BR").replace(",", " ")}
                                                        </span>
                                                    )
                                                } else if (difference < 1) {
                                                    status = "Atualizado"
                                                    time = (
                                                        <span className="last-report-span" style={{ backgroundColor: "#3c9956" }}>
                                                            {date.toLocaleString("pt-BR").replace(",", " ")}
                                                        </span>
                                                    )
                                                } else {
                                                    time = (
                                                        <span className="last-report-span" style={{ backgroundColor: "#636a74" }}>
                                                            {date.toLocaleString("pt-BR").replace(",", " ")}
                                                        </span>
                                                    )
                                                }
                                            }

                                            try {
                                                icone = JSON.parse(campo?.campo__icone)?.icone_on
                                            } catch (err) {

                                            }

                                            return (
                                                <div key={key} className="grid-informacoes-dashboard-card">
                                                    <div className="px-3 py-2">
                                                        <h5>{campo?.campo__descricao}</h5>
                                                        <p><strong>Equipamento: </strong>
                                                            {campo?.campo__equipamento__imei ?
                                                                <span
                                                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                    onClick={() => this.redirect(`/equipamento/${campo?.campo__equipamento}/editar`)}
                                                                >
                                                                    {campo?.campo__equipamento__imei}
                                                                </span>
                                                                :
                                                                "---"
                                                            }
                                                        </p>
                                                        <p><strong>Valor: </strong>{value + " " + campo?.campo__unidade}</p>
                                                        <p><strong>Status: </strong> {status}</p>
                                                        <p><strong>Último Evento: </strong>{time}</p>
                                                    </div>
                                                    {icone ? <i className={`fa ${icone}`}></i> : null}
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    :
                                    <p className="pt-5" style={{ fontSize: "15px", opacity: "0.8", textAlign: "center" }}>
                                        Não há parâmetros cadastrados
                                    </p>
                                }
                            </TabPanel>
                            {permissions.includes("observacao")
                                ?
                                <TabPanel>
                                    {monitorado ? <ObservacaoGrid monitorado={monitorado?.id} /> : null}
                                </TabPanel>
                                :
                                null
                            }
                            {permissions.includes("alerta")
                                ?
                                <TabPanel>
                                    <Alerta
                                        compact
                                        customFilter={`&monitorado=${monitorado?.id}`}
                                        data={monitorado?.alertas ?? []}
                                    />
                                </TabPanel>
                                :
                                null
                            }
                        </Tabs>
                    </ModalBody>
                    <ModalFooter className={this.state.tabIndex === 0 ? "" : "hdv-noshow-item"}>
                        <div className="hdv-btn-group">
                            <button onClick={() => this.redirect(`status/${monitorado.id}`)} className="hdv-btn-forms hdv-btn-blue d-flex align-items-center">
                                <i className="fa fa-external-link-alt fa-2x"></i>
                                Status
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                <div>
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Meus locais</h4>
                            <div className="gray-background">
                                <i className="fa fa-map fa-2x"></i>
                            </div>
                        </span>
                    </div>

                    <div>
                        <div className={`div-sample marker-filter marker-first-info ${!markersState.on ? 'disabled' : ''}`} onClick={() => this.updateMarkers("on")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-active">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-check-circle hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Atualizados</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.atualizados}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter marker-mid-info ${!markersState.warning ? 'disabled' : ''}`} onClick={() => this.updateMarkers("warning")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-alert">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-exclamation-triangle hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Alertas</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.alertas}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter marker-mid-info ${!markersState.danger ? 'disabled' : ''}`} onClick={() => this.updateMarkers("danger")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-sem-comunicacao">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-wifi hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Sem comunicação</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.semcomunicacao}</div>
                                </div>
                            </div>
                        </div>
                        <div className={`div-sample marker-filter marker-last-info ${!markersState.off ? 'disabled' : ''}`} onClick={() => this.updateMarkers("off")}>
                            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                                <div className="hdv-info-bloco hdv-info-bloco-off">
                                    <div className="hdv-title-bloco">
                                        <i className="fa fa-power-off hdv-icon-position-homebox"></i>
                                        <span className="hdv-icon-text-homebox">Inativos</span>
                                    </div>
                                    <div className="hdv-body-bloco">{this.state.inativos}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Mapa
                        mapObjectFn={(map) => this.setState({ mapObject: map })}
                        filter={this.state.mapFilter}
                        style={{ minHeight: `calc(${pageHeight}px - 348px)` }}
                    />

                    <div style={{ top: "28px" }} className="map-marker-toggle">
                        {this.state.loading
                            ?
                            <div style={{ padding: '12px 10px' }}>
                                <ReactLoading type="spin" color="#ffffff" width={32} />
                            </div>
                            :
                            <button
                                id="toggle"
                                onClick={() => this.setState({ filter: { ...this.state.filter, toggle: !this.state.filter.toggle }, mapFilter: null })}>
                                <i className={this.state.filter.toggle ? "fa fa-times fa-2x" : "fa fa-search fa-2x"}></i>
                            </button>
                        }
                        {this.state.filter.toggle
                            ?
                            <div className="marker-toggle-list">
                                <input value={this.state.filter.value} type="text" placeholder="Pesquisar" onChange={this.filterMarkers} />
                                <ul className="pb-4">
                                    {this.renderClientList()}
                                </ul>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard