import PrivateRoute from '../containers/PrivateRoute'
import Acesso from '../containers/Acesso'
import Cliente from '../containers/Cliente'
import Empresa from '../containers/Empresa'
import Equipamento from '../containers/Equipamento'
import Monitorado from '../containers/Monitorado'
import Logs from '../containers/Logs'
import PerfilUsuario from '../containers/PerfilUsuario'
import PerfilCliente from '../containers/PerfilCliente'
import ConfiguracaoSistema from '../containers/ConfiguracaoSistema'
import Automacao from '../containers/Automacao'
import MinhaConta from '../containers/MinhaConta'
import Usuario from '../containers/Usuario'
import AcessoModulo from '../containers/AcessoModulo'
import ListaMonitorados from '../containers/ListaMonitorados'
import Alerta from '../containers/Alerta'
import Status from '../containers/Status'
import EquipamentoVirtual from '../containers/EquipamentoVirtual'
import ComandoCustomizado from '../containers/ComandoCustomizado'
import CampoParametrizado from '../containers/CampoParametrizado'
import Sideccr from '../containers/Sideccr'
import RelatorioCustomizado from '../containers/RelatorioCustomizado'
import RelatorioConsumo from '../containers/RelatorioConsumo'
import Mira from '../containers/Mira'
import RelatorioInema from '../containers/RelatorioInema/form.js'
import RelatorioSerhma from '../containers/RelatorioSerhma/form.js'
import AfiraLink from '../containers/AfiraLink/index.js'
import ExportacaoDados from '../containers/ExportacaoDados/index.js'
import RelatorioAlertas from '../containers/RelatorioAlertas/index.js'

let key = 0
const getKey = () => {
    let value = key
    key += 1
    return value
}

const routes = [
    {
        route: "/acesso",
        cliente: false,
        component: <PrivateRoute key={getKey()} path='/acessos' component={Acesso} />
    },
    {
        route: "/acesso-modulo",
        cliente: false,
        component: <PrivateRoute key={getKey()} path='/acesso-modulo' component={AcessoModulo} />
    },
    {
        route: "/cliente",
        cliente: false,
        component: <PrivateRoute key={getKey()} path='/cliente' component={Cliente} />
    },
    {
        route: "/empresa",
        component: <PrivateRoute key={getKey()} path='/empresa' component={Empresa} />
    },
    {
        route: "/monitorado-status",
        component: <PrivateRoute key={getKey()} path='/status/:id' component={Status} />
    },
    {
        route: "/equipamento",
        cliente: false,
        component: <PrivateRoute key={getKey()} path='/equipamento' component={Equipamento} />
    },
    {
        route: "/monitorado",
        component: <PrivateRoute key={getKey()} path='/monitorado' component={Monitorado} />
    },
    {
        route: "/relatorio",
        component: <PrivateRoute key={getKey()} path='/relatorio-customizado' component={RelatorioCustomizado} />
    },
    {
        route: "/relatorio/consumo",
        component: <PrivateRoute key={getKey()} path='/relatorio-consumo' component={RelatorioConsumo} />
    },
    {
        route: "/log-acessos",
        component: <PrivateRoute key={getKey()} path='/relatorio/logs' component={Logs} />
    },
    {
        route: "/sideccr",
        component: <PrivateRoute key={getKey()} required='ambiental' path='/sideccr/' component={Sideccr} />
    },
    {
        route: "/mira",
        component: <PrivateRoute key={getKey()} required='ambiental' path='/mira' component={Mira} />
    },
    {
        route: "/equipamento",
        cliente: false,
        component: <PrivateRoute key={getKey()} path='/campo-parametrizado' component={CampoParametrizado} />
    },
    {
        route: "/usuario/perfil",
        component: <PrivateRoute key={getKey()} path='/perfil/usuario' component={PerfilUsuario} />
    },
    {
        route: "/cliente/perfil",
        component: <PrivateRoute key={getKey()} path='/perfil/cliente' component={PerfilCliente} />
    },
    {
        route: "/configuracao",
        component: <PrivateRoute key={getKey()} path='/configuracao-sistema' component={ConfiguracaoSistema} />
    },
    {
        route: "/configuracao",
        component: <PrivateRoute key={getKey()} path='/minha-conta' component={MinhaConta} />
    },
    {
        route: "/automacao",
        cliente: false,
        component: <PrivateRoute key={getKey()} path='/automacao' component={Automacao} />
    },
    /*{
        route: "",
        component: <PrivateRoute path='/automacao-grupo' component={Automacao} />
    },*/
    {
        route: "/usuario",
        component: <PrivateRoute key={getKey()} path='/usuario' component={Usuario} />
    },
    {
        route: "/lista-monitorado",
        component: <PrivateRoute key={getKey()} path='/lista-monitorados' component={ListaMonitorados} />
    },
    {
        route: "/equipamento",
        component: <PrivateRoute key={getKey()} path='/virtual' component={EquipamentoVirtual} />
    },
    {
        route: "/alerta",
        component: <PrivateRoute key={getKey()} path='/lista-alertas' component={Alerta} />
    },
    {
        route: "/equipamento",
        cliente: false,
        component: <PrivateRoute key={getKey()} exact path='/comando-customizado/:id/novo' component={ComandoCustomizado} />
    },
    {
        route: "/equipamento",
        cliente: false,
        component: <PrivateRoute key={getKey()} exact path='/comando-customizado/:idComando/editar/' component={ComandoCustomizado} />
    },
    {
        route: "/relatorio-ambiental-inema",
        component: <PrivateRoute key={getKey()} required='ambiental' path='/relatorio-inema' component={RelatorioInema} />
    },
    {
        route: "/relatorio-ambiental-serhma",
        component: <PrivateRoute key={getKey()} required='ambiental' path='/relatorio-serhma' component={RelatorioSerhma} />
    },
    {
        route: "/equipamento",
        cliente: false,
        component: <PrivateRoute key={getKey()} path='/afiralink' component={AfiraLink} />
    },
    {
        route: "/exportacao-dados",
        component: <PrivateRoute key={getKey()} path='/exportacao-dados' component={ExportacaoDados} />
    },
    {
        route: "/relatorio/alerta",
        component: <PrivateRoute key={getKey()} path='/relatorio-alertas' component={RelatorioAlertas} />
    }
]

export default routes
