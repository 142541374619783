import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Select from '../../components/Select'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'

class FormGrupo extends Component {
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    userInfo = auth.getUserInfo()

    state = {
        detalhes: { descricao: "", automacoes: [], automacao: null },
        tabIndex: 0,
        erros: [],
        modal: false,
        automacaoOptions: [],
    }

    componentDidMount = async () => {
        this.getAutomacoes()

        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao-grupo/' + this.props.match.params.id + '/'

        axios.get(requestURL, this.configMe).then((res) => {
            let detalhes = {}
            detalhes['descricao'] = res.data?.descricao
            detalhes['automacoes'] = res.data?.automacoes

            this.setState({ detalhes })
        })
    }

    getAutomacoes = () => {
        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao/?limit=1000', this.configMe).then((res) => {
            let automacaoOptions = []

            res.data?.results?.forEach(automacao => {
                automacaoOptions.push({
                    automacao,
                    label: automacao.descricao,
                    value: automacao.id
                })
            })

            this.setState({ automacaoOptions })
        })
    }

    handleChange = (e) => {
        let { detalhes } = this.state

        detalhes[e.target.name] = e.target.value

        if (document.querySelector(`#validacao-${e.target.name}`)) {
            document.querySelector(`#validacao-${e.target.name}`).innerHTML = ""
        }

        this.setState({ detalhes })
    }

    handleChangeAutomacao = (selectedOption) => {
        let { detalhes } = this.state
        detalhes['automacao'] = selectedOption
        this.setState({ detalhes })
    }

    addAutomacao = () => {
        let { detalhes, modal } = this.state

        if (detalhes?.automacao) {
            let automacao = detalhes?.automacao?.automacao
            const includes = detalhes?.automacoes?.map(a => a?.id)?.includes(automacao?.id)

            if (!includes) {
                detalhes.automacoes.push(automacao)
                detalhes.automacao = null
                this.setState({ detalhes })
                this.saveAction(false)
            }

            this.setState({ modal: !modal })
        }
    }

    saveAction = async (showAviso = true) => {
        let { detalhes } = this.state
        let requestURL, verb
        let bodyFormData = new FormData()

        this.setState({ cooldown: true })

        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao-grupo/' + this.props.match.params.id + '/'
            verb = 'PATCH'
        } else {
            requestURL = cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/automacao-grupo/'
            verb = 'POST'
        }

        const automacoes = detalhes?.automacoes?.map(a => {
            let ordem = String(a.ordem).replace(/-/g, "")
            ordem = ordem.replace(/e/g, "")

            return new Object({ id: a.id, ordem: ordem })
        })

        bodyFormData.append('descricao', detalhes?.descricao)
        bodyFormData.append('automacoes', JSON.stringify(automacoes))

        await axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(() => {
            if (showAviso) {
                helper.dispatchEvent("showAviso", {
                    message: `Grupo ${verb === "POST" ? "criado" : "editado"} com sucesso`,
                    callback: this.props.history.goBack
                })
            }
        }).catch(error => {
            let erros = error.response?.data ?? {}

            Object.keys(erros)?.forEach((key) => {
                let span = document.querySelector(`#validacao-${key}`)
                if (span) span.innerHTML = erros[key]?.[0]
            })
        })

        this.setState({ cooldown: false })
    }

    deleteAutomacao = (automacao) => {
        let { detalhes } = this.state
        detalhes.automacoes = detalhes.automacoes?.filter(a => a.id != automacao.id)
        this.setState({ detalhes })
        this.saveAction()
    }

    changeOrdem = (index, e) => {
        let { detalhes } = this.state

        detalhes['automacoes'][index].ordem = e.target.value
        this.setState({ detalhes })
    }

    render() {
        const { automacaoOptions, detalhes } = this.state

        return (
            <div style={{ marginBottom: "150px" }}>

                <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: !this.state.modal })} size="lg">
                    <ModalHeader toggle={() => this.setState({ modal: !this.state.modal })}>
                        Automações
                    </ModalHeader>
                    <ModalBody>
                        <label className="required">Automação</label>
                        <Select
                            value={detalhes['automacao']}
                            onChange={(e) => this.handleChangeAutomacao(e)}
                            options={automacaoOptions}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="hdv-btn-forms hdv-btn-blue"
                            onClick={this.addAutomacao}>
                            <i className="fa fa-plus"></i>
                            Adicionar Automação
                        </button>
                        <button
                            className="hdv-btn-forms hdv-btn-default"
                            onClick={() => this.setState({ modal: !this.state.modal })}>
                            Fechar
                        </button>
                    </ModalFooter>
                </Modal>


                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        {this.props.match.params.id ? <h4>Editar Grupo</h4> : <h4>Novo Grupo</h4>}
                        <div className="gray-background">
                            <i className="fa fa-robot fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-form-default mb-2">
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="descricao" className="mt-0">Descrição: </label>
                            <input type="text" name="descricao" id="descricao" value={this.state.detalhes.descricao} onChange={this.handleChange} />
                            <span id="validacao-descricao"></span>
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between mb-2 mt-3">
                                <h6 className="hdv-subtable-title m-0">Lista de automações cadastrados</h6>
                                <button className="hdv-btn-forms hdv-btn-green m-0" onClick={() => this.setState({ modal: !this.state.modal })}>
                                    Nova Automação
                                </button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <table className="hdv-subtable mt-3">
                                <thead>
                                    <tr>
                                        <td>Descrição</td>
                                        <td>Ordem de Execução</td>
                                        <td>Ativo</td>
                                        <td>Ações</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detalhes.automacoes?.map((automacao, index) => (
                                        <tr key={index}>
                                            <td>{automacao?.descricao}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    style={{ height: "36px" }}
                                                    value={automacao?.ordem}
                                                    maxLength="3"
                                                    onChange={(e) => this.changeOrdem(index, e)}
                                                />
                                            </td>
                                            <td>{automacao?.ativo ? "Sim" : "Não"}</td>
                                            <td className="hdv-subtable-action-td">
                                                <button
                                                    onClick={() => this.deleteAutomacao(automacao)}
                                                    className="hdv-btn-forms hdv-btn-red">
                                                    Deletar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="hdv-btn-group">
                    <button
                        onClick={this.saveAction}
                        disabled={this.state.cooldown}
                        className="hdv-btn-forms hdv-btn-green">
                        Salvar
                    </button>
                    <Link to="/automacao">
                        <button className="hdv-btn-forms hdv-btn-orange">
                            Cancelar
                        </button>
                    </Link>
                </div>
            </div>
        )
    }
}

export default FormGrupo
