import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import Form from './form.js'
import PrivateRoute from '../PrivateRoute'

class CampoCustomizado extends Component {
    render() {
        return (
            <div className="hdv-render-div">
                <div>
                    <Switch>
                        <PrivateRoute path='/campo-parametrizado/:imei/novo' component={Form} />
                        <PrivateRoute path='/campo-parametrizado/:imei/editar/:id' component={Form} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default CampoCustomizado
