import React, { Component } from 'react'
class Status extends Component {

    state = {
        params: {
            descricao: "",
            value: 0,
            params: false,
        }
    }

    componentWillMount = () => {
        if (this.props.dataWidget)
            this.setState({ params: this.props.dataWidget })
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.dataWidget.value !== nextProps.dataWidget.value)
            this.setState({ params: nextProps.dataWidget })

    }

    render() {
        return (
            <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                {this.props.dataWidget?.ultimoEvento({ showUnity: false })}
                <div className="box-simple-value">
                    <div className="bar-value">
                        <div className="layout-inlineflex"><strong className={"value-super"}>
                            {Boolean(this.state?.params.value) ? this.state.params.params?.on : this.state.params.params?.off}
                        </strong></div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Status