import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import ConfirmDelete from '../../components/ConfirmDelete'
import ButtonsGrid from '../../components/ButtonsGrid'
import { Badge } from 'reactstrap'
import Table from '../../components/Table'
import helper from '../../utils/helper'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import axios from 'axios'


class Grid extends Component {

    state = {
        data: [],
        pages: null,
        loading: true,
        redirectToEdit: false,
        table: null
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + `/usuario/perfil/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((res) => {
            this.setState({
                loading: false,
                data: res.data.results,
                count: res.data.count,
                next: res.data.next,
                previous: res.data.previous,
            })
        })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        this.state.table?.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/usuario/perfil/' + value + '/', config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state.table?.fetch()
            this.state.table?.unselect()
        }))
    }

    editar = () => {
        this.state.table?.unselect()
        let toGo = "/perfil/usuario/" + this.state?.table?.state?.selected[0] + "/editar"
        let from = { pathname: toGo }
        history.pushState({}, "", location.href)
        this.setState({ redirectToEdit: from })
    }

    render() {

        const { redirectToEdit, table } = this.state
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: 'Nome do Perfil',
            Accessor: 'nome'
        }, {
            Header: 'Restrição Monitorado',
            sortable: false,
            Accessor: 'monitorados',
            Cell: (original) => {
                let status = original.monitorados.length > 0 ? "Sim" : "Não"
                let color = status === "Sim" ? "success" : "danger"

                return <Badge href="#" color={color}>{status}</Badge>
            }
        }, {
            Header: 'Criado em',
            Accessor: 'data_criado',
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_criado)}</span>
            }
        }, {
            Header: 'Atualizado em',
            Accessor: 'data_atualizado',
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_atualizado)}</span>
            }
        }]

        return (
            <div>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Cadastro de Perfil - Usuários</h4>
                        <div className="gray-background">
                            <i className="fa fa-id-card fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsGrid 
                        route="/usuario/perfil"
                        buttons={[
                            {
                                method: "post",
                                button: <Link to="/perfil/usuario/novo"><button className="hdv-btn-forms hdv-btn-green ">Novo</button></Link>
                            },

                            {
                                method: "patch",
                                button: <button onClick={() => { if (table?.state.enableEditar) this.editar() }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                            },

                            {
                                method: "delete",
                                button: <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar}/>
                            }
                        ]}
                    />
                </div>

                <Table
                    checkbox
                    data={this.state.data}
                    loading={this.state.loading}
                    count={this.state.count}
                    next={this.state.next}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({ table })}
                />
            </div>
        )
    }
}

export default Grid
