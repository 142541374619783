import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import './assets/new.css'
import './assets/style.css'
import PrivateRoute from './containers/PrivateRoute'
import Login from './containers/Login'
import Recuperar from './containers/Login/recuperar'
import Cliente from './containers/Login/cliente'
import Layout from './containers/Layout'
import { SupervisorioLink, Supervisorio } from './containers/Supervisorio'
import { AvisoContextProvider } from './contexts/Aviso'
import RedirectRoute from './containers/RedirectRoute'


if (process.env.NODE_ENV === 'development') {
	const consoleError = console.error
	const hiddenFilter = 'ReactDOMWarning'
	const hiddenStyles = 'color: transparent; font-size: 0px; user-select: none'

	console.error = (...args) => {
		let showFiltered = false
		const trace = new Error().stack
		if (!trace || !trace.includes('react-dom.development.js')) {
			return consoleError(...args)
		}

		const firstArg = args[0]
		if (typeof firstArg !== 'string' || !firstArg.startsWith('Warning:')) {
			return consoleError(...args)
		}

		if (showFiltered) {
			const template = args.shift()?.replace(/%s$/, '')
			const stacktrace = args.pop()

			console.groupCollapsed(
				`%c⚠️ ${template}%c${hiddenFilter}`,
				'color: red; font-weight: normal',
				...args,
				hiddenStyles
			)

			consoleError(`%s%c${hiddenFilter}`, stacktrace, hiddenStyles)
			console.groupEnd()
		}
	}
}

class App extends Component {
	render() {
		return (
			<AvisoContextProvider>
				<div className="hdv-maxheight">
					<Switch>
						<Route exact path='/redirect' component={RedirectRoute} />
						<Route exact path='/login' component={Login} />
						<Route path="/customer" component={Cliente} />
						<Route path="/lost-password" component={Recuperar} />
						<PrivateRoute exact path='/supervisorio' component={Supervisorio} />
						<Route exact path='/supervisorio/:hash' component={SupervisorioLink} />
						<PrivateRoute path='/' component={Layout} />
					</Switch>
				</div>
			</AvisoContextProvider>
		);
	}
}

export default App;
